<div>
  <ng-container *ngIf="router.url == '/login'; then login; else other">
  </ng-container>

  <!-- login -->
  <ng-template #login>
    <div class="bio-blue-bg-color text-white font-bold py-sm-3 height-measure">
      <span>
        <img src="assets/images/logo.png" alt="logo" style="width: 100px;margin-left: 34%; border-radius:55px;"
          class="d-md-none" />
      </span>
    </div>
  </ng-template>
  <ng-template #other>
    <div class=" row bio-blue-bg-color text-white font-bold mx-0 px-0 height-measure ">
      <div *ngIf="!hideIcon && !hideDefault" class="d-none d-md-flex col-md-1 verticle-content-middle">
        <img src="assets/images/logo.png" alt="logo" style="height: 65px; margin-left: 34%; border-radius:55px;"/>
      </div>
      <div class="py-2 verticle-content-middle" [ngClass]="hideIcon ? 'col-12' : 'col-12 col-md-11'">
        <div class="w-100">
          <div *ngIf="!hideDefault && !HeaderTitle" class="float-md-left pl-md-4 ml-md-2">
            <div class="font-size-24 font-weight-bold d-flex">
              Maharashtra State Biodiversity Board
            </div>
            <div class="font-size-13 d-flex justify-content-center pr-5 mr-3">
              Department of Maharashtra Biodiversity, Government of Maharashtra
            </div>
          </div>
          <div *ngIf="HeaderTitle" class="font-size-24 float-md-left pl-md-5 ml-md-2 d-flex justify-content-center">
            {{HeaderTitle}}
          </div>
          <div *ngIf="router.url != '/register' && userToken" class="float-md-right d-flex justify-content-center pt-4 pt-md-0">
            <div class="h-100 d-inline-block mr-3" style="vertical-align: middle;">
              <span class="user-name">{{userName}}</span>
              <span class="d-flex w-100 justify-content-end profile-name font-size-10">{{this.userEmail}}</span>
            </div>
            <div class="h-100 d-inline-block mr-md-5 dropdown">
              <button class="btn btn-default dropdown-toggle text-white border-none" type="button"
                data-toggle="dropdown">

                <span><i class="fa fa-user header-icon"></i></span>
                <span class="caret ml-2"></span>
              </button>
              <ul class="dropdown-menu mr-4 text-center mt-3">
                <li><a class="pr-3 text-black text-decoration-none" routerLink="/">
                    <i class="fa fa-home bio-blue-text-color"></i>
                    &nbsp;Home</a></li>
                <hr>
                <li><a role="button" (click)="logout()">
                    <i class="fa fa-sign-out-alt bio-blue-text-color"></i>
                    &nbsp;Log Out</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

  </ng-template>
</div>
<app-ng-dynamic-breadcrumb [hidden]="['/login', '/register'].includes(router.url)"></app-ng-dynamic-breadcrumb>
<ng-content></ng-content>

<ngx-ui-loader></ngx-ui-loader>

