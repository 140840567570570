import { Component, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable, timer } from 'rxjs';
import { RestService } from 'src/app/services/rest.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import {calculateSHA256, checkNumber, ConfirmedValidator, MustMatch, passwordPolicy} from '../../_helpers/utility';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';


@Component({
  selector: 'app-bmc-registration',
  templateUrl: './bmc-registration.component.html',
  styleUrls: ['./bmc-registration.component.css'],
})
export class BmcRegistrationComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private restService: RestService,
    private ngxService: NgxUiLoaderService,
    private modalService: BsModalService
  ) { }

  get checkNumber() {
    return checkNumber;
  }

  get OTP() {
    const otpData = this.OTPArray.controls.map((formControl, i) => { return formControl.value; });
    return otpData.join('');
  }

  get getFormControls() {
    return this.registrationFormGroup.controls;
  }

  get checkErrors() {
    let anyError = false;
    if (this.getFormControls.area_sub_category.value == '0') {
      this.getFormControls.area_sub_category['custom_errors'] = {
        invalid_selection: true,
      };
      anyError = true;
    } else {
      this.getFormControls.area_sub_category['custom_errors'] = undefined;
    } // reset

    if (this.getFormControls.area_category.value == '0') {
      this.getFormControls.area_category['custom_errors'] = {
        invalid_selection: true,
      };
      anyError = true;
    } else {
      this.getFormControls.area_category['custom_errors'] = undefined;
    } // reset

    if (this.getFormControls.state.value == '0') {
      this.getFormControls.state['custom_errors'] = { invalid_selection: true };
      anyError = true;
    } else {
      this.getFormControls.state['custom_errors'] = undefined;
    } // reset

    if (this.getFormControls.district.value == '0') {
      this.getFormControls.district['custom_errors'] = {
        invalid_selection: true,
      };
      anyError = true;
    } else {
      this.getFormControls.district['custom_errors'] = undefined;
    }

    if (this.getFormControls.taluka.value == '0') {
      this.getFormControls.taluka['custom_errors'] = {
        invalid_selection: true,
      };
      anyError = true;
    } else {
      this.getFormControls.taluka['custom_errors'] = undefined;
    }

    if (this.getFormControls.bmc_company.value == '0') {
      this.getFormControls.bmc_company['custom_errors'] = {
        invalid_selection: true,
      };
      anyError = true;
    } else {
      this.getFormControls.bmc_company['custom_errors'] = undefined;
    }

    if (this.getFormControls.cordinator_name.value == '0') {
      this.getFormControls.cordinator_name['custom_errors'] = {
        invalid_selection: true,
      };
      anyError = true;
    } else {
      this.getFormControls.cordinator_name['custom_errors'] = undefined;
    }

    if (this.getFormControls.cordinator_mobile.value == '0') {
      this.getFormControls.cordinator_mobile['custom_errors'] = {
        invalid_selection: true,
      };
      anyError = true;
    } else {
      this.getFormControls.cordinator_mobile['custom_errors'] = undefined;
    }

    if (this.getFormControls.cordinator_email.value == '0') {
      this.getFormControls.cordinator_email['custom_errors'] = {
        invalid_selection: true,
      };
      anyError = true;
    } else {
      this.getFormControls.cordinator_email['custom_errors'] = undefined;
    }

    if (this.getFormControls.gross_population.value == '0') {
      this.getFormControls.gross_population['cutom_errors'] = {
        invalid_selection: true,
      };
      anyError = true;
    } else {
      this.getFormControls.gross_population['custom_errors'] = undefined;
    }

    if (this.getFormControls.area_hectare.value == '0') {
      this.getFormControls.area_hectare['custom_errors'] = {
        invalid_selection: true,
      };
      anyError = true;
    } else {
      this.getFormControls.area_hectare['custom_errors'] = undefined;
    }

    if (this.getFormControls.username.value == '0') {
      this.getFormControls.username['custom_errors'] = {
        invalid_selection: true,
      };
      anyError = true;
    } else {
      this.getFormControls.username['custom_errors'] = undefined;
    }

    if (this.getFormControls.password.value == '0') {
      this.getFormControls.password['custom_errors'] = {
        invalid_selection: true,
      };
      anyError = true;
    } else {
      this.getFormControls.password['custom_errors'] = undefined;
    }

    if (this.getFormControls.re_password.value == '0') {
      this.getFormControls.re_password['custom_errors'] = {
        invalid_selection: true,
      };
      anyError = true;
    } else {
      this.getFormControls.re_password['custom_errors'] = undefined;
    }

    return anyError;
  }
  get isOTPErrorsExists() {
    if (!this.OTP) {
      this.OTPArray['custom_errors'] = {
        invalid_OTP: true,
      };
      return true;
    }

    return false;
  }
  registrationFormGroup: FormGroup;
  submitted = false;

  error: string;
  // tslint:disable-next-line:variable-name
  area_category_list = [];
  // tslint:disable-next-line:variable-name
  sub_area_category_list = [];
  // tslint:disable-next-line:variable-name
  state_list = [];
  // tslint:disable-next-line:variable-name
  bmc_list = [];
  // tslint:disable-next-line:variable-name
  district_list = [];
  // tslint:disable-next-line:variable-name
  taluka_list = [];
  // tslint:disable-next-line:ban-types
  modalOTPContentRef: BsModalRef<Object>;
  OTPArray: FormArray;
  defaultResendOTPDelayTime = 180;
  resendOTPDelayTime = 0;

  otpIntervalVar: any;

  ngOnInit(): void {
    this.OTPArray = new FormArray([new FormControl(1)
      , new FormControl(2)
      , new FormControl(3)
      , new FormControl(4)]);
    this.registrationFormGroup = this.formBuilder.group(
      {
        area_category: [0, [Validators.required]],
        area_sub_category: [0, [Validators.required]],
        state: [0, [Validators.required]],
        district: [0, [Validators.required]],
        taluka: [0, [Validators.required]],
        bmc_company: [0, [Validators.required]],
        cordinator_name: ['', [Validators.required]],
        cordinator_mobile: [
          '',
          [
            Validators.required,
            Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$'),
          ],
        ],
        cordinator_email: [
          '',
          [
            Validators.required,
            Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
          ],
        ],
        gross_population: ['', [Validators.required]],
        area_hectare: ['', [Validators.required]],
        username: ['', [Validators.required]],
        password: ['', [Validators.required]],
        re_password: ['', [Validators.required]],
        OTPArray: this.OTPArray
      }, {
        validator: [
          passwordPolicy('password'),
          ConfirmedValidator('password', 're_password')
        ]
      });

    this.bindValueChanges();
    this.bindAllFormControlsInit();
    // this.PatchAnyExistingValue(true);
  }

  async bindValueChanges() {
    this.ngxService.start();

    this.getFormControls.area_category.valueChanges.subscribe(
      async (newValue) => {
        // tslint:disable-next-line:no-unused-expression
        this.checkErrors; // check for other  errors
        if (newValue !== undefined && newValue.toString() == "0") {
          this.sub_area_category_list = [];
          return;
        }
        this.sub_area_category_list = await this.runApiCommunication(
          'get-area-sub-categories-list',
          { area_id: newValue }
        ).catch(e => {
          this.handlePageErrors(e);
        });
        this.getFormControls.area_sub_category.patchValue(0); // reset
      }
    );
    this.getFormControls.area_sub_category.valueChanges.subscribe(
      async (newValue) => {
        // tslint:disable-next-line:no-unused-expression
        this.checkErrors; // check for other  errors
      }
    );
    this.getFormControls.state.valueChanges.subscribe(async (newValue) => {
      // tslint:disable-next-line:no-unused-expression
      this.checkErrors; // check for other  errors
      // tslint:disable-next-line:triple-equals
      if (newValue !== undefined && newValue.toString() == '0') {
        this.district_list = [];
        return;
      }
      this.district_list = await this.runApiCommunication(
        'get-districts-list',
        { state_id: newValue }
      ).catch(e => {
        this.handlePageErrors(e);
      });
      this.getFormControls.district.patchValue(0); // reset
    });
    this.getFormControls.district.valueChanges.subscribe(async (newValue) => {
      // tslint:disable-next-line:no-unused-expression
      this.checkErrors; // check for other  errors
      // tslint:disable-next-line:triple-equals
      if (newValue !== undefined && newValue.toString() == '0') {
        this.taluka_list = [];
        return;
      }
      this.taluka_list = await this.runApiCommunication('get-talukas-list', {
        district_id: newValue,
      }).catch(e => {
        this.handlePageErrors(e);
      });
      this.getFormControls.taluka.patchValue(0); // reset
    });

    this.getFormControls.taluka.valueChanges.subscribe(async (newValue) => {
      // tslint:disable-next-line:no-unused-expression
      this.checkErrors; // check for other  errors
      // tslint:disable-next-line:triple-equals
      if (newValue !== undefined && newValue.toString() == '0') {
        this.bmc_list = [];
        return;
      }

      this.bmc_list = await this.runApiCommunication(
        'get-category-details-list',
        {
          taluka_id: newValue,
          area_id: this.getFormControls.area_category.value,
          state_id: this.getFormControls.state.value,
          district_id: this.getFormControls.district.value,
          area_sub_category_id: this.getFormControls.area_sub_category.value,
        }
      ).catch(e => {
        this.handlePageErrors(e);
      });
      if (newValue != '' && (!this.bmc_list || this.bmc_list.length < 1)) {
        Swal.fire({
          html: 'No BMC Data Available',
          showConfirmButton: false,
          toast:true,
          position:'top-right',
          timer: 2500
        })
      }
      this.getFormControls.bmc_company.patchValue(0); // reset
    });

    this.getFormControls.bmc_company.valueChanges.subscribe(
      async (newValue) => {
        // tslint:disable-next-line:no-unused-expression
        this.checkErrors; // check for other  errors
        // tslint:disable-next-line:triple-equals
        if (newValue !== undefined && newValue.toString() == '0') {
          return;
        }
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.bmc_list.length; i++) {
          // tslint:disable-next-line:triple-equals
          if (this.bmc_list[i].bmc_id == newValue) {
            let newUserName = this.bmc_list[i].bmc_name + newValue;
            newUserName = newUserName.replace(/\s+/g, '');
            this.getFormControls.username.patchValue(newUserName);
          }
        }
      }
    );
    this.ngxService.stop();
  }

  PatchAnyExistingValue(autoFill: boolean = false) {
    this.getFormControls.area_category.patchValue(2);
    let eachDelay = 1000;
    this.delay(eachDelay).then(() => this.getFormControls.area_sub_category.patchValue(6));
    eachDelay += 1000; this.delay(eachDelay).then(() => this.getFormControls.state.patchValue(1));
    eachDelay += 1000; this.delay(eachDelay).then(() => this.getFormControls.district.patchValue(23));
    eachDelay += 1000; this.delay(eachDelay).then(() => this.getFormControls.taluka.patchValue(58));
    eachDelay += 1000; this.delay(eachDelay).then(() => this.getFormControls.bmc_company.patchValue(156));
    const date = new Date();
    this.getFormControls.cordinator_name.patchValue('Pooja Tyagi' + date.getMilliseconds());
    this.getFormControls.cordinator_mobile.patchValue(9717911539);
    this.getFormControls.cordinator_email.patchValue("a" + date.getMilliseconds() + "@gmail.com");

    this.getFormControls.gross_population.patchValue(110);
    this.getFormControls.area_hectare.patchValue(10);
    // this.getFormControls.username.patchValue('');
    this.getFormControls.password.patchValue('12345678');
    this.getFormControls.re_password.patchValue('12345678');
  }
  delay(time) {
    return new Promise(resolve => setTimeout(resolve, time));
  }
  getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }

  async bindAllFormControlsInit() {
    this.ngxService.start();

    this.area_category_list = await this.runApiCommunication(
      'get-areas-list',
      null
    ).catch(e => {
      this.handlePageErrors(e);
    });
    this.state_list = await this.runApiCommunication('get-states-list', null).catch(e => {
      this.handlePageErrors(e);
    });

    this.ngxService.stop();
  }

  async registerBmcCompany() {
    if (this.isOTPErrorsExists) {
      return;
    }
    let otpVerificationFailed = false;
    const Data = await this.runApiCommunication(
      'verify-otp',
      {
        username: this.getFormControls.username.value,
        mobile_no: this.getFormControls.cordinator_mobile.value,
        otp: this.OTP
      }
    ).catch(e => {
      otpVerificationFailed = true;
    });

    if (otpVerificationFailed) {
      console.warn('otpVerificationFailed');
      return;
    }
    else {
      this.modalOTPContentRef.hide();
    }

    const ApiData = {
      email: this.getFormControls.username.value,
      password: this.getFormControls.password.value.toString() ? calculateSHA256(this.getFormControls.password.value.toString()) : null,
      // tslint:disable-next-line:max-line-length
      password_confirmation: this.getFormControls.re_password.value.toString() ? calculateSHA256(this.getFormControls.re_password.value.toString()) : null,
      user_role_id: '5',
      area_id: this.getFormControls.area_category.value,
      area_sub_category_id: this.getFormControls.area_sub_category.value,
      state_id: this.getFormControls.state.value,
      district_id: this.getFormControls.district.value,
      taluka_id: this.getFormControls.taluka.value,
      category_detail_id: this.getFormControls.bmc_company.value,
      co_ordinator_name: this.getFormControls.cordinator_name.value,
      co_ordinator_mobile: this.getFormControls.cordinator_mobile.value,
      co_ordinator_email: this.getFormControls.cordinator_email.value,
      gross_population: this.getFormControls.gross_population.value,
      area_in_hectare: this.getFormControls.area_hectare.value,
    };

    // console.log(ApiData);
    const APIResult = await this.runApiCommunication('register-user', ApiData).catch(e => {
      this.handlePageErrors(e);
    });
    if (APIResult && APIResult.area_id) {
      Swal.fire({
        title: 'Registration done',
        icon: 'success',
      }).then((result) => { window.location.reload(); });
    }
  }

  runApiCommunication(fromPath: string, params: any): Promise<any> {
    const dataPromise = new Promise((resolve, reject) => {
      this.restService
        .postDataToAPI(params, fromPath)
        .subscribe((resp: any) => {
          if (resp && resp.status === 'success' && resp.code === 200) {
            resolve(resp.result);
          } else {
            Swal.fire({
              title: 'Error',
              icon: 'error',
              text: resp.message,
            });
            // reject([]);
          }
        }, error => {
          Swal.fire({
            title: 'Error',
            icon: 'error',
            text: error.message,
            showConfirmButton: false,
            timer: 1500,
          });
          // reject([]);
        });
    });
    dataPromise.catch(function onReject(e) {
      Swal.fire({
        title: 'Error',
        icon: 'error',
        text: e.message,
      });
      // console.log('some problem happened', e);
    });
    return dataPromise;
  }
  // tslint:disable-next-line:variable-name
  async sendOTP(otpContent: any, open_otpContent: boolean) {

    this.submitted = true;
    this.error = null;

    if (this.registrationFormGroup.invalid) {
      // tslint:disable-next-line:no-unused-expression
      this.checkErrors; // check for other errors as well
      return;
    }

    if (this.checkErrors) {
      return;
    }

    if (open_otpContent) {
      this.modalOTPContentRef = this.modalService.show(otpContent);
    }

    this.OTPArray.reset();

    await this.runApiCommunication(
      'send-otp',
      {
        username: this.getFormControls.username.value,
        mobile_no: this.getFormControls.cordinator_mobile.value
      }
    ).catch(e => {
      this.handlePageErrors(e);
    });
    this.resendOTPDelayTime = this.defaultResendOTPDelayTime;

    this.otpIntervalVar = setInterval(x => {
      this.resendOTPDelayTime--;
      if (this.resendOTPDelayTime < 1) {
        this.clearOTPIntervalVar();
      }
    }, 1000);
  }
  clearOTPIntervalVar() {
    if (this.otpIntervalVar) {
      clearInterval(this.otpIntervalVar);
    }
  }


  handlePageErrors(e: any) {
    // Swal.fire({
    //   title: 'Error',
    //   icon: 'error',
    //   text: e.message,
    //   toast: true
    // }).then((result) => { });
  }
}
