<app-header></app-header>
<div class="container" ngxUiLoaderBlurred blur="1">
  <form [formGroup]="loginForm" (ngSubmit)="logIn(loginForm.value)">
    <div>
      <div class="row px-md-5 py-4">
        <div class="col-lg-6 col-12 col-sm-6  px-md-5">
          <div class="font-size-23 font-weight-bold">Maharashtra State Biodiversity Board</div>
          <div class="font-size-13 pr-5 mr-3">Department of Maharashtra Biodiversity, Government of Maharashtra
          </div>
          <div class="font-size-40 font-weight-bold py-2">Reset Password</div>
          <div class="row">
            <div class="col-lg-7 col-12">
                            <span>
                                <i class="fa fa-user position-absolute login-input-icons"></i>
                                <input type="text" class="form-input-text form-control login-input-with-icons"
                                       placeholder="Username" formControlName="email" disabled="true"/>
                            </span>
              <div
                *ngIf="loginForm.controls['email'].invalid && (loginForm.controls['email'].dirty || loginForm.controls['email'].touched)">
                                <span [hidden]="!loginForm.controls['email'].errors.required"
                                      class="text-red font-size-12 error-text">User Name is required. </span>
                <span [hidden]="!loginForm.controls['email'].errors.pattern"
                      class="text-red font-size-12 error-text">Please enter a valid user name. </span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-7 col-12">
                            <span>
                                <i class="fa fa-unlock-alt position-absolute login-input-icons"></i>
                                <input type="password" class=" form-input-text form-control login-input-with-icons"
                                       placeholder="Password" formControlName="password" />
                            </span>
              <div
                *ngIf="loginForm.controls['password'].invalid && (loginForm.controls['password'].dirty || loginForm.controls['password'].touched)">
                                <span [hidden]="!loginForm.controls['password'].errors.required"
                                      class="text-red font-size-12 error-text">Password is required. </span>


                <span [hidden]="!loginForm.controls['password'].errors.mustMatch"
                      class="text-red font-size-12 error-text">Password do not match. </span>



                <span [hidden]="!loginForm.controls['password'].errors.mustUpp"
                      class="text-red font-size-12 error-text">Please Enter An Uppercase Letter. </span>



                <span [hidden]="!loginForm.controls['password'].errors.mustLow"
                      class="text-red font-size-12 error-text">Please Enter A Lowercase Letter. </span>



                <span [hidden]="!loginForm.controls['password'].errors.mustNum"
                      class="text-red font-size-12 error-text">Please Enter A Number. </span>



                <span [hidden]="!loginForm.controls['password'].errors.mustSpc"
                      class="text-red font-size-12 error-text">Please Enter A Special Character. </span>


                <span [hidden]="!loginForm.controls['password'].errors.mustLen"
                      class="text-red font-size-12 error-text">Password length should be equal to or greater than 8 characters. </span>

              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-7 col-12">
                            <span>
                                <i class="fa fa-unlock-alt position-absolute login-input-icons"></i>
                                <input type="password" class=" form-input-text form-control login-input-with-icons"
                                       placeholder="Password Confirmation" formControlName="password_confirmation" />
                            </span>
              <div
                *ngIf="loginForm.controls['password_confirmation'].invalid && (loginForm.controls['password_confirmation'].dirty || loginForm.controls['password_confirmation'].touched)">
                                <span [hidden]="!loginForm.controls['password_confirmation'].errors.required"
                                      class="text-red font-size-12 error-text">Password Confirmation is required. </span>

                <span [hidden]="!loginForm.controls['password_confirmation'].errors.mustMatch"
                                      class="text-red font-size-12 error-text">Password do not match. </span>

              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-7 col-12 py-2">
              <p class="p-modifie">Type the Security Code shown:</p>
            </div>
          </div>
          <div class="row">
            <div class="col-7">
              <div class="captcha-modify w-100">
                                <span onmousedown='false;' onselectstart='false;'>{{passcode}}
                                </span>
              </div>
            </div>
            <div class="col-5 pl-0 verticle-content-middle">
                            <span>
                                <a type="button" class="d-block" (click)="getRandomString()"><img
                                  src="assets/images/load.svg"></a>
                            </span>
            </div>

            <div class="col-12 col-lg-7 pt-1">
              <input onpaste="false" autocomplete="off" type="text" name="passcode" required
                     formControlName="passcode" class="form-input-text form-control ">
              <div *ngIf="submitted">
                                <span *ngIf="loginForm.controls['passcode'].errors"
                                      [hidden]="!loginForm.controls['passcode'].errors.required"
                                      class="text-red font-size-12 error-text"> Required. </span>

                <span *ngIf="validPasscode == false && loginForm.controls['passcode'].errors == null"
                      class="text-red font-size-12 error-text">Invalid Code
                                </span>

              </div>

            </div>

          </div>
          <div class="row">
            <div class="col-12 col-lg-7">
              <button type="submit"
                      class="font-size-15 btn text-white bio-blue-bg-color mt-3 py-2 button-radius w-100">Submit</button>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-lg-7">
              <div class="hr-sect mt-3 justify-content-center d-flex bio-blue-text-color" role="button"
                   routerLink="/login">Login</div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-12 col-sm-6 pt-3 mt-3">
          <img src="assets/images/logo.png" alt="logo" style="width:85%; margin-left: 10%;"
               class="pt-3 mt-3 d-none d-md-block" />
        </div>

      </div>
    </div>
  </form>
</div>
