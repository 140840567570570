<app-header></app-header>
<div class="mt-3 px-md-5 py-3">
    <div class="col-12 col-sm-12 pl-4">
        <div class="header pt-4 pb-1 mt-3">
            <div class="row w-100">
                <div class="col-md-9">
                    <h4 class="font-size-32 font-weight-bold">{{categoriesName}}</h4>
                </div>
                <div *ngIf="categoriesName != 'e-BMC Forms'" class="col-md-3">
                    <a type="button"
                        class="button font-size-15 btn text-white bio-blue-bg-color py-2 button-radius float-right"
                        [routerLink]="['/user/upload-epbr-document/'+ categoriesName + '/' + id]">Upload e-PBR - PDF</a>
                </div>
            </div>
        </div>

        <!-- formListGroupedBy_form_type.keys() will keep sorting of the form_type intact -->
        <div *ngFor="let eachForm_Type_Name of formListGroupedBy_form_type.keys()" class="main-content ">
            <hr>
            <h4 *ngIf="eachForm_Type_Name != 'EMPTY'" class="font-size-20">{{eachForm_Type_Name}}</h4>
            <div class="pt-4 main-content pt-3">
                <div class="row">
                    <div *ngFor="let d of formListGroupedBy_form_type.get(eachForm_Type_Name)"
                        class="col-12 col-sm-6 col-md-3">
                        <div class="box-content">
                            <div class="pb-3">
                                <a [title]="d.form_subtext ? d.form_subtext : ''"
                                    class="button font-size-15 btn text-white bio-blue-bg-color w-100 py-2   button-radius"
                                    [routerLink]="['/user/categories-form/' + d.form_title + '/' + id + '/form/' + d.form_text, d.id]">{{d.form_text}}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

