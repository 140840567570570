<app-header></app-header>
<div class="container-fluid pr-5 pl-5 justify-content-center">
    <div class="row">
        <div class="col-12 mt-4 d-flex justify-content-lg-start">
            <div class="row col-12">
                <div class=" col-md-8 col-sm-12 font-size-32 font-weight-bold pl-4">
                    e-PBR - PDF</div>
                <div class=" col-md-4 col-sm-12">
                    <form [formGroup]="ngFilter" id="ngFilter" (ngSubmit)="getUserUploadedList()" autocomplete="off"
                        style="zoom: 0.8">
                        <mat-form-field appearance="outline" style="display: initial">
                            <input matInput placeholder="Search" formControlName="search">
                            <mat-icon role="button" (click)="getUserUploadedList()" matSuffix><i
                                    class="fa fa-search bio-blue-text-color"></i></mat-icon>
                        </mat-form-field>
                    </form>
                </div>
            </div>
        </div>
        <div class="col-md-12 pt-1">
            <div class="mat-elevation-z8 table-responsive table-active table-hover table-div pr-2 pl-1">
                <table class="e-BMC_e-PBR-table" mat-table [dataSource]="dataSource" matSort
                    matSortActive="{{matSortActive}}" matSortDisableClear matSortDirection="{{matSortDirection}}">
                    <ng-container [matColumnDef]="col" *ngFor="let col of displayedColumns; index as j">
                        <ng-container *ngIf="col=='serial'">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                [style]="columnInformations[j].style ? columnInformations[j].style : ''">
                                {{columnInformations[j].label ? columnInformations[j].label : ''}}
                            </th>
                            <td mat-cell *matCellDef="let element, index as i" class="serial">
                                {{ paginator.pageIndex * this.paginator.pageSize + i + 1 }}
                            </td>
                        </ng-container>
                        <ng-container *ngIf="!['serial', 'file_path'].includes(col)">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header [style]="columnInformations[j].style"
                                class="">
                                {{columnInformations[j].label ? columnInformations[j].label : ''}}
                            </th>
                            <td mat-cell *matCellDef="let element" class="{{col}} text-capitalize">
                                <span>{{!element[col] || element[col] == "" ? "NA" :
                                    element[col]}}</span>
                            </td>
                        </ng-container>
                        <ng-container *ngIf="['file_path'].includes(col)">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header [style]="columnInformations[j].style"
                                class="">
                                {{columnInformations[j].label ? columnInformations[j].label : ''}}
                            </th>
                            <td mat-cell *matCellDef="let element" class="{{col}} text-capitalize">
                                <img [src]="element['file_type'].indexOf('pdf') > -1 ? '/assets/images/pdf.png' : RestApi.apiBaseUrl + element[col]"
                                    width="30"
                                    (click)="showFile(filePreviewModal, element['base64'], element['file_type'])"
                                    class="cursor-pointer">
                            </td>

                        </ng-container>

                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">
                    </tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
            <div class="no-record-div text-center pt-5 pb-5" *ngIf="(total==0)">
                No Record Found
            </div>
            <div class="stats">
                <mat-paginator [length]="total" [pageSize]="page_size" [pageSizeOptions]="[5,10,20,50,100]"
                    showFirstLastButtons>
                </mat-paginator>
            </div>
        </div>
    </div>
</div>



<ng-template #filePreviewModal>
  <form>
    <div class="modal-body p-4 h-100">
      <button type="button" class="close modal-cross pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">×</span>
      </button>
      <div>
                       <!-- <div class="font-size-20 font-weight-bold pb-3">Document Preview</div>
             <button class="btn btn-primary" (click)="downloadFile(RestApi.ImageUrl + fileUrl)">
         <i class="fa fa-download"></i>
      </button> -->
        <div class="theme mt-1 py-1 text-center">
            <!-- {{fileUrl}} -->
                <img *ngIf="fileType === 'image'" [src]="'data:image/jpeg;base64,' + fileUrl" style="max-width: 750px;" class="w-100" (error)="onError($event)">
            <pdf-viewer *ngIf="fileType === 'pdf'" [src]="'data:application/pdf;base64,' + fileUrl" [render-text]="true" (on-progress)="onProgress($event)" (after-load-complete)="onComplete($event)" (error)="onError($event)" style="display: block;"></pdf-viewer>

          <div *ngIf="isError == true" class="text-center pt-3 pb-3" style="z-index: 9999; left: 40%; top: 30%">
            Not Found
          </div>
        </div>
      </div>
      <div *ngIf="isLoading == true" class="spinner pb-5" style="z-index: 9999; left: 40%; top: 30%">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
      </div>
    </div>
  </form>
</ng-template>
