<app-header></app-header>
<form [formGroup]="registrationFormGroup">
  <div class="row justify-content-center d-flex mx-1">
    <div class="col-11">
      <div class="mt-5 d-flex justify-content-center">
        <div class="font-size-24 font-weight-bold">Registration of BMC</div>
      </div>
      <div class="row">
        <div class="col-12">
          <hr class="border-bottom" />
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-lg-3 col-sm-6 col-12 pt-3">
          <select
            class="form-control form-input-text w-100"
            formControlName="area_category"
          >
            <option value="0">-Select Category</option>
            <option
              *ngFor="let each_area_category of area_category_list"
              [ngValue]="each_area_category.acat_id"
            >
              {{ each_area_category.acat_name }}
            </option>
          </select>
          <label class="form-label font-size-15 font-weight-bold"
            ><span class="mendatory-text">*</span> Area Category:</label
          >
          <div *ngIf="submitted && getFormControls.area_category.errors">
            <span
              [hidden]="!getFormControls.area_category.errors.required"
              class="font-size-12"
            >
              <i class="fas fa-exclamation-circle error-text mr-1"></i>Select
              Area Category.
            </span>
          </div>
          <div
            *ngIf="submitted && getFormControls.area_category['custom_errors']"
          >
            <span
              [hidden]="
                !getFormControls.area_category.custom_errors ||
                !getFormControls.area_category.custom_errors.invalid_selection
              "
              class="font-size-12"
            >
              <i class="fas fa-exclamation-circle error-text mr-1"></i>Select A
              valid Area Category.
            </span>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6 col-12 pt-3">
          <select
            class="form-control form-input-text w-100"
            formControlName="area_sub_category"
          >
            <option value="0">-Select Sub Category</option>
            <option
              *ngFor="let each_sub_area_category of sub_area_category_list"
              [ngValue]="each_sub_area_category.ascat_id"
            >
              {{ each_sub_area_category.ascat_name }}
            </option>
          </select>
          <label class="form-label font-size-15 font-weight-bold"
            ><span class="mendatory-text">*</span> Area Sub Category:</label
          >
          <div *ngIf="submitted && getFormControls.area_sub_category.errors">
            <span
              [hidden]="!getFormControls.area_sub_category.errors.required"
              class="font-size-12"
            >
              <i class="fas fa-exclamation-circle error-text mr-1"></i>Select
              Area Sub Category.
            </span>
          </div>
          <div
            *ngIf="
              submitted && getFormControls.area_sub_category['custom_errors']
            "
          >
            <span
              [hidden]="
                !getFormControls.area_sub_category.custom_errors ||
                !getFormControls.area_sub_category.custom_errors
                  .invalid_selection
              "
              class="font-size-12"
            >
              <i class="fas fa-exclamation-circle error-text mr-1"></i>Select A
              valid Area Sub Category.
            </span>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6 col-12 pt-3">
          <select
            class="form-control form-input-text w-100"
            formControlName="state"
          >
            <option value="0">-Select State</option>
            <option
              *ngFor="let each_state of state_list"
              [ngValue]="each_state.state_id"
            >
              {{ each_state.state_name }}
            </option>
          </select>
          <label class="form-label font-size-15 font-weight-bold"
            ><span class="mendatory-text">*</span> State:</label
          >
          <div *ngIf="submitted && getFormControls.state.errors">
            <span
              [hidden]="!getFormControls.state.errors.required"
              class="font-size-12"
            >
              <i class="fas fa-exclamation-circle error-text mr-1"></i>Select
              State.
            </span>
          </div>
          <div *ngIf="submitted && getFormControls.state['custom_errors']">
            <span
              [hidden]="
                !getFormControls.state.custom_errors ||
                !getFormControls.state.custom_errors.invalid_selection
              "
              class="font-size-12"
            >
              <i class="fas fa-exclamation-circle error-text mr-1"></i>Select A
              valid State Category.
            </span>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6 col-12 pt-3">
          <select
            class="form-control form-input-text w-100"
            formControlName="district"
          >
            <option value="0">-Select District</option>
            <option
              *ngFor="let each_district of district_list"
              [ngValue]="each_district.district_id"
            >
              {{ each_district.district_name }}
            </option>
          </select>
          <label class="form-label font-size-15 font-weight-bold"
            ><span class="mendatory-text">*</span> District:</label
          >
          <div *ngIf="submitted && getFormControls.district.errors">
            <span
              [hidden]="!getFormControls.district.errors.required"
              class="font-size-12"
            >
              <i class="fas fa-exclamation-circle error-text mr-1"></i>Select
              District.
            </span>
          </div>
          <div *ngIf="submitted && getFormControls.district['custom_errors']">
            <span
              [hidden]="
                !getFormControls.district.custom_errors ||
                !getFormControls.district.custom_errors.invalid_selection
              "
              class="font-size-12"
            >
              <i class="fas fa-exclamation-circle error-text mr-1"></i>Select A
              valid District
            </span>
          </div>
        </div>

        <div class="col-12">
          <hr class="border-bottom" />
        </div>
        <div class="col-lg-3 col-sm-6 col-12 pt-3">
          <select
            class="form-control form-input-text w-100"
            formControlName="taluka"
          >
            <option value="0">-Select Taluka</option>
            <option
              *ngFor="let each_taluka of taluka_list"
              [ngValue]="each_taluka.taluka_id"
            >
              {{ each_taluka.taluka_name }}
            </option>
          </select>
          <label class="form-label font-size-15 font-weight-bold"
            ><span class="mendatory-text">*</span> Taluka:</label
          >

          <div *ngIf="submitted && getFormControls.taluka.errors">
            <span
              [hidden]="!getFormControls.taluka.errors.required"
              class="font-size-12"
            >
              <i class="fas fa-exclamation-circle error-text mr-1"></i>Select
              Taluka.
            </span>
          </div>
          <div *ngIf="submitted && getFormControls.taluka['custom_errors']">
            <span
              [hidden]="
                !getFormControls.taluka.custom_errors ||
                !getFormControls.taluka.custom_errors.invalid_selection
              "
              class="font-size-12"
            >
              <i class="fas fa-exclamation-circle error-text mr-1"></i>Select A
              valid Taluka
            </span>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6 col-12 pt-3">
          <select
            class="form-control form-input-text w-100"
            formControlName="bmc_company"
          >
            <option value="0">-Select BMC Name</option>
            <option
              *ngFor="let each_bmc of bmc_list"
              [ngValue]="each_bmc.bmc_id"
            >
              {{ each_bmc.bmc_name }}
            </option>
          </select>
          <label class="form-label font-size-15 font-weight-bold"
            ><span class="mendatory-text">*</span> BMC Name:</label
          >

          <div *ngIf="submitted && getFormControls.bmc_company.errors">
            <span
              [hidden]="!getFormControls.bmc_company.errors.required"
              class="font-size-12"
            >
              <i class="fas fa-exclamation-circle error-text mr-1"></i>Select
              BMC Name.
            </span>
          </div>
          <div
            *ngIf="submitted && getFormControls.bmc_company['custom_errors']"
          >
            <span
              [hidden]="
                !getFormControls.bmc_company.custom_errors ||
                !getFormControls.bmc_company.custom_errors.invalid_selection
              "
              class="font-size-12"
            >
              <i class="fas fa-exclamation-circle error-text mr-1"></i>Select A
              valid BMC Name
            </span>
          </div>
        </div>

        <div class="col-lg-3 col-sm-6 col-12 pt-3">
          <input
            type="text"
            class="form-input-text form-control w-100"
            placeholder="Cordinator Name"
            formControlName="cordinator_name"
          />
          <label class="form-label font-size-15 font-weight-bold">
            <span class="mendatory-text">*</span>
            Co-Ordinator Name:</label
          >
          <div *ngIf="submitted && getFormControls.cordinator_name.errors">
            <span
              [hidden]="!getFormControls.cordinator_name.errors.required"
              class="font-size-12"
            >
              <i class="fas fa-exclamation-circle error-text mr-1"></i>Please
              Enter the Cordinator Name.
            </span>
          </div>
          <div
            *ngIf="
              submitted && getFormControls.cordinator_name['custom_errors']
            "
          >
            <span
              [hidden]="
                !getFormControls.cordinator_name.custom_errors ||
                !getFormControls.cordinator_name.custom_errors.invalid_selection
              "
              class="font-size-12"
            >
              <i class="fas fa-exclamation-circle error-text mr-1"></i>Select A
              valid Cordinator Name
            </span>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6 col-12 pt-3">
          <input
            type="text"
            class="form-input-text form-control w-100"
            placeholder="Cordinator Mobile"
            formControlName="cordinator_mobile"
            (keydown)="checkNumber($event)"
            maxlength="10"
          />
          <label class="form-label font-size-15 font-weight-bold">
            <span class="mendatory-text">*</span>
            Co-Ordinator Mobile:</label
          >
          <div *ngIf="submitted && getFormControls.cordinator_mobile.errors">
            <span
              [hidden]="!getFormControls.cordinator_mobile.errors.required"
              class="font-size-12"
            >
              <i class="fas fa-exclamation-circle error-text mr-1"></i>Please
              enter the Co-Ordinator Mobile .
            </span>
          </div>
          <div *ngIf="submitted && getFormControls.cordinator_mobile.errors">
            <span
              [hidden]="!getFormControls.cordinator_mobile.errors.pattern"
              class="font-size-12"
            >
              <i class="fas fa-exclamation-circle error-text mr-1"></i>
              Please Enter 10 digit Co-Ordinator Mobile</span
            >
          </div>

          <div
            *ngIf="
              submitted && getFormControls.cordinator_mobile['custom_errors']
            "
          >
            <span
              [hidden]="
                !getFormControls.cordinator_mobile.custom_errors ||
                !getFormControls.cordinator_mobile.custom_errors
                  .invalid_selection
              "
              class="font-size-12"
            >
              <i class="fas fa-exclamation-circle error-text mr-1"></i>Select
              valid Cordinator Mobile
            </span>
          </div>
        </div>
        <div class="col-12">
          <hr class="border-bottom" />
        </div>

        <div class="col-lg-3 col-sm-6 col-12 pt-3">
          <input
            type="text"
            class="form-input-text form-control w-100"
            placeholder="Cordinator Email"
            formControlName="cordinator_email"
          />
          <label class="form-label font-size-15 font-weight-bold">
            <span class="mendatory-text">*</span>
            Co-Ordinator Email:</label
          >
          <div *ngIf="submitted && getFormControls.cordinator_email.errors">
            <span
              [hidden]="!getFormControls.cordinator_email.errors.required"
              class="font-size-12"
            >
              <i class="fas fa-exclamation-circle error-text mr-1"></i>Please
              Enter the Co-Ordinator Email .
            </span>
          </div>
          <div *ngIf="submitted && getFormControls.cordinator_email.errors">
            <span
              [hidden]="!getFormControls.cordinator_email.errors.pattern"
              class="font-size-12"
            >
              <i class="fas fa-exclamation-circle error-text mr-1"></i>
              Please Enter Valid Co-Ordinator Email</span
            >
          </div>
          <div
            *ngIf="
              submitted && getFormControls.cordinator_email['custom_errors']
            "
          >
            <span
              [hidden]="
                !getFormControls.cordinator_email.custom_errors ||
                !getFormControls.cordinator_email.custom_errors
                  .invalid_selection
              "
              class="font-size-12"
            >
              <i class="fas fa-exclamation-circle error-text mr-1"></i>Select A
              valid Cordinator Email
            </span>
          </div>
        </div>

        <div class="col-lg-3 col-sm-6 col-12 pt-3">
          <input
            type="text"
            class="form-input-text form-control w-100"
            placeholder="Gross Population"
            formControlName="gross_population"
            (keydown)="checkNumber($event)"
          />
          <label class="form-label font-size-15 font-weight-bold">
            <span class="mendatory-text">*</span>
            Gross Population:</label
          >
          <div *ngIf="submitted && getFormControls.gross_population.errors">
            <span
              [hidden]="!getFormControls.gross_population.errors.required"
              class="font-size-12"
            >
              <i class="fas fa-exclamation-circle error-text mr-1"></i>Please
              Enter the Gross Population.
            </span>
          </div>
          <div
            *ngIf="
              submitted && getFormControls.gross_population['custom_errors']
            "
          >
            <span
              [hidden]="
                !getFormControls.gross_population.custom_errors ||
                !getFormControls.gross_population.custom_errors
                  .invalid_selection
              "
              class="font-size-12"
            >
              <i class="fas fa-exclamation-circle error-text mr-1"></i>Select A
              valid Gross Population
            </span>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6 col-12 pt-3">
          <input
            type="text"
            class="form-input-text form-control w-100"
            placeholder="Area Hectare"
            formControlName="area_hectare"
            (keydown)="checkNumber($event)"
          />
          <label class="form-label font-size-15 font-weight-bold">
            <span class="mendatory-text">*</span>
            Area(in Hectare):</label
          >
          <div *ngIf="submitted && getFormControls.area_hectare.errors">
            <span
              [hidden]="!getFormControls.area_hectare.errors.required"
              class="font-size-12"
            >
              <i class="fas fa-exclamation-circle error-text mr-1"></i>Please
              Enter the Area.
            </span>
          </div>
          <div
            *ngIf="submitted && getFormControls.area_hectare['custom_errors']"
          >
            <span
              [hidden]="
                !getFormControls.area_hectare.custom_errors ||
                !getFormControls.area_hectare.custom_errors.invalid_selection
              "
              class="font-size-12"
            >
              <i class="fas fa-exclamation-circle error-text mr-1"></i>Select A
              valid Area
            </span>
          </div>
        </div>

        <div class="col-12">
          <hr class="border-bottom" />
        </div>
        <div class="col-lg-3 col-sm-6 col-12 pt-3">
          <input
            type="text"
            class="form-input-text form-control w-100"
            placeholder="Username"
            formControlName="username"
            readonly
          />
          <label class="form-label font-size-15 font-weight-bold">
            <span class="mendatory-text">*</span>
            Username:</label
          >
          <div *ngIf="submitted && getFormControls.username.errors">
            <span
              [hidden]="!getFormControls.username.errors.required"
              class="font-size-12"
            >
              <i class="fas fa-exclamation-circle error-text mr-1"></i>Please
              Enter the Username.
            </span>
          </div>
          <div *ngIf="submitted && getFormControls.username['custom_errors']">
            <span
              [hidden]="
                !getFormControls.username.custom_errors ||
                !getFormControls.username.custom_errors.invalid_selection
              "
              class="font-size-12"
            >
              <i class="fas fa-exclamation-circle error-text mr-1"></i>Select A
              valid Username
            </span>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6 col-12 pt-3">
          <input
            type="password"
            class="form-input-text form-control w-100"
            placeholder="Password"
            formControlName="password"
          />
          <label class="form-label font-size-15 font-weight-bold"
            ><span class="mendatory-text">*</span> Password:</label
          >
          <div *ngIf="submitted && getFormControls.password.errors">
            <span
              [hidden]="!getFormControls.password.errors.required"
              class="font-size-12"
            >
              <i class="fas fa-exclamation-circle error-text mr-1"></i>Please
              Enter the Password.
            </span>
          </div>
          <div *ngIf="submitted && (registrationFormGroup.controls['password'].errors)">
            <span [hidden]="!registrationFormGroup.controls.password.errors.required" class="font-size-12"><i class="fas fa-exclamation-circle error-text mr-1"></i>Select A valid Password</span>
            <span [hidden]="!getFormControls.password.errors.mustUpp" class="font-size-12"><i class="fas fa-exclamation-circle error-text mr-1"></i>Please Enter An Uppercase Letter.</span>
            <span [hidden]="!getFormControls.password.errors.mustLow" class="font-size-12"><i class="fas fa-exclamation-circle error-text mr-1"></i>Please Enter A Lowercase Letter.</span>
            <span [hidden]="!getFormControls.password.errors.mustNum" class="font-size-12"><i class="fas fa-exclamation-circle error-text mr-1"></i>Please Enter A Number.</span>
            <span [hidden]="!getFormControls.password.errors.mustSpc" class="font-size-12"><i class="fas fa-exclamation-circle error-text mr-1"></i>Please Enter A Special Character.</span>
            <span [hidden]="!getFormControls.password.errors.mustLen" class="font-size-12"><i class="fas fa-exclamation-circle error-text mr-1"></i>Password length should be equal to or greater than 8 characters.</span>

          </div>
        </div>
        <div class="col-lg-3 col-sm-6 col-12 pt-3">
          <input
            type="password"
            class="form-input-text form-control w-100"
            placeholder="Re-Enter"
            formControlName="re_password"
          />
          <label class="form-label font-size-15 font-weight-bold"
            ><span class="mendatory-text">*</span> Re-Enter Password:</label
          >
          <div *ngIf="submitted && getFormControls.re_password.errors">
            <span [hidden]="!getFormControls.re_password.errors.required" class="font-size-12"><i class="fas fa-exclamation-circle error-text mr-1"></i>Please Enter the Re_password.</span>
          </div>
          <div
            *ngIf="submitted && getFormControls.re_password['custom_errors']"
          >
            <span
              [hidden]="
                !getFormControls.re_password.custom_errors ||
                !getFormControls.re_password.custom_errors.invalid_selection
              "
              class="font-size-12"
            >
              <i class="fas fa-exclamation-circle error-text mr-1"></i>Select A
              valid re_password.
            </span>
          </div>
          <div *ngIf="submitted && getFormControls.re_password.errors">
            <span
              [hidden]="
                !getFormControls.re_password.errors.required.ConfirmedValidator
              "
              class="font-size-12"
            >
              <i class="fas fa-exclamation-circle error-text mr-1"></i>
              Password and Re_Password must be match</span
            >
          </div>
        </div>
      </div>
      <div class="row pb-5 justify-content-center d-flex">
        <div class="w-100 pt-5 mt-3 ml-3">
          <div class="col-12 justify-content-center d-flex">
            <button
              class="
                font-size-15
                btn
                text-white
                bio-blue-bg-color
                w-25
                py-2
                mt-3
                button-radius
              "
              (click)="sendOTP(otpContent, true)"
            >
              Register
            </button>
            <a
              class="
                font-size-15
                btn
                text-white
                bio-blue-bg-color
                ml-3
                w-25
                py-2
                mt-3
                button-radius
              "
              routerLink="/login"
              >Login</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #otpContent let-c="close" let-d="dismiss">
    <div class="modal-body">
      <div class="container-fluid">
        <div class="row d-flex justify-content-center">
          <div class="col-12 pt-3 mt-3 d-flex justify-content-center">
            <h2 class="font-weight-bold font-size-40">Enter OTP</h2>
          </div>
          <div
            class="col-12 pt-4 d-flex justify-content-center"
            style="color: #707070"
          >
            OTP has been sent to mobile number
          </div>
          <div
            class="col-12 d-flex justify-content-center"
            style="color: #707070"
          >
            "{{
              this.getFormControls.cordinator_mobile.value
                .toString()
                .substring(0, 2)
            }}XXXXXX{{
              this.getFormControls.cordinator_mobile.value
                .toString()
                .substring(8, 11)
            }}"
          </div>
          <div class="col-12">
            <div class="row" formArrayName="OTPArray">
              <div
                class="col-3"
                *ngFor="let eachOTPChar of OTPArray.controls; let i = index"
              >
                <input
                  formControlName="{{ i }}"
                  class="otp-input form-input-text form-control text-center"
                  maxlength="1"
                />
              </div>
            </div>
          </div>
          <div class="col-9 d-flex justify-content-center">
            <button
              class="
                font-size-15
                btn
                text-white
                bio-blue-bg-color
                w-75
                py-2
                mt-5
                button-radius
              "
              (click)="registerBmcCompany()"
            >
              Submit
            </button>
          </div>
          <div class="col-9 d-flex justify-content-center hr-sect">
            <button
              [disabled]="resendOTPDelayTime > 0"
              class="bio-blue-text-color py-3 btn btn-link"
              role="button"
              (click)="sendOTP(otpContent, false)"
            >
              Resend OTP
            </button>
          </div>
          <div
            *ngIf="resendOTPDelayTime > 0"
            class="col-9 d-flex justify-content-center"
            style="color: #707070"
          >
            Please wait {{ resendOTPDelayTime }} sec to resend
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</form>
