<div class="content-div">
    <div class="row w-100 d-flex mx-0 justify-content-center">
        <div class="col-12 pl-4 pr-5 py-4">
            <div class="row">
                <div class="col-md-6 col-12 pl-3 pr-1 card2-main-div">
                    <div class="card card-style mx-0 h-100">
                        <div class="card-body">
                            <div class="row" *ngFor="let eachItem of numberofBmcData; let i = index"
                                [ngClass]="i!=0 ? ' mt-3 pt-3': ''">
                                <div class="col-12 col-sm-6 col-md-12 col-lg-7 card-left">
                                    <div class="row">
                                        <div class="col-md-12 font-size-20">
                                            Total {{ [0,1,2].includes(i) ? (i==0 ? "Number of BMCs:" : (i==1 ?
                                            "Registration Under BMCs:" : "Members:")) : "" }}
                                        </div>
                                        <div class="col-md-12 font-size-30 font-weight-bold">
                                            {{eachItem.total}}
                                        </div>
                                    </div>
                                </div>
                                <!--   -->
                                <div class="col-12 col-sm-6 col-md-12 col-lg-5 card-right">
                                    <div class=" float-sm-right  float-md-left  float-card-inner-text">
                                        <div class="font-size-20">
                                            In Urban Sector: {{eachItem.urban}}
                                        </div>
                                        <div class="font-size-20">
                                            In Rural Sector: {{eachItem.rural}}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 pl-2 pr-0 card1-main-div">
                    <div class="card card-style card1 ml-2 mr-0 h-100">
                        <div class="card-body">
                            <div class="row" *ngFor="let eachItem of formSubmitted; let i = index"
                                [ngClass]="i!=0 ? ' mt-3 pt-3': ''">
                                <div class="col-12 col-sm-6 com-md-12 col-lg-7 card-left">
                                    <div class="row">
                                        <div class="col-md-12 font-size-20">
                                            Total Number of {{i == 1 ? "ePBR" : i == 2 ? "eBMC" : ""}} forms submitted:
                                        </div>
                                        <div class="col-md-12 font-size-30 font-weight-bold">
                                            {{eachItem.total}}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6 com-md-12 col-lg-5 card-right">
                                    <div class=" float-sm-right float-md-left float-card-inner-text">
                                        <div class="font-size-20">
                                            In Urban Sector: {{eachItem.urban}}
                                        </div>
                                        <div class="font-size-20">
                                            In Rural Sector: {{eachItem.rural}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row pt-2">
                <div class="col-md-3 pr-0 py-3 pl-3">
                    <!-- graph -->
                    <div class="bg-white mr-2 border-style py-4 pr-1 h-100">
                        <div class="col-12 font-size-18 font-weight-bold position-absolute z-index-500">
                            <div class="row">
                                <div class="col-2 pl-3 pr-0">
                                    <img src="assets/images/pie-chart.svg" alt="img-icon" />
                                </div>
                                <div class="col-10 text-break pl-0 pr-2">
                                    Registered category - {{bmcGraphAreaCategory}} Area
                                </div>
                            </div>
                        </div>
                        <div [hidden]="isLoading.bmcGraphAreaCategory" >
                          <div id="register-chart-container" style="overflow: scroll !important; z-index: 20; position: relative"></div>
                          <div class="col-12 text-center">
                              <mat-button-toggle-group value="1" (change)="toggle('bmcGraph', $event.value)">
                                  <mat-button-toggle value="1">Urban</mat-button-toggle>
                                  <mat-button-toggle value="2">Rural</mat-button-toggle>
                              </mat-button-toggle-group>
                          </div>
                        </div>
                        <div *ngIf="isLoading.bmcGraphAreaCategory" class="spinner position-absolute" style="z-index: 9999; left: 40%; top: 30%">
                          <div class="bounce1"></div>
                          <div class="bounce2"></div>
                          <div class="bounce3"></div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 pr-0 py-3 pl-4 pr-1">
                    <div class="row bg-white mr-0 border-style h-100">
                        <div class="col-12 font-size-18 font-weight-bold py-4 light-border-bottom">
                            <div class="row">
                                <div class="col-2 pl-3 pr-0">
                                    <img src="assets/images/categories.svg" alt="img-icon" />
                                </div>
                                <div class="col-10 text-break pl-0 pr-2">
                                    Registered BMC Revenue Division wise
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div [hidden]="isLoading.resisteredBmcRevenueWise" class="col-12 col-md-12 col-sm-12 font-size-16 px-0 my-custom-scrollbar scrollbar-thin">
                            <div class="row table font-size-10 d-md-flex d-none bold-border-bottom m-0 p-0 font-weight-bold">
                                <div class="col-12 col-md-2 text-center light-border-right py-3 px-1">#</div>
                                <div class="col-12 col-md-7 text-center light-border-right py-3 px-1">Revenue Division
                                </div>
                                <div class="col-12 col-md-3 text-center py-3 px-1">Total</div>
                            </div>
                            <div *ngFor="let item of resisteredBmcRevenueWise; let i = index;"
                                class="row mx-0 font-size-16 light-border-bottom text-center">
                                <div class="col-12 col-md-2 light-border-right py-3 px-1">
                                    <div class="d-title d-md-none">#</div>
                                    <div class="d-value">{{i+1}}</div>
                                </div>
                                <div class="col-12 col-md-7 light-border-right py-3 px-1">
                                    <div class="d-title d-md-none">Revenue Division</div>
                                    <div class="d-value">{{item.rdivision_name}}</div>
                                </div>
                                <div class="col-12 col-md-3 py-3 px-1">
                                    <div class="d-title d-md-none">Total</div>
                                    <div class="d-value">{{item.registered}}</div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="isLoading.resisteredBmcRevenueWise" class="spinner position-absolute" style="z-index: 9999; left: 40%; top: 30%">
                          <div class="bounce1"></div>
                          <div class="bounce2"></div>
                          <div class="bounce3"></div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 pr-0 py-3">
                    <!-- graph -->
                    <div class="bg-white mr-2 border-style py-4 h-100">
                        <div class="col-12 font-size-18 font-weight-bold position-absolute z-index-500">
                            <div class="row">
                                <div class="col-2 pl-3 pr-0">
                                    <img src="assets/images/pie-chart.svg" alt="img-icon" />
                                </div>
                                <div class="col-10  text-break pl-0 pr-2">
                                    Submitted forms category - {{formGraphAreaCategory}} Area
                                </div>
                            </div>
                        </div>

                        <div [hidden]="isLoading.formGraphAreaCategory" >
                          <div id="submit-chart-container" style="overflow: scroll !important; z-index: 20; position: relative"></div>
                          <div class="col-12 text-center">
                            <mat-button-toggle-group value="1" (change)="toggle('formGraph', $event.value)">
                              <mat-button-toggle value="1">Urban</mat-button-toggle>
                              <mat-button-toggle value="2">Rural</mat-button-toggle>
                            </mat-button-toggle-group>
                          </div>
                        </div>
                        <div *ngIf="isLoading.formGraphAreaCategory" class="spinner position-absolute" style="z-index: 9999; left: 40%; top: 30%">
                          <div class="bounce1"></div>
                          <div class="bounce2"></div>
                          <div class="bounce3"></div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 pr-0 py-3 pl-4">
                    <div class="row bg-white mr-0 border-style h-100">
                        <div class="col-12 font-size-18 font-weight-bold py-4 light-border-bottom">
                            <div class="row">
                                <div class="col-2 pl-3 pr-0">
                                    <img src="assets/images/categories.svg" alt="img-icon" />
                                </div>
                                <div class="col-10 text-break pl-0 pr-2">
                                    Submitted form Revenue Division wise
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div [hidden]="isLoading.submittedRevenueWise" class="col-12 col-md-12 col-sm-12 font-size-16 px-0 my-custom-scrollbar scrollbar-thin">
                            <div class="row table font-size-10 d-md-flex d-none bold-border-bottom m-0 p-0 font-weight-bold">
                                <div class="col-12 col-md-2 text-center light-border-right py-3 px-1">#</div>
                                <div class="col-12 col-md-7 text-center light-border-right py-3 px-1">Revenue Division
                                </div>
                                <div class="col-12 col-md-3 text-center py-3 px-1">Total</div>
                            </div>
                            <div *ngFor="let item of submittedRevenueWise; let i = index;"
                                class="row mx-0 font-size-16 light-border-bottom text-center">
                                <div class="col-12 col-md-2 light-border-right py-3 px-1">
                                    <div class="d-title d-md-none">#</div>
                                    <div class="d-value">{{i+1}}</div>
                                </div>
                                <div class="col-12 col-md-7 light-border-right py-3 px-1">
                                    <div class="d-title d-md-none">Revenue Division</div>
                                    <div class="d-value">{{item.rdivision_name}}</div>
                                </div>
                                <div class="col-12 col-md-3 py-3 px-1">
                                    <div class="d-title d-md-none">Total</div>
                                    <div class="d-value">{{item.forms_submitted}}</div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="isLoading.submittedRevenueWise" class="spinner position-absolute" style="z-index: 9999; left: 40%; top: 30%">
                          <div class="bounce1"></div>
                          <div class="bounce2"></div>
                          <div class="bounce3"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row bg-white border-style pb-4 ml-1">
              <form class="w-100" [formGroup]="ngFilter">
                    <div class="row px-4">
                        <div [hidden]="![1,7].includes(userRoleId)" class="col-lg-2 col-md-2 col-12 px-1">
                            <select [(ngModel)]="revDivId" class="form-control form-input-text w-100 select-style" formControlName="rev_div_id" (change)="getDistrictList()">
                                <option value="">Select Division</option>
                                <option *ngFor="let eachDivision of division" [ngValue]="eachDivision.revenu_div_id" >
                                    {{ eachDivision.rdivision_name }}
                                </option>
                            </select>
                        </div>
                        <div [hidden]="![1,7].includes(userRoleId)" class="col-lg-2 col-md-2 col-12 px-1">
                            <select [(ngModel)]="districtId" class="form-control form-input-text w-100 select-style" formControlName="district_id">
                                <option value="">Select District</option>
                                <option *ngFor="let each_district of district_list"
                                    [ngValue]="each_district.district_id">
                                    {{ each_district.district_name }}
                                </option>
                            </select>
                        </div>
                        <div class="col-lg-2 col-md-2 col-12 px-1">
                            <select class="form-control form-input-text w-100 select-style" formControlName="bmc_registered">
                                <option value="">Select Registered</option>
                                <option value="1">Yes</option>
                                <option value="2">No</option>
                            </select>
                        </div>
                        <div class="col-lg-2 col-md-2 col-12 px-1">
                            <select class="form-control form-input-text w-100 select-style" formControlName="bmc_form_count">
                                <option value="">Select e-BMC form filled</option>
                                <option value="1">Yes</option>
                                <option value="2">No</option>
                            </select>
                        </div>
                        <div class="col-lg-2 col-md-2 col-12 px-1">
                            <select class="form-control form-input-text w-100 select-style" formControlName="pbr_form_count">
                                <option value="">Select e-PBR form filled</option>
                              <option value="1">Yes</option>
                              <option value="2">No</option>
                            </select>
                        </div>
                        <div class="col-lg-2 col-md-2 col-12 text-center verticle-content-end pb-0 pl-1 pr-3">
                            <button type="button" class="btn bg-blue text-white btn-style border-style-10 w-100 filter-btn" (click)="getBmcDetails()">Search</button>
                        </div>
                        <div class="col-12 pt-3 pr-1 pl-0">
                            <div [hidden]="isLoading.bmcDetails" class="mat-elevation-z8 table-responsive table-active table-hover table-div pr-2 pl-1">
                                <table class="bmc-table" mat-table [dataSource]="dataSource" matSort
                                    matSortActive="{{matSortActive}}" matSortDisableClear
                                    matSortDirection="{{matSortDirection}}">

                                    <ng-container [matColumnDef]="col" *ngFor="let col of displayedColumns; index as j">
                                        <ng-container>
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                [style]="columnInformations[j].style" class="">
                                                {{columnInformations[j].label ? columnInformations[j].label : ''}}
                                            </th>
                                            <td mat-cell *matCellDef="let element" class="{{col}} text-capitalize">
                                                <span>{{!element[col] || element[col] == "" ? "NA" : element[col]}}</span>
                                            </td>
                                        </ng-container>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">
                                    </tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                </table>
                            </div>
                            <div class="no-record-div text-center pt-5 pb-5" *ngIf="(total_bmc_data==0)">
                              No Record Found
                            </div>
                            <div *ngIf="isLoading.bmcDetails" class="spinner pt-3 pb-5" style="z-index: 9999; left: 40%; top: 30%">
                              <div class="bounce1"></div>
                              <div class="bounce2"></div>
                              <div class="bounce3"></div>
                            </div>
                            <div class="stats">
                              <mat-paginator [length]="total_bmc_data" [pageSize]="page_size" [pageSizeOptions]="[5,10,20,50,100]" showFirstLastButtons>
                              </mat-paginator>
                            </div>
                        </div>


                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
