import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { RestService } from 'src/app/services/rest.service';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { tap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup } from '@angular/forms';
import {PDFDocumentProxy, PDFProgressData} from "ng2-pdf-viewer";

@Component({
  selector: 'app-uploaded-document-list',
  templateUrl: './uploaded-document-list.component.html',
  styleUrls: ['./uploaded-document-list.component.css']
})
export class UploadedDocumentListComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  total;
  tableHeader: any;
  page_size: any;
  matSortActive: any = 'serial';
  matSortDirection: any = 'asc'
  dataSource: any = [];
  ngFilter: FormGroup;

  serverPath: string = this.RestApi.endpoint.replace(/\/api/img, '')

  displayedColumns: string[] = ['serial', 'file_type', 'original_file_name', 'year','file_path'];

  columnInformations: any = [
    { id: 'serial', label: 'Serial', type: 'text', style: '' },
    { id: 'file_type', label: 'File Type', type: 'text', style: '' },
    { id: 'original_file_name', label: 'File Name', type: 'text', style: '' },
    { id: 'year', label: 'year', type: 'text', style: '' },
    { id: 'file_path', label: 'File', type: 'text', style: '' }
  ];
  modalRef: any;
  fileUrl: any;
  fileType: any;
  constructor(
    private ngxService: NgxUiLoaderService,
    private RestApi: RestService,
    private modalService: BsModalService,
    private fb: FormBuilder,
  ) {
    this.createForm();
  }

  ngOnInit(): void {
    this.getUserUploadedList();
  }

  createForm() {
    this.ngFilter = this.fb.group({
      search: [''],
    },
    );
  }
  async getUserUploadedList() {
    const param = {
      page: (this.paginator.pageIndex ? this.paginator.pageIndex : 0) + 1,
      recordsPerPage: this.paginator.pageSize ? this.paginator.pageSize : 5,
      search: this.ngFilter.value,

    };
    this.ngxService.start();
    const dataWithPagination = await this.runApiCommunication('get-user-applications', param);
    this.total = dataWithPagination.data.total;
    this.page_size = dataWithPagination.data.per_page;
    this.dataSource = new MatTableDataSource(dataWithPagination.data.data);
    this.dataSource.sort = this.sort;

    this.ngxService.stop();
  }
  ngAfterViewInit() {
    this.paginator.page.pipe(tap(() => this.getUserUploadedList())).subscribe();
    this.sort.sortChange.pipe(tap(() => this.getUserUploadedList())).subscribe();

  }

  runApiCommunication(fromPath: string, params: any): Promise<any> {
    const userToken = localStorage.getItem('userToken');
    const dataPromise = new Promise((resolve, reject) => {
      this.RestApi.postRequestCreator(fromPath, userToken, params)
        .subscribe((resp: any) => {
          this.ngxService.stop();
          if (resp && resp.status === 'success' && resp.code === 200) {
            resolve(resp.result);
          } else {
            Swal.fire({
              title: 'Error',
              icon: 'error',
              text: resp.message,
            });
            // reject([]);
          }
        }, error => {
          this.ngxService.stop();
          Swal.fire({
            title: 'Error',
            icon: 'error',
            text: error.message,
            showConfirmButton: false,
            timer: 1500,
          });
          // reject([]);
        });
    });
    dataPromise.catch(function onReject(e) {
      Swal.fire({
        title: 'Error',
        icon: 'error',
        text: e.message,
      });
    });
    return dataPromise;
  }

  downloadFile(fullFilePath) {
    // this.RestApi.downloadFile(fullFilePath);
  }

  showFile(template: TemplateRef<any>, fileUrl, fileType) {

    if (fileType == 'pdf') {
      this.isLoading = true;
      this.isError = true;
    } else {
      this.isLoading = false;
      this.isError = false;
    }
    this.modalRef = this.modalService.show(template, {
      class: 'modal-dialog-centered modal-lg'
    });
  // console.log("full path",this.RestApi.ImageUrl + fileUrl);
      this.fileUrl = fileUrl;
    //console.log("fileUrl",fileUrl);
   //this.fileUrl='http://localhost:8000/files/documents/pdf/080413MOTDEM123_FORM_DETAILS_ED.pdf',
    this.fileType = fileType;
  }

  isLoading: any;
  isError: any;
  onProgress(progressData: PDFProgressData) {
    this.isLoading = true;
    this.isError = false;
  }
  onComplete(pdf: PDFDocumentProxy) {
    this.isLoading = false;
    this.isError = false;
  }
  onError(error: any) {
    this.isLoading = false;
    this.isError = true;
  }
}
