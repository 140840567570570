<div>
    <div class="row w-100 d-flex mx-0 justify-content-center">
        <div class="col-12 px-3 py-3">
            <div class="row bg-white border-style pb-4 mx-1 pt-4">
                <div class="col-12 col-sm-12 mt-2 font-size-18 verticle-content-middle"
                    style="margin-bottom: -8px;text-decoration: none;">
                    <a class=" font-blue mr-1 font-weight-bold" style="text-decoration: none;"
                        [routerLink]="['/admin/report']">Report</a>
                    <span class="font-size-25 mr-1 font-blue font-weight-bold">/</span>{{form_category_name ==
                    "pbr_forms" ? ' e-PBR' : ' e-BMC'}}
                </div>
                <hr class="ml-3 mr-3 blue-border w-100 mt-0">
                <div *ngIf="!forDownloadOnly" class="col-md-3 pr-md-0 pb-3">
                    <div class="w-100 py-3 px-1 blue-border border-style-20 h-100">
                        <div class="font-black w-100 d-flex justify-content-center font-weight-bold">
                            {{form_category_name == "pbr_forms" ? 'Report of e-PBR' : 'e-BMC Forms'}}
                        </div>
                        <div class="pt-3 h-fit-content my-custom-scrollbar scrollbar-thin px-3">
                            <div *ngFor="let data of form_category_Sub_Forms; let iEachSubForm = index"
                                class="w-100 py-2">
                                <button class="font-size-15 btn w-100 button-radius"
                                    [ngClass]="activeIndexSubForm == iEachSubForm ? 'bg-blue text-white' : 'font-blue blue-border'"
                                    (click)="loadFormData(iEachSubForm)">
                                    {{data.form_text}}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="h-fit-content" [ngClass]="forDownloadOnly ? 'col-md-12' : 'col-md-9'">
                    <div class="row blue-border mx-1 border-style-20">
                        <div
                            class="col-12 table-head font-size-16 text-white py-2 d-md-flex d-none border-style-top-left-20">
                            {{'Registration Details ' + (form_category_Sub_Forms[activeIndexSubForm] &&
                            form_category_Sub_Forms[activeIndexSubForm].form_type ? '- ' +
                            form_category_Sub_Forms[activeIndexSubForm].form_type : '')}}
                        </div>

                        <div class="col-12 table-responsive p-0 m-0" style="border-radius: 50px">
                            <table class="table table-bordered p-0 m-0">

                                <tr>
                                    <th class="w-16" colspan="1">Category Name</th>
                                    <td class="w-16" colspan="1">{{ dataSource.area_name}}</td>
                                    <th class="w-16" colspan="1">Sub-Category Name</th>
                                    <td class="w-16" colspan="2">{{ dataSource.area_sub_category_name}}</td>
                                    <td colspan="1"></td>
                                </tr>
                                <tr>
                                    <th class="w-16" colspan="1">District</th>
                                    <td class="w-16" colspan="1">{{ dataSource.district}}</td>
                                    <th class="w-16" colspan="1">Taluka</th>
                                    <td class="w-16" colspan="1">{{ dataSource.taluka}}</td>
                                    <th class="w-16" colspan="1">BMCs Name</th>
                                    <td class="w-16" colspan="1">{{ dataSource.category_detail_name}}</td>
                                </tr>
                                <tr>
                                    <th class="w-16" colspan="1">Co-Ordinate Name</th>
                                    <td class="w-16" colspan="1">{{ dataSource.co_ordinator_name}}</td>
                                    <th class="w-16" colspan="1">Mobile</th>
                                    <td class="w-16" colspan="1">{{ dataSource.co_ordinator_mobile}}</td>
                                    <th class="w-16" colspan="1">Email</th>
                                    <td class="w-16" colspan="1">{{ dataSource.co_ordinator_email}}</td>
                                </tr>
                                <tr>
                                    <th class="w-16" colspan="1">Population</th>
                                    <td class="w-16" colspan="1">{{ dataSource.gross_population}}</td>
                                    <th class="w-16" colspan="1">Area</th>
                                    <td class="w-16" colspan="2">{{ dataSource.area_in_hectare}}</td>
                                    <td colspan="1"></td>
                                </tr>
                            </table>
                        </div>

                    </div>

                    <div *ngIf="!forDownloadOnly" class="blue-border border-style-20 mt-3">
                        <div
                            class="col-12 table-head font-size-16 text-white py-2 d-md-flex d-none border-style-top-left-20">
                            <div class="w-100">
                                <span class="float-left ">
                                    {{form_category_Sub_Forms[activeIndexSubForm]?.form_subtext}}
                                </span>
                                <span class="float-right font-size-16">
                                    <!--                                    {{-->
                                    <!--                                        this.filterData.year ? "YEAR - " + this.filterData.year :-->
                                    <!--                                        dataSourcememberdetails?.filteredData &&-->
                                    <!--                                        dataSourcememberdetails.filteredData.length>0-->
                                    <!--                                    ? "YEAR - " + dataSourcememberdetails.filteredData[0]?.year : ""}}-->

                                    {{ this.filterData.year ? "YEAR - " + this.filterData.year : ""}}
                                </span>
                            </div>
                        </div>
                        <div class="mat-elevation-z8 table-responsive table-active table-hover table-div">
                            <table class="bmc-table" mat-table [dataSource]="dataSourcememberdetails" matSort
                                matSortActive="{{matSortActive}}" matSortDisableClear
                                matSortDirection="{{matSortDirection}}">
                                <ng-container [matColumnDef]="col" *ngFor="let col of displayedColumns; index as j">
                                    <ng-container *ngIf="col=='serial'">
                                        <th mat-header-cell *matHeaderCellDef
                                            [style]="columnInformations[j].style ? columnInformations[j].style : ''">
                                            {{columnInformations[j].label ? columnInformations[j].label : ''}} </th>
                                        <td mat-cell *matCellDef="let element, index as i" class="serial">
                                            {{ i + 1 }}
                                        </td>
                                    </ng-container>
                                    <ng-container *ngIf="col!='serial'">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                                            [style]="columnInformations[j].style">
                                            {{columnInformations[j].label ? columnInformations[j].label : ''}}
                                        </th>
                                        <td mat-cell *matCellDef="let element" class="{{col}} text-capitalize">
                                            <span
                                                *ngIf="columnInformations[j].label && columnInformations[j].type == 'text'">{{element[col] ? element[col] : 'NA'}}</span>

                                            <span
                                                *ngIf="columnInformations[j].label && columnInformations[j].type == 'date'">{{element[col]
                                                |
                                                date}}</span>
                                            <span
                                                *ngIf="columnInformations[j].label && columnInformations[j].type == 'time'">{{element[col]
                                                |
                                                date: 'shortTime'}}</span>
                                            <span
                                                *ngIf="columnInformations[j].label && columnInformations[j].type == 'datetime'">{{element[col]
                                                | date}} {{element[col] | date: 'shortTime'}}</span>

                                        </td>
                                    </ng-container>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" [ngClass]="'w-25'">
                                </tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="'w-25'"></tr>
                            </table>
                        </div>
                        <div class="no-record-div text-center pt-5 pb-5"
                            *ngIf="(!dataSourcememberdetails?.filteredData || dataSourcememberdetails?.filteredData.length==0)">
                            No Record Found
                        </div>
                        <div class="stats"
                            [hidden]="(!dataSourcememberdetails?.filteredData || dataSourcememberdetails?.filteredData.length==0)">
                            <mat-paginator [length]="total_data" [pageSize]="10" [pageSizeOptions]="[5,10,20,50,100]"
                                showFirstLastButtons>
                            </mat-paginator>
                        </div>
                    </div>
                    <div *ngIf="!forDownloadOnly" class="row d-flex justify-content-center py-md-5">
                        <a id="exportcontentexcel" target="_blank"
                            class="btn text-white button-style mt-5 font-size-14 p-2" title="Export Excel."
                            (click)="exportContentReport('excel')">
                            Download Excel
                        </a>
                    </div>

                    <div *ngIf="forDownloadOnly" class="blue-border border-style-20 mt-3">
                        <div
                            class="col-12 table-head font-size-16 text-white py-2 d-md-flex d-none border-style-top-left-20">
                            <div class="w-100">
                                <span class="float-right font-size-16">
                                    {{ this.filterData.year ? "YEAR - " + this.filterData.year : ""}}
                                </span>
                            </div>
                        </div>
                        <div class="mat-elevation-z8 table-responsive table-active table-hover table-div pr-2 pl-1">
                            <table class="e-BMC_e-PBR-table" mat-table [dataSource]="dataSourceUploadedDocs" matSort
                                matSortActive="{{matSortActive}}" matSortDisableClear
                                matSortDirection="{{matSortDirection}}">
                                <ng-container [matColumnDef]="col"
                                    *ngFor="let col of displayedColumnsforfileupload; index as j">
                                    <ng-container *ngIf="col=='serial'">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                                            [style]="columnInformationsforuploads[j].style ? columnInformationsforuploads[j].style : ''">
                                            {{columnInformationsforuploads[j].label ?
                                            columnInformationsforuploads[j].label : ''}}
                                        </th>
                                        <td mat-cell *matCellDef="let element, index as i" class="serial">
                                            {{ paginator.pageIndex * this.paginator.pageSize + i + 1 }}
                                        </td>
                                    </ng-container>
                                    <ng-container *ngIf="!['serial', 'file_path'].includes(col)">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                                            [style]="columnInformationsforuploads[j].style" class="">
                                            {{columnInformationsforuploads[j].label ?
                                            columnInformationsforuploads[j].label : ''}}
                                        </th>
                                        <td mat-cell *matCellDef="let element" class="{{col}} text-capitalize">
                                            <span>{{!element[col] || element[col] == "" ? "NA" :
                                                element[col]}}</span>
                                        </td>
                                    </ng-container>
                                    <ng-container *ngIf="['file_path'].includes(col)">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                                            [style]="columnInformationsforuploads[j].style" class="">
                                            {{columnInformationsforuploads[j].label ?
                                            columnInformationsforuploads[j].label : ''}}
                                        </th>
                                        <td mat-cell *matCellDef="let element" class="{{col}} text-capitalize">
                                            <img [src]="element['file_type'].indexOf('pdf') > -1 ? '/assets/images/pdf.png' : RestApi.apiBaseUrl + element[col]"
                                                width="30"
                                                (click)="showFile(filePreviewModal, element['file_path'], element['file_type'])"
                                                class="cursor-pointer">
                                        </td>
                                    </ng-container>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumnsforfileupload; sticky: true">
                                </tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumnsforfileupload;"></tr>
                            </table>
                        </div>
                        <div class="no-record-div text-center pt-5 pb-5" *ngIf="(total==0)">
                            No Record Found
                        </div>
                        <div class="stats">
                            <mat-paginator [length]="total" [pageSize]="page_size" [pageSizeOptions]="[5,10,20,50,100]"
                                showFirstLastButtons>
                            </mat-paginator>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #filePreviewModal>
  <form>
    <div class="modal-body p-4 h-100">
      <button type="button" class="close modal-cross pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">×</span>
      </button>
      <div>
        <!--                <div class="font-size-20 font-weight-bold pb-3">Document Preview</div>-->
        <!--                <button class="btn btn-primary" (click)="downloadFile(RestApi.apiBaseUrl + fileUrl)">-->
        <!--                    <i class="fa fa-download"></i>-->
        <!--                </button>-->
        <div class="theme mt-1 py-1 text-center">
          <img *ngIf="fileType == 'image'" [src]="fileUrl" style="max-width: 750px;" class="w-100" (error)="onError($event)">
          <pdf-viewer *ngIf="fileType == 'pdf'" [src]="fileUrl" [render-text]="true" (on-progress)="onProgress($event)" (after-load-complete)="onComplete($event)" (error)="onError($event)"
                      style="display: block;"></pdf-viewer>
          <div *ngIf="isError == true" class="text-center pt-3 pb-3" style="z-index: 9999; left: 40%; top: 30%">
            Not Found
          </div>
        </div>
      </div>
      <div *ngIf="isLoading == true" class="spinner pb-5" style="z-index: 9999; left: 40%; top: 30%">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
      </div>
    </div>
  </form>
</ng-template>
