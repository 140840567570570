import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainWebAppComponent } from './main-web-app.component';
import { WelcomeScreenComponent } from './welcome-screen/welcome-screen.component';
import { CategoriesFormsComponent } from './categories-forms/categories-forms.component';
import { FormComponent } from './form/form.component';
import { ListComponent } from './list/list.component';
import { DocumentUploadComponent } from './document-upload/document-upload.component';
import { UploadedDocumentListComponent } from './uploaded-document-list/uploaded-document-list.component';
import {ReportComponent} from './report/report.component';
import {CategoryWiseReportComponent} from './category-wise-report/category-wise-report.component';

const routes: Routes = [
  {
    path: '',
    component: MainWebAppComponent,

    children: [
      {
        path: '',
        component: WelcomeScreenComponent,
        data: {
          title: 'Welcome',
          breadcrumb: [
            {
              label: 'Home',
              url: ''
            }
          ]
        }
      },
      {
        path: 'report',
        component: ReportComponent,
        data: {
          title: 'Report',
          breadcrumb: [
            {
              label: 'Home',
              url: '/user'
            },
            {
              label: 'Report',
              url: ''
            }
          ]
        }
      },
      {
        path: 'report/:form_category_name/:category_id/:user_id/:sessionkey',
        component: CategoryWiseReportComponent,
        data: {
          title: 'Report',
          breadcrumb: [
            {
              label: 'Home',
              url: '/user'
            },
            {
              label: 'Report',
              url: '/user/report'
            },
            {
              label: 'Forms',
              url: ''
            }
          ]
        }
      },
      {
        path: 'report/download/:form_category_name/:category_id/:user_id/:sessionkey',
        component: CategoryWiseReportComponent,
        data: {
          title: 'Report',
          breadcrumb: [
            {
              label: 'Home',
              url: '/user'
            },
            {
              label: 'Report',
              url: '/user/report'
            },
            {
              label: 'e-PBR - PDF',
              url: ''
            }
          ]
        }
      },
      {
        path: 'welcome',
        component: WelcomeScreenComponent,
        data: {
          title: 'Welcome',
          breadcrumb: [
            {
              label: 'Home',
              url: ''
            }
          ]
        }
      },
      {
        path: 'categories-form/:categoryformName/:catergoryformid',
        component: CategoriesFormsComponent,
        data: {
          title: 'categories',
          breadcrumb: [
            {
              label: 'Home',
              url: '/user'
            },
            {
              label: '{{categoryformName}}',
              url: ''
            }
          ]
        }
      },
      {
        path: 'categories-form/:catergoryformName/:catergoryformid/form/:formname/:id',
        component: FormComponent,
        data: {
          title: 'form',
          breadcrumb: [
            {
              label: 'Home',
              url: '/user'
            },
            {
              label: '{{catergoryformName}}',
              url: '/user/categories-form/:catergoryformName/:catergoryformid'
            },
            {
              label: '{{formname}}',
              url: ''
            }
          ]
        }
      },
      {
        path: 'categories-form/:catergoryformName/:catergoryformid/form/:formname/:id/list',
        component: ListComponent,
        data: {
          title: 'list',
          breadcrumb: [
            {
              label: 'Home',
              url: '/user'
            },
            {
              label: '{{catergoryformName}}',
              url: '/user/categories-form/:catergoryformName/:catergoryformid'
            },
            {
              label: '{{formname}}',
              url: '/user/categories-form/:catergoryformName/:catergoryformid/form/:formname/:id'
            },
            {
              label: 'List View',
              url: ''
            }
          ]
        }
      },
      {
        path: 'categories-form/:catergoryformName/:catergoryformid/form/:formname/:id/list/edit/:appid',
        component: FormComponent,
        data: {
          title: 'edit list',
          breadcrumb: [
            {
              label: 'Home',
              url: '/user'
            },
            {
              label: '{{catergoryformName}}',
              url: '/user/categories-form/:catergoryformName/:catergoryformid'
            },
            {
              label: '{{formname}}',
              url: '/user/categories-form/:catergoryformName/:catergoryformid/form/:formname/:id'
            },
            {
              label: 'List View',
              url: '/user/categories-form/:catergoryformName/:catergoryformid/form/:formname/:id/list'
            },
            {
              label: 'Edit',
              url: ''
            }
          ]
        }
      },
      {
        path: 'upload-epbr-document/:categoryformName/:catergoryformid',
        component: DocumentUploadComponent,
        data: {
          title: 'document-upload',
          breadcrumb: [
            {
              label: 'Home',
              url: '/user'
            },
            {
              label: '{{categoryformName}}',
              url: '/user/categories-form/:categoryformName/:catergoryformid'
            },
            {
              label: 'Upload e-PBR - PDF',
              url: ''
            }
          ]
        }
      },
      {
        path: 'document-list/:categoryformName/:catergoryformid',
        component: UploadedDocumentListComponent,
        data: {
          title: 'Uploaded-document-list',
          breadcrumb: [
            {
              label: 'Home',
              url: '/user'
            },
            {
              label: '{{categoryformName}}',
              url: '/user/categories-form/:categoryformName/:catergoryformid'
            },
            {
              label: 'Upload e-PBR - PDF',
              url: '/user/upload-epbr-document/:categoryformName/:catergoryformid'
            },
            {
              label: 'List View',
              url: ''
            }
          ]
        }
      },
    ]
  }

];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MainWebAppRoutingModule {
  static components = [
    WelcomeScreenComponent,
    CategoriesFormsComponent,
    FormComponent
  ];
}
