import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { RestService } from 'src/app/services/rest.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})
export class AddUserComponent implements OnInit {
  userManagmentForm: FormGroup;
  preDefinedUserRoles = {
    mantralya: 7,
    revenuDivision: 6,
    district: 3,
    zilaParishad: 4
  }
  submitted: boolean;
  error: any;
  zilaParishadList = [];
  districtList = [];
  departmentList = [];
  revenueDivisionList = [];
  selectedAdminType;

  constructor(
    private fb: FormBuilder,
    private RestApi: RestService,
    private ngxService: NgxUiLoaderService,
    private router: Router,) { }

  ngOnInit(): void {
    this.userManagmentForm = new FormGroup({
      firstname: new FormControl('', Validators.required),
      lastname: new FormControl('', Validators.required),
      mobile: new FormControl('', [
        Validators.required,
        Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')]),
      email: new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-zA-Z]{2,4}$/i)]),
      selectedRole: new FormControl(7, Validators.required),
      selectedDepartment: new FormControl(null),
      revenuedivision: new FormControl(null),
      district: new FormControl(null),
      zilaParishad: new FormControl(null),
    });
    this.getDepartment();

    this.userManagmentFormControls.revenuedivision.disable();
    this.userManagmentFormControls.district.disable();
    this.userManagmentFormControls.zilaParishad.disable();

    this.userManagmentFormControls.revenuedivision.valueChanges.subscribe(newValue => {
      if (this.selectedAdminType == "revenueDivisionLevel")
        return;

      if (newValue && newValue.toString() != '0' && this.districtList.length < 1) {
        this.getDistrictList(newValue);
      }
    });

    this.userManagmentFormControls.district.valueChanges.subscribe(newValue => {
      if (this.selectedAdminType == "districtLevel")
        return;

      if (newValue && newValue.toString() != '0' && this.zilaParishadList.length < 1) {
        this.getZilaParishadList(newValue);
      }
    });
  }

  onAdminTypeChanged(radioButtonChangeEvent) {

    this.userManagmentFormControls.revenuedivision.disable();
    this.userManagmentFormControls.district.disable();
    this.userManagmentFormControls.zilaParishad.disable();

    this.selectedAdminType = radioButtonChangeEvent.target.id;
    if (radioButtonChangeEvent.target.id == "mantralayaLevel") {
      this.revenueDivisionList = [];
      this.districtList = [];
      this.zilaParishadList = [];
      this.userManagmentFormControls.revenuedivision.reset();
      this.userManagmentFormControls.district.reset();
      this.userManagmentFormControls.zilaParishad.reset();
    }

    if (radioButtonChangeEvent.target.id == "revenueDivisionLevel"
      || radioButtonChangeEvent.target.id == "districtLevel"
      || radioButtonChangeEvent.target.id == "zilaParishadLevel") {

      this.userManagmentFormControls.revenuedivision.enable();
      this.getDivisionList();
      if (radioButtonChangeEvent.target.id == "revenueDivisionLevel") {
        this.userManagmentFormControls.district.reset();
        this.userManagmentFormControls.zilaParishad.reset();
        this.zilaParishadList = [];
      }
    }

    if (radioButtonChangeEvent.target.id == "districtLevel"
      || radioButtonChangeEvent.target.id == "zilaParishadLevel") {

      if (radioButtonChangeEvent.target.id == "districtLevel") {
        this.userManagmentFormControls.zilaParishad.reset();
        this.zilaParishadList = [];
      }

      if (this.userManagmentFormControls.revenuedivision.value
        && this.userManagmentFormControls.revenuedivision.value.toString() != '0') {
        if (this.userManagmentFormControls.district.disabled)
          this.userManagmentFormControls.district.enable();
        this.getDistrictList(this.userManagmentFormControls.revenuedivision.value);
      }
    }

    if (radioButtonChangeEvent.target.id == "zilaParishadLevel"
      && this.userManagmentFormControls.district.value
      && this.userManagmentFormControls.district.value.toString() != '0') {
      if (this.userManagmentFormControls.zilaParishad.disabled)
        this.userManagmentFormControls.zilaParishad.enable();
      this.getZilaParishadList(this.userManagmentFormControls.district.value);
    }
  }

  get userManagmentFormControls() {
    return this.userManagmentForm.controls;
  }

  getSelectTextFormControl(formControl: AbstractControl, listData: any[], valueFieldName: string
    , textFieldName: string, defaultTextWhenNoneSelected: string) {
    const formControlValue = formControl.value;
    if (formControlValue == "0" || !formControlValue)
      return defaultTextWhenNoneSelected;

    let displayText = "";
    for (let listDataIndex = 0; listDataIndex < listData.length; listDataIndex++) {
      const eachDataInList = listData[listDataIndex];
      if (eachDataInList[valueFieldName] == formControlValue)
        displayText = eachDataInList[textFieldName];
    }
    return displayText;
  }

  setSelectValue(formControl: AbstractControl, value: any) {
    formControl.patchValue(value);
  }

  runApiCommunication(fromPath: string, params: any): Promise<any> {
    const userToken = localStorage.getItem('userToken');
    const dataPromise = new Promise((resolve, reject) => {
      this.RestApi.postRequestCreator(fromPath, userToken, params)
        .subscribe((resp: any) => {
          this.ngxService.stop();
          if (resp && resp.status === 'success' && resp.code === 200) {
            resolve(resp.result);
          } else if (resp && resp.status === 'error' && resp.code === 400) {
            const result = resp.result;
            let message = '';
            if (result) {
              // tslint:disable-next-line:forin
              for (const key in result) {
                // tslint:disable-next-line:no-shadowed-variable
                message = message + `<br>` + `${result[key]}`;
              }
            }
            Swal.fire({
              title: 'Error',
              icon: 'error',
              html: message,
            });
          } else {
            Swal.fire({
              title: 'Error',
              icon: 'error',
              text: resp.message,
            });
            // reject([]);
          }
        }, error => {
          this.ngxService.stop();
          Swal.fire({
            title: 'Error',
            icon: 'error',
            text: error.message,
            showConfirmButton: false,
            timer: 1500,
          });
          // reject([]);
        });
    });
    dataPromise.catch(function onReject(e) {
      Swal.fire({
        title: 'Error',
        icon: 'error',
        text: e.message,
      });
    });
    return dataPromise;
  }

  async getDepartment() {
    this.departmentList = await this.runApiCommunication('get-department-list', null);
    if (!this.departmentList || this.departmentList.length < 1)
      return;
    this.userManagmentFormControls.selectedDepartment.patchValue(this.departmentList[0].id);
  }
  async getDivisionList() {
    this.revenueDivisionList = await this.runApiCommunication('get-division-list', null);
  }
  async getDistrictList(selectedRev_div_id) {
    const param = {
      // rev_div_id_not_null: true,
      state_id: 1,
      rev_div_id: selectedRev_div_id
    };
    this.districtList = await this.runApiCommunication('get-districts-list', param);
    this.userManagmentFormControls.district.enable();
  }
  async getZilaParishadList(selectedDistrict_id) {
    const param = {
      district_id: selectedDistrict_id
    };
    this.zilaParishadList = await this.runApiCommunication('get-talukas-list', param);
    this.userManagmentFormControls.zilaParishad.enable();
  }

  async addUserForm() {
    this.submitted = true;
    this.error = null;
    if (this.userManagmentForm.invalid) {
      return;
    }
    const ApiData = {
      first_name: this.userManagmentFormControls.firstname.value,
      last_name: this.userManagmentFormControls.lastname.value.toString(),
      contact: this.userManagmentFormControls.mobile.value.toString(),
      email: this.userManagmentFormControls.email.value,
      user_role_id: this.userManagmentFormControls.selectedRole.value,
      rev_div_id: this.userManagmentFormControls.revenuedivision.value,
      district_id: this.userManagmentFormControls.district.value,
      taluka_id: this.userManagmentFormControls.zilaParishad.value,
      department_id: this.userManagmentFormControls.selectedDepartment.value,
    };
    const APIResult = await this.runApiCommunication('add-user', ApiData).catch(e => {
      Swal.fire({
        title: 'An error occured when adding User.',
        icon: 'error',
      });
    });
    if (APIResult) {
      Swal.fire({
        title: 'User Added successfully',
        icon: 'success',
      }).then((result) => {
        this.router.navigate(['/admin/user-management']);
      })
    }
  }
}
