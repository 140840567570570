import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BmcRegistrationComponent } from './access-to-application/bmc-registration/bmc-registration.component';
import { LoginComponent } from './access-to-application/login/login.component';

import {UserAuthGuard} from './auth-service/user-auth/user-auth.guard';
import {AdminAuthGuard} from './auth-service/admin-auth/admin-auth.guard';
import { SigninComponent } from './admin-section/signin/signin.component';
import {ForgetPasswordComponent} from './access-to-application/forget-password/forget-password.component';
import {ResetPasswordComponent} from './access-to-application/reset-password/reset-password.component';
import {ForgotPasswordComponent} from './admin-section/forgot-password/forgot-password.component';

const routes: Routes = [
  {
    path: 'register',
    component: BmcRegistrationComponent
  },
  {
    path: 'forget-password',
    component: ForgetPasswordComponent
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent
  },
  {
    path: 'admin/login',
    component: SigninComponent
  },
  {
    path: 'admin/forget-password',
    component: ForgetPasswordComponent
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },

  {
    path: 'user',
    loadChildren: () => import('./main-web-app/main-web-app-module.module')
      .then(m => m.MainWebAppModuleModule),
    canActivate: [UserAuthGuard]
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin-section/admin-main-app/admin-main-app.module')
      .then(m => m.AdminMainAppModule),
    canActivate: [AdminAuthGuard]
  },
  {
    path: '**',
    component: LoginComponent,
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
