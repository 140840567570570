import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { RestService } from 'src/app/services/rest.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.css']
})
export class AdminHeaderComponent implements OnInit {

  loggedInUserName: string;
  userToken: string;
  userRole: number;
  userEmail: string;
  baseUrl: string;
  data: any;
  constructor(private ngxService: NgxUiLoaderService,
    public service: RestService, public route: Router) { }

  ngOnInit(): void {
    const ls = localStorage.getItem('authData');
    this.userToken = localStorage.getItem('userToken');
    this.userEmail = localStorage.getItem('userEmail');
    const data = JSON.parse(ls);
    if (data && data.length && data[0] && data[0].user) {
      this.loggedInUserName = data[0].user.user_role_name ? data[0].user.user_role_name.replace(/^\p{CWU}/u, char => char.toLocaleUpperCase('en')) : '';
      this.userRole = data[0].user.user_role_id ? parseInt(data[0].user.user_role_id, 10) : 0;

      // if ([3, 4, 6].includes(this.userRole)
      //   && (this.route.url.indexOf("user-management") > -1 || this.route.url.indexOf("add-user") > -1)) {
      //   this.route.navigate(['/admin/dashboard']);
      // }
    }
  }

  logout() {

    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to logout?',
      showCancelButton: true,
      confirmButtonColor: '#163862',
      confirmButtonText: 'Confirm',
      cancelButtonColor: '#d33'
    }).then((result) => {
      if (result.value) {

        this.ngxService.start();
        this.service.postRequestCreator('logout', this.userToken).subscribe(response => {
          this.ngxService.stop();
          this.data = response;
          if (this.data && this.data.message) {
            Swal.fire({
              html: '<b>You are logged out successfully!</b>',
              showConfirmButton: false,
            });
            localStorage.removeItem('authData');
            localStorage.removeItem('userName');
            localStorage.removeItem('userEmail');
            localStorage.removeItem('userToken');
            window.location.href = 'admin/login';
          } else {
            Swal.fire({
              html: '<b>Something went wrong! Try Again</b>',
              showConfirmButton: true,
            });
          }
        }, error => {
          this.ngxService.stop();
        });

      }
    });
  }
}
