import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { RestService } from 'src/app/services/rest.service';
import { BreadCrumbModel } from '../Model/BreadCrumb.Model';


@Component({
  selector: 'app-categories-forms',
  templateUrl: './categories-forms.component.html',
  styleUrls: ['./categories-forms.component.css']
})
export class CategoriesFormsComponent implements OnInit {
  @Input() BreadCrumbData: BreadCrumbModel[] = [];
  form_data;
  form_type;
  routes;
  categoriesFormList;
  id;
  categoriesName;
  userToken;
  agrobiodiversityData;
  domesticated_bioData;
  urban_bioData;
  web_bioData;


  constructor(
    private RestApi: RestService,
    private router: Router,
    private route: ActivatedRoute,
    private ngxService: NgxUiLoaderService,

  ) {
    this.id = route.snapshot.params.catergoryformid;
  }


  ngOnInit(): void {
    this.getdata(this.id);
  }

  initProcess() {
    const ls = localStorage.getItem('authData');
    this.userToken = localStorage.getItem('userToken');
    const data = JSON.parse(ls);
    if (data && data[0].token) {
      this.router.navigate(['/user']);
    }
  }
  formListGroupedBy_form_type = new Map(); // created a new map to prevent automatic sorting in object {}

  getdata(id) {
    this.userToken = localStorage.getItem('userToken');

    // console.log("url_id", this.id);
    this.routes = "get-category-forms-list";
    const param = {
      category_id: this.id,
      summary: true
    };
    this.ngxService.start();
    this.RestApi.postRequestCreator("get-category-forms-list", this.userToken, param).subscribe((data: any) => {
      this.ngxService.stop();
      this.categoriesFormList = data.result;
      this.categoriesName = data.result[0]["form_title"];

      for (let eachCategoryForms of this.categoriesFormList) {
        let form_type_name = eachCategoryForms["form_type"];
        if (!form_type_name || form_type_name === undefined || form_type_name == null)
          form_type_name = "EMPTY"; // default

        if (this.formListGroupedBy_form_type.get(form_type_name) === undefined)
          this.formListGroupedBy_form_type.set(form_type_name, []);

        this.formListGroupedBy_form_type.get(form_type_name).push(eachCategoryForms);
      }
    })
  }
}
