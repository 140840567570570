<div>
    <div class="row w-100 d-flex mx-0 justify-content-center">
        <div class="col-12 px-3 py-3">
            <form class="w-100" [formGroup]="ngFilter">
                <div class="row bg-white border-style pb-4 mx-1">
                    <div class="row w-100 pl-3">
                        <div class="col-md-10 col-sm-12 mt-2 font-size-18 font-weight-bold verticle-content-middle">
                            User List
                        </div>
                        <div class="col-md-2 col-sm-12 mt-2 d-flex justify-content-lg-end pl-3 pr-0">
                            <a class="font-size-16" role="button" [routerLink]="['/admin/add-user']" title="Add User">
                                <img src="assets/images/plus.svg" style="width: 49%;" /></a>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="row px-2">
                            <div class="col-lg-2 col-md-2 col-12 px-1">
                                <select class="form-control form-input-text w-100 select-style"
                                    formControlName="area_id">
                                    <option value="">Select Category</option>
                                    <option *ngFor="let each_area_category of area_category_list"
                                        [ngValue]="each_area_category.acat_id">
                                        {{ each_area_category.acat_name }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-lg-2 col-md-2 col-12 px-1">
                                <select class="form-control form-input-text w-100 select-style"
                                    formControlName="area_sub_category_id">
                                    <option value="">Select Sub Category</option>
                                    <option *ngFor="let each_sub_area_category of sub_area_category_list"
                                        [ngValue]="each_sub_area_category.ascat_id">
                                        {{ each_sub_area_category.ascat_name }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-lg-2 col-md-2 col-12 px-1">
                                <select class="form-control form-input-text w-100 select-style"
                                    formControlName="district_id">
                                    <option value="">Select District</option>
                                    <option *ngFor="let each_district of district_list"
                                        [ngValue]="each_district.district_id">
                                        {{ each_district.district_name }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-lg-2 col-md-2 col-12 px-1">
                                <select class="form-control form-input-text w-100 select-style"
                                    formControlName="taluka_id">
                                    <option value="">Select Taluka</option>
                                    <option *ngFor="let each_taluka of taluka_list" [ngValue]="each_taluka.taluka_id">
                                        {{ each_taluka.taluka_name }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-lg-2 col-md-2 col-12 px-1">
                                <select class="form-control form-input-text w-100 select-style"
                                    formControlName="category_detail_id">
                                    <option value="">Select BMC Name</option>
                                    <option *ngFor="let each_bmc of bmc_list" [ngValue]="each_bmc.bmc_id">
                                        {{ each_bmc.bmc_name }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-lg-2 col-md-2 col-12 text-center verticle-content-end pb-0 pl-1 pr-3">
                                <button type="button"
                                    class="btn bg-blue text-white btn-style border-style-10 w-100 filter-btn"
                                    (click)="getUsers()">Search</button>
                            </div>
                            <div class="col-12 pt-3 pr-1 pl-0">
                                <div
                                    class="mat-elevation-z8 table-responsive table-active table-hover table-div pr-2 pl-1">
                                    <table class="e-BMC_e-PBR-table" mat-table [dataSource]="dataSource" matSort
                                        matSortActive="{{matSortActive}}" matSortDisableClear
                                        matSortDirection="{{matSortDirection}}">
                                        <ng-container [matColumnDef]="col"
                                            *ngFor="let col of displayedColumns; index as j">
                                            <ng-container *ngIf="col=='#'">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                    [style]="columnInformations[j].style ? columnInformations[j].style : ''">
                                                    {{columnInformations[j].label ? columnInformations[j].label : ''}}
                                                </th>
                                                <td mat-cell *matCellDef="let element, index as i" class="serial">
                                                    {{ paginator.pageIndex * this.paginator.pageSize + i + 1 }}
                                                </td>
                                            </ng-container>
                                            <ng-container
                                                *ngIf="!['#', 'co_ordinator_name', 'co_ordinator_mobile'].includes(col)">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                    [style]="columnInformations[j].style" class="">
                                                    {{columnInformations[j].label ? columnInformations[j].label : ''}}
                                                </th>
                                                <td mat-cell *matCellDef="let element" class="{{col}} text-capitalize">
                                                    <span>{{!element[col] || element[col] == "" ? "NA" :
                                                        element[col]}}</span>
                                                </td>
                                            </ng-container>
                                            <ng-container *ngIf="['co_ordinator_name'].includes(col)">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                    [style]="columnInformations[j].style" class="">
                                                    {{columnInformations[j].label ? columnInformations[j].label : ''}}
                                                </th>
                                                <td mat-cell *matCellDef="let element" class="{{col}} text-capitalize">
                                                    <span *ngIf="[1, 3, 4, 6].includes(element['user_role_id'])">
                                                        {{!element['first_name'] || !element['last_name'] ? 'NA' :
                                                        element['first_name'] + ' ' + element['last_name'] }}
                                                    </span>
                                                    <span *ngIf="![1, 3, 4, 6].includes(element['user_role_id'])">
                                                        {{!element[col] || element[col] == "" ? "NA" : element[col]}}
                                                    </span>
                                                </td>
                                            </ng-container>
                                            <ng-container *ngIf="['co_ordinator_mobile'].includes(col)">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                    [style]="columnInformations[j].style" class="">
                                                    {{columnInformations[j].label ? columnInformations[j].label : ''}}
                                                </th>
                                                <td mat-cell *matCellDef="let element" class="{{col}} text-capitalize">
                                                    <span *ngIf="[1, 3, 4, 6].includes(element['user_role_id'])">
                                                        {{!element['contact'] ? 'NA' : element['contact'] }}
                                                    </span>
                                                    <span *ngIf="![1, 3, 4, 6].includes(element['user_role_id'])">
                                                        {{!element[col] || element[col] == "" ? "NA" : element[col]}}
                                                    </span>
                                                </td>
                                            </ng-container>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">
                                        </tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                    </table>
                                </div>
                                <div class="no-record-div text-center pt-5 pb-5" *ngIf="(total_bmc_pbr_data==0)">
                                    No Record Found
                                </div>
                                <div class="stats">
                                    <mat-paginator [length]="total_bmc_pbr_data" [pageSize]="page_size"
                                        [pageSizeOptions]="[5,10,20,50,100]" showFirstLastButtons>
                                    </mat-paginator>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>