import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators';
import { errorValidate } from '../_helpers/utility';

@Injectable({
  providedIn: 'root'
})
export class RestService {
  endpoint: string = environment.apiUrl;
  baseUrl: string = environment.baseUrl;
  apiBaseUrl: string = environment.apiBaseUrl;
  ImageUrl: string = environment.imageUrl;
  constructor(private http: HttpClient, private router: Router) { }

  getRequestCreator<T>(FromPath: string, token: string = null, DataRow: any,): Observable<any> {
    const header = new HttpHeaders();
    const otherHeader = header.append('Authorization', 'Bearer ' + token);
    return this.http.get<T>(this.endpoint + FromPath + '/' + DataRow, { headers: otherHeader }).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError)
    );
  }

  postRequestCreator<T>(FromPath: string, token: string = null, DataRow: any = {},): Observable<any> {
    const header = new HttpHeaders();
    const otherHeader = header.append('Authorization', 'Bearer ' + token);
    return this.http.post<T>(this.endpoint + FromPath, DataRow, { headers: otherHeader }).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError)
    );
  }

  getDataFromAPI<T>(FromPath: string, data): Observable<any> {
    return this.http.get<T>(this.endpoint + FromPath).pipe(
      map((res: any) => {
        return res;
      })
      ,
      catchError(this.handleError)
    );
  }

  postDataToAPI<T>(DataRow: any, FromPath: string): Observable<any> {
    return this.http.post<T>(this.endpoint + FromPath, DataRow).pipe(
      map((res: any) => {
        return res;
      })
      ,
      catchError(this.handleError)
    );
  }
  downloadFile(fullFilePath, userToken): any {
    const header = new HttpHeaders();
    const otherHeader = header.append('Authorization', 'Bearer ' + userToken);
    return this.http.get(fullFilePath, {responseType: 'blob' as 'json', headers: otherHeader});
  }
  handleError(error: HttpErrorResponse): any {
    errorValidate(error);
  }
}


