import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormControlName, FormGroup } from '@angular/forms';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { tap } from 'rxjs/operators';
import { RestService } from 'src/app/services/rest.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css']
})
export class UserManagementComponent implements OnInit {

  ngFilter: FormGroup;
  tableHeader: any;
  total_bmc_pbr_data;
  page_size: any;
  district_list = [];
  sub_area_category_list = [];
  area_category_list = [];
  taluka_list = [];
  bmc_list = [];
  yearOptions = [];
  matSortActive: any = 'email';
  matSortDirection: any = 'asc'

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private fb: FormBuilder,
    private RestApi: RestService,
    private ngxService: NgxUiLoaderService,
    private route: Router
  ) {

  }

  displayedColumns: string[] = ['#', 'email', 'user_role_name', 'area_name', 'area_sub_category_name', 'state', 'district', 'taluka', 'category_detail_name', 'co_ordinator_name',
    'co_ordinator_mobile', 'co_ordinator_email', 'gross_population', 'area_in_hectare'];

  columnInformations: any = [
    { id: '#', label: '#', type: 'text', style: '' },
    { id: 'email', label: 'User Email', type: 'text', style: '' },
    { id: 'user_role_name', label: 'User Role', type: 'text', style: '' },
    { id: 'area_name', label: 'Area', type: 'text', style: '' },
    { id: 'area_sub_category_name', label: 'Area-Sub-Category', type: 'text', style: '' },
    { id: 'state', label: 'State', type: 'text', style: '' },
    { id: 'district', label: 'District', type: 'text', style: '' },
    { id: 'taluka', label: 'Taluka', type: 'text', style: '' },
    { id: 'category_detail_name', label: 'BMC Name', type: 'text', style: '' },
    { id: 'co_ordinator_name', label: 'Co-Ordinator Name', type: 'text', style: '' },
    { id: 'co_ordinator_mobile', label: 'Mobile', type: 'text', style: '' },
    { id: 'co_ordinator_email', label: 'Co-Ordinator Email', type: 'text', style: '' },
    { id: 'gross_population', label: 'Gross Population', type: 'text', style: '' },
    { id: 'area_in_hectare', label: 'Area-In-Hectare', type: 'text', style: '' },
  ];
  dataSource: any = [];
  ngOnInit(): void {
    this.ngFilter = this.fb.group(
      {
        area_id: new FormControl(''),
        area_sub_category_id: new FormControl(''),
        district_id: new FormControl(''),
        taluka_id: new FormControl(''),
        category_detail_id: new FormControl(''),
      }
    );

    this.getAreaCategoryList();
    this.ngxService.start();
    this.ngFilterFormControl.area_id.valueChanges.subscribe(newValue => {
      this.getSubCategoryList(newValue);
    });
    this.ngFilterFormControl.area_sub_category_id.valueChanges.subscribe(newValue => {
      this.getDistrictList(newValue);
    });
    this.ngFilterFormControl.district_id.valueChanges.subscribe(newValue => {
      this.getTalukaList(newValue);
    });
    this.ngFilterFormControl.taluka_id.valueChanges.subscribe(newValue => {
      this.getBmcNameList(newValue);
    });

    this.getUsers();
  }

  get ngFilterFormControl() {
    return this.ngFilter.controls;
  }

  runApiCommunication(fromPath: string, params: any): Promise<any> {
    const userToken = localStorage.getItem('userToken');
    const dataPromise = new Promise((resolve, reject) => {
      this.RestApi.postRequestCreator(fromPath, userToken, params)
        .subscribe((resp: any) => {
          this.ngxService.stop();
          if (resp && resp.status === 'success' && resp.code === 200) {
            resolve(resp.result);
          } else {
            Swal.fire({
              title: 'Error',
              icon: 'error',
              text: resp.message,
            });
            // reject([]);
          }
        }, error => {
          this.ngxService.stop();
          Swal.fire({
            title: 'Error',
            icon: 'error',
            text: error.message,
            showConfirmButton: false,
            timer: 1500,
          });
          // reject([]);
        });
    });
    dataPromise.catch(function onReject(e) {
      Swal.fire({
        title: 'Error',
        icon: 'error',
        text: e.message,
      });
    });
    return dataPromise;
  }
  ngAfterViewInit() {
    this.paginator.page.pipe(tap(() => this.getUsers())).subscribe();
    this.sort.sortChange.pipe(tap(() => this.getUsers())).subscribe();
  }

  async getUsers() {
    const param = {
      page: (this.paginator.pageIndex ? this.paginator.pageIndex : 0) + 1,
      sort: this.sort?.active,
      order: this.sort?.direction,
      search: this.ngFilter.value,
      recordsPerPage: this.paginator.pageSize ? this.paginator.pageSize : 10
    };
    this.ngxService.start();
    const bmcPbrDataWithPagination = await this.runApiCommunication('get-users', param);
    this.total_bmc_pbr_data = bmcPbrDataWithPagination.data.total;
    this.page_size = bmcPbrDataWithPagination.data.per_page;
    this.dataSource = new MatTableDataSource(bmcPbrDataWithPagination.data.data);
    this.dataSource.sort = this.sort;

    this.ngxService.stop();
  }
  async getAreaCategoryList() {
    this.ngxService.start();
    this.ngxService.start();
    this.area_category_list = await this.runApiCommunication('get-areas-list', null);
    this.ngxService.stop();
  }

  async getSubCategoryList(categoryId: any) {
    this.ngxService.start();
    if (categoryId !== undefined && categoryId.toString() == "0") {
      this.sub_area_category_list = [];
      return;
    }
    this.sub_area_category_list = await this.runApiCommunication('get-area-sub-categories-list', { area_id: categoryId });
    this.ngxService.stop();
  }

  async getDistrictList(subCategoryId) {
    this.ngxService.start();
    if (subCategoryId !== undefined && subCategoryId.toString() == "0") {
      this.district_list = [];
      return;
    }
    this.district_list = await this.runApiCommunication('get-districts-list', { sub_area_id: subCategoryId });
    this.ngxService.stop();
  }

  async getTalukaList(districtId) {
    this.ngxService.start();
    if (districtId !== undefined && districtId.toString() == "0") {
      this.taluka_list = [];
      return;
    }
    this.taluka_list = await this.runApiCommunication('get-talukas-list', { district_id: districtId });
    this.ngxService.stop();
  }

  async getBmcNameList(talukaId) {
    this.ngxService.start();
    if (talukaId !== undefined && talukaId.toString() == "0") {
      this.bmc_list = [];
      return;
    }
    this.bmc_list = await this.runApiCommunication('get-category-details-list', {
      fetch_all: true,
      taluka_id: talukaId,
      area_id: this.ngFilterFormControl.area_id.value,
      district_id: this.ngFilterFormControl.district_id.value,
      area_sub_category_id: this.ngFilterFormControl.area_sub_category_id.value,
    });
    this.ngxService.stop();
  }



}

