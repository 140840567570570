import { Component, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RestService } from 'src/app/services/rest.service';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MatPaginator } from '@angular/material';
import { tap } from 'rxjs/operators';
import { MatSort } from '@angular/material/sort';
import { ReportComponent } from '../report/report.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup } from '@angular/forms';
import {PDFDocumentProxy, PDFProgressData} from "ng2-pdf-viewer";

@Component({
  selector: 'app-category-wise-report',
  templateUrl: './category-wise-report.component.html',
  styleUrls: ['./category-wise-report.component.css']
})
export class CategoryWiseReportComponent implements OnInit {

  @ViewChildren(MatPaginator) allPaginator: QueryList<MatPaginator>;
  @ViewChildren(MatSort) allSort: QueryList<MatSort>;

  paginator: MatPaginator;
  sort: MatSort;

  category_id;
  categoryFormId;
  form_category_name;
  user_id;
  form_category_Sub_Forms: any[] = [];
  userToken: string;
  activeIndexSubForm = 0;
  total_report_category;
  total_data = 0;
  from = 0;
  id: any = '';
  formData: any;
  yearOptions = [];
  displayedColumns: string[] = [];
  columnInformations: any = [];
  dataSource: any = [];
  modalRef: any;
  fileUrl: any;
  fileType: any;

  displayedColumnsforfileupload: string[] = ['serial', 'file_type', 'original_file_name','year', 'co_ordinator_name', 'file_path'];

  columnInformationsforuploads: any = [
    { id: 'serial', label: 'Serial', type: 'text', style: '' },
    { id: 'file_type', label: 'File Type', type: 'text', style: '' },
    { id: 'original_file_name', label: 'File Name', type: 'text', style: '' },
    { id: 'year', label: 'Year', type: 'text', style: '' },
    { ud: 'co_ordinator_name', label: 'User Name', text: 'text', style: '' },
    { id: 'file_path', label: 'File', type: 'text', style: '' }
  ];
  dataSourcememberdetails: any = [];

  matSortActive: any;
  matSortDirection: any;
  filterData: any;
  sessionKey: any;
  forDownloadOnly: boolean = false;
  total;
  tableHeader: any;
  page_size: any;
  dataSourceUploadedDocs: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private RestApi: RestService,
    private ngxService: NgxUiLoaderService,
    private modalService: BsModalService,
    private fb: FormBuilder,
  ) {
    this.sessionKey = this.route.snapshot.params.sessionkey;
    this.form_category_name = this.route.snapshot.params.form_category_name;
    this.category_id = this.route.snapshot.params.category_id;
    this.user_id = this.route.snapshot.params.user_id;
    this.userToken = localStorage.getItem('userToken');
    this.forDownloadOnly = this.router.url.indexOf("download") > -1;
  }

  ngOnInit(): void {
    this.filterData = history.state;
    if (Object.keys(this.filterData).length == 1) {
      if (!this.sessionKey || this.sessionKey == '' || !localStorage.getItem(this.sessionKey)
        || localStorage.getItem(this.sessionKey) == '') {
        Swal.fire({
          title: 'Error',
          icon: 'error',
          text: 'Search Parameter not found',
          showConfirmButton: true,
          timer: 1500
        }).then(result => {
          this.router.navigateByUrl('/admin/report');
        });
        return;
      }

      const sessionDataWithTimeStamp = JSON.parse(localStorage.getItem(this.sessionKey));
      this.filterData = sessionDataWithTimeStamp.sessionData;
    }

    try { delete this.filterData.navigationId; } catch { }

    localStorage.setItem(this.sessionKey, JSON.stringify({
      timeStamp: new Date(),
      sessionData: this.filterData
    }));
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit() {
    if (this.allPaginator && this.allPaginator.length > 0) {
      this.paginator = this.allPaginator.first;
      this.sort = this.allSort.first;
      if (!this.forDownloadOnly) {
        this.paginator.page.pipe(tap(() => this.getFormDetails())).subscribe();
        this.sort.sortChange.pipe(tap(() => this.getFormDetails())).subscribe();
      }
      else {
        this.paginator.page.pipe(tap(() => this.getUserUploadedList())).subscribe();
        this.sort.sortChange.pipe(tap(() => this.getUserUploadedList())).subscribe();
      }
    }
    this.getReportCategories();
    this.getUsersList();
    this.cleanOldSessionData();

    if (this.forDownloadOnly)
      this.getUserUploadedList();
  }

  async cleanOldSessionData() {
    for (const key in localStorage) {
      if (key.startsWith(ReportComponent.sessionKeyPrefix)) {
        const sessionDataWithTimeStamp = JSON.parse(localStorage.getItem(key));
        const date1: Date = new Date(sessionDataWithTimeStamp.timeStamp);
        const date2: Date = new Date();
        const diffDate = date2.getTime() - date1.getTime();
        const diffTime = Math.abs(diffDate);
        if (diffTime > 30 * 60 * 1000) {
          // 30min
          localStorage.removeItem(key);
        }
      }
    }
  }

  async getReportCategories() {
    if (!this.forDownloadOnly) {
      this.ngxService.start();
      this.form_category_Sub_Forms = await this.runApiCommunication('get-report-categories', {
        search: {
          category_id: this.category_id,
          user_id: this.user_id,
          ...this.filterData
        }
      });
    }
    this.updateUISelectedSubForm();
    this.ngxService.stop();
  }

  async getFormDetails() {
    this.ngxService.start();
    const param = {
      page: (this.paginator.pageIndex ? this.paginator.pageIndex : 0) + 1,
      sort: this.sort.active,
      order: this.sort.direction,
      recordsPerPage: this.paginator.pageSize ? this.paginator.pageSize : 10,
      search: {
        category_form_id: this.categoryFormId,
        user_id: this.user_id,
        ...this.filterData
      }
    };
    const memberDataWithPagination = await this.runApiCommunication('get-form-details', param);

    this.dataSourcememberdetails = new MatTableDataSource(memberDataWithPagination.data.data);
    const perPage = (memberDataWithPagination.perPage);
    const currentPage = memberDataWithPagination.currentPage;
    this.from = memberDataWithPagination.data.from;
    this.total_data = memberDataWithPagination.data.total;

    this.dataSourcememberdetails.sort = this.sort;

    this.ngxService.stop();
  }


  async getUsersList() {
    this.ngxService.start();
    const bmcPbrDataWithPagination = await this.runApiCommunication('get-users', {
      search: {
        user_id: this.user_id
      }
    });
    if (!bmcPbrDataWithPagination || !bmcPbrDataWithPagination.data || bmcPbrDataWithPagination.data.length < 1) {
      return;
    }

    this.dataSource = (bmcPbrDataWithPagination.data[0]);
    this.ngxService.stop();
  }

  loadFormData(activateIndexSubForm) {
    this.activeIndexSubForm = activateIndexSubForm;
    this.updateUISelectedSubForm();
  }

  runApiCommunication(fromPath: string, params: any): Promise<any> {
    const userToken = localStorage.getItem('userToken');
    const dataPromise = new Promise((resolve, reject) => {
      this.RestApi.postRequestCreator(fromPath, userToken, params)
        .subscribe((resp: any) => {
          this.ngxService.stop();
          if (resp && resp.status === 'success' && resp.code === 200) {
            resolve(resp.result);
          } else {
            Swal.fire({
              title: 'Error',
              icon: 'error',
              text: resp.message,
            });
            // reject([]);
          }
        }, error => {
          this.ngxService.stop();
          Swal.fire({
            title: 'Error',
            icon: 'error',
            text: error.message,
            showConfirmButton: false,
            timer: 1500,
          });
          // reject([]);
        });
    });
    dataPromise.catch(function onReject(e) {
      Swal.fire({
        title: 'Error',
        icon: 'error',
        text: e.message,
      });
    });
    return dataPromise;
  }

  updateUISelectedSubForm() {

    if (!this.form_category_Sub_Forms || this.form_category_Sub_Forms.length < 1) {
      return;
    }

    this.categoryFormId = this.form_category_Sub_Forms[this.activeIndexSubForm].id;

    this.getFormDetails();

    const matTableHeaderDetails = JSON.parse(this.form_category_Sub_Forms[this.activeIndexSubForm]?.list_header_format);
    if (!matTableHeaderDetails) {
      return;
    }
    this.displayedColumns = matTableHeaderDetails.displayedColumns.filter(x => x !== 'action');
    this.columnInformations = matTableHeaderDetails.columnInformations;
    if ((this.displayedColumns && this.columnInformations) && (this.displayedColumns.length && this.columnInformations.length)) {
      // tslint:disable-next-line:max-line-length
      this.matSortActive = matTableHeaderDetails.matSortActive ? matTableHeaderDetails.matSortActive : '';
      this.matSortDirection = matTableHeaderDetails.matSortDirection ? matTableHeaderDetails.matSortDirection : '';

    }
  }
  getYearsList() {
    const currentYear = (new Date()).getFullYear();
    const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step));
    this.yearOptions = range(currentYear, currentYear - 50, -1);
  }

  exportContentReport(type) {
    const param = {
      category_form_id: this.categoryFormId,
      user_id: this.user_id,
      ...this.filterData
    };

    let resource = '';
    let index = 0;
    // tslint:disable-next-line:forin
    for (const key in param) {
      resource += ((!index ? '?' : '&') + key + '=' + ((param[key]) ? btoa((param[key])) : btoa(null)));
      index++;
    }
    let a;
    const token = localStorage.getItem('userToken');
    // tslint:disable-next-line:max-line-length
    const routeLink = 'form-details-export';

    a = document.getElementById('exportcontent' + type);
    a.href = this.RestApi.apiBaseUrl + routeLink + '/' + type + '/' + token + resource;
    document.getElementById('exportcontent' + type).click();
  }


  downloadFile(fullFilePath) {
    this.userToken = localStorage.getItem('userToken');
    this.RestApi.downloadFile(fullFilePath, this.userToken).subscribe(file => {
      const fileExt = file.name ?file.name.split('.').pop(): '';
      let blobFile = new Blob([file], { type: 'application/' + fileExt });

      if (window.navigator && window.navigator["msSaveOrOpenBlob"]) {
        window.navigator["msSaveOrOpenBlob"](blobFile);
        return;
      }
      const downloadURL = URL.createObjectURL(blobFile);
      window.open(downloadURL);
    });
  }

  showFile(template: TemplateRef<any>, fileUrl, fileType) {
    // tslint:disable-next-line:triple-equals
    if (fileType == 'pdf') {
      this.isLoading = true;
      this.isError = false;
    } else {
      this.isLoading = false;
      this.isError = false;
    }
    this.modalRef = this.modalService.show(template, {
      class: 'modal-dialog-centered modal-lg'
    });
    this.fileUrl = this.RestApi.apiBaseUrl + fileUrl;
    this.fileType = fileType;
  }
  async getUserUploadedList() {
    const param = {
      page: (this.paginator.pageIndex ? this.paginator.pageIndex : 0) + 1,
      recordsPerPage: this.paginator.pageSize ? this.paginator.pageSize : 5,
      search: {
        ...this.filterData,
        user_id: this.user_id
      },
    };
    this.ngxService.start();
    const dataWithPagination = await this.runApiCommunication('get-user-applications', param);
    this.total = dataWithPagination.data.total;
    this.page_size = dataWithPagination.data.per_page;
    this.dataSourceUploadedDocs = new MatTableDataSource(dataWithPagination.data.data);
    this.dataSourceUploadedDocs.sort = this.sort;

    this.ngxService.stop();
  }

  isLoading: any;
  isError: any;
  onProgress(progressData: PDFProgressData) {
    this.isLoading = true;
    this.isError = false;
  }
  onComplete(pdf: PDFDocumentProxy) {
    this.isLoading = false;
    this.isError = false;
  }
  onError(error: any) {
    this.isLoading = false;
    this.isError = true;
  }
}



