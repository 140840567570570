import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { RestService } from '../../services/rest.service';
import Swal from 'sweetalert2';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray, ValidatorFn, AbstractControl } from '@angular/forms';
import { Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { errorValidate, checkNumber } from '../../_helpers/utility';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { tap } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})

export class ListComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  // displayedColumns: string[] = ['serial', 'address', 'age', 'age_of_specialization', 'gender', 'id',
  //                               'member_type', 'name', 'user_email', 'year', 'action'];
  displayedColumns: string[];
  columnInformations: any = [];
  tableHeader: any;
  matSortActive: any;
  matSortDirection: any;
  dataSource: any = [];
  from: number;
  total: number;

  id: any = '';
  categoryId: any = '';
  isFormAvailable: any = 1;
  private userToken: string;
  public categoryFormdata: any;
  public formData: any;
  formName: any;
  formTitle: string;
  formType: any;
  formSubText: any;
  formDescription: any;

  ngFilter: FormGroup;
  formJson: any = {};
  labelArray = [];
  controlNameArray = [];
  get headerTitle() {
    return this.formTitle + ' / ' + 'List View';

  }
  constructor(
    private router: Router,
    private service: RestService,
    private fb: FormBuilder,
    @Inject(DOCUMENT) document,
    private activeroute: ActivatedRoute,
    private ngxService: NgxUiLoaderService,
    private http: HttpClient,
  ) {
    this.id = activeroute.snapshot.params.id;
    this.createForm();

  }

  createForm() {
    this.ngFilter = this.fb.group({
      search: [''],
    },
    );
  }

  ngOnInit(): void {
    this.initProcess();
  }

  initProcess() {
    const ls = localStorage.getItem('authData');
    this.userToken = localStorage.getItem('userToken');
    this.getCategoryForm();
    this.getFormDetails();
  }

  getCategoryForm() {
    const param = {
      category_form_id: this.id
    };
    this.ngxService.start();
    this.service.postRequestCreator('get-category-forms-list', this.userToken, param).subscribe((response: any) => {
      this.ngxService.stop();
      if (response.code === 200) {
        this.categoryFormdata = response.result && response.result.length && response.result[0] ? response.result[0] : {};
        this.categoryId = this.categoryFormdata.category_id ? this.categoryFormdata.category_id : '';
        this.formName = this.categoryFormdata.form_text ? this.categoryFormdata.form_text : '';
        this.formTitle = this.categoryFormdata.form_title ? this.categoryFormdata.form_title : '';
        this.formType = this.categoryFormdata.form_type ? this.categoryFormdata.form_type : '';
        this.formSubText = this.categoryFormdata.form_subtext ? this.categoryFormdata.form_subtext : '';
        this.tableHeader = this.categoryFormdata.list_header_format ? JSON.parse(this.categoryFormdata.list_header_format) : {};

        if (this.tableHeader) {
          this.displayedColumns = this.tableHeader.displayedColumns ? this.tableHeader.displayedColumns : [];
          this.columnInformations = this.tableHeader.columnInformations ? this.tableHeader.columnInformations : [];

          if ((this.displayedColumns && this.columnInformations) && (this.displayedColumns.length && this.columnInformations.length)) {
            // tslint:disable-next-line:max-line-length
            this.matSortActive = this.tableHeader.matSortActive ? this.tableHeader.matSortActive : '';
            this.matSortDirection = this.tableHeader.matSortDirection ? this.tableHeader.matSortDirection : '';

          } else {
            this.isFormAvailable = 0;
          }

        } else {
          this.isFormAvailable = 0;
        }


      } else {
        Swal.fire({
          html: '<b>Something went wrong! Try Again</b>',
          showConfirmButton: true,
        });
      }
    }, (error) => {
      this.ngxService.stop();
    });
  }

  getFormDetails() {
    this.ngFilter.value.category_form_id = this.id;
    const param = {
      page: (this.paginator.pageIndex ? this.paginator.pageIndex : 0) + 1,
      search: this.ngFilter.value,
      sort: this.sort.active,
      order: this.sort.direction,
      recordsPerPage: this.paginator.pageSize ? this.paginator.pageSize : 10
    };
    this.ngxService.start();
    this.service.postRequestCreator('get-form-details', this.userToken, param).subscribe((response: any) => {
      this.ngxService.stop();
      if (response.code === 200) {
        this.ngxService.stop();
        this.formData = response;
        this.dataSource = new MatTableDataSource(this.formData.result.data.data);
        const perPage = (this.formData.result.perPage);
        const currentPage = this.formData.result.currentPage;
        this.from = this.formData.result.data.from;
        this.total = this.formData.result.data.total;
        this.dataSource.sort = this.sort;
      } else {
        Swal.fire({
          html: '<b>Something went wrong! Try Again</b>',
          showConfirmButton: true,
        });
      }
    }, (error) => {
      this.ngxService.stop();
    });
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit() {
    this.paginator.page.pipe(tap(() => this.getFormDetails())).subscribe();
    this.sort.sortChange.pipe(tap(() => this.getFormDetails())).subscribe();
  }

  printObject(object) {
    return JSON.stringify(object);
  }

}

