import Swal from 'sweetalert2';
import { FormGroup } from '@angular/forms';
import * as CryptoJS from 'crypto-js';

export function errorValidate(err) {
  if (
    err.code === 401 ||
    err.error.message === 'Unauthenticated.' ||
    err.status === 401
  ) {
    localStorage.removeItem('authData');
    localStorage.removeItem('userName');
    localStorage.removeItem('userEmail');
    localStorage.removeItem('userToken');

    const message = 'Session Expired';

    Swal.fire({
      title: 'Error!',
      html: '<b>' + message + '</b>',
      showConfirmButton: false,
      timer: 3000,
    });
    location.reload();
    return;
  } else {
    const message = err && err.error && err.error.message ? err.error.message : err.statusText;

    Swal.fire({
      title: 'Error!',
      html: '<b>' + message + '</b>',
      showConfirmButton: false,
      timer: 5000,
    });
    return;
  }
}

export function checkNumber(event) {
  const numCharacters = /[0-9]+/g;

  if (
    !(
      (event.keyCode >= 48 && event.keyCode <= 57) ||
      (event.keyCode >= 96 && event.keyCode <= 105) ||
      event.keyCode == 8 ||
      event.keyCode == 37 ||
      event.keyCode == 39 ||
      event.keyCode == 9 ||
      event.keyCode == 190
    ) &&
    numCharacters.test(event.key) === false
  ) {
    event.preventDefault();
  }

  if (
    !(
      (event.which >= 48 && event.which <= 57) ||
      (event.which >= 96 && event.which <= 105) ||
      event.which == 8 ||
      event.which == 37 ||
      event.which == 39 ||
      event.which == 9 ||
      event.which == 190
    ) &&
    numCharacters.test(event.key) === false
  ) {
    event.preventDefault();
  }
}

export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      return;
    }
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}
export function date(controlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    console.log('date', control);
    const currentDate = new Date();
    if (control.value == '' || control.value == null) {
      control.setErrors({ mustValid: true });
    } else {
      control.setErrors(null);
    }
  };
}

export function passwordPolicy(controlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const upperCaseCharacters = /[A-Z]+/g;
    const lowerCaseCharacters = /[a-z]+/g;
    const numCharacters = /[0-9]+/g;
    const specialCharacters = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    if (upperCaseCharacters.test(control.value) === false) {
      control.setErrors({ mustUpp: true });
    } else if (lowerCaseCharacters.test(control.value) === false) {
      control.setErrors({ mustLow: true });
    } else if (numCharacters.test(control.value) === false) {
      control.setErrors({ mustNum: true });
    } else if (specialCharacters.test(control.value) === false) {
      control.setErrors({ mustSpc: true });
    } else if (control.value.length < 8) {
      control.setErrors({ mustLen: true });
    } else {
      control.setErrors(null);
    }
  };
}

export function email(controlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const checkValue = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (checkValue.test(control.value) === false) {
      control.setErrors({ mustEmail: true });
    } else {
      control.setErrors(null);
    }
  };
}

export function calculateSHA256(input: string) {
  return CryptoJS.SHA256(input).toString();
}

export function number(controlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    if (control.value.toString().length >= 5) {
      control.setErrors(null);
    } else {
      control.setErrors({ mustMob: true });
    }
  };
}

export function diff_years(dt1, dt2) {
  if (dt1 === '') {
    return;
  }
  let diff = (dt2.getTime() - dt1.getTime()) / 1000;
  diff /= 60 * 60 * 24;
  return Math.abs(Math.round(diff / 365.25));
}

export function formatDate(date) {
  const d = new Date(date);
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) {
    month = '0' + month;
  }
  if (day.length < 2) {
    day = '0' + day;
  }
  return [year, month, day].join('-');
}

export function minValue(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];

    if (!control.value) {
      control.setErrors({ required: true });
    }

    const matchingControl = formGroup.controls[matchingControlName];
    if (parseFloat(control.value) > parseFloat(matchingControl.value)) {
      control.setErrors({ minValue: true });
    } else if (parseFloat(control.value) < parseFloat(matchingControl.value)) {
      control.setErrors(null);
    }
  };
}

export function maxValue(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];
    if (!matchingControl.value) {
      matchingControl.setErrors({ required: true });
    }

    if (parseFloat(control.value) > parseFloat(matchingControl.value)) {
      matchingControl.setErrors({ maxValue: true });
    } else if (parseFloat(control.value) < parseFloat(matchingControl.value)) {
      matchingControl.setErrors(null);
    }
  };
}
// for password_match
export function ConfirmedValidator(
  controlName: string,
  matchingControlName: string
) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];
    if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
      return;
    }
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ confirmedValidator: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}

