import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { RestService } from '../../services/rest.service';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import {calculateSHA256, errorValidate} from '../../_helpers/utility';
import { Location } from '@angular/common';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    @Inject(DOCUMENT) document,
    private service: RestService,
    private http: HttpClient,
    private router: Router,
    private activeroute: ActivatedRoute,
    private ngxService: NgxUiLoaderService,
    private location: Location
  ) {
    this.createForm();
  }
  loginForm: FormGroup;
  data: any;
  authData: any;
  userToken: any;
  errorMessage: any;

  submitted = false;
  error = '';
  validPasscode: boolean = undefined;
  passcode;

  // tslint:disable-next-line:typedef
  createForm() {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required]],
      passcode: ['', Validators.required]
    });

    this.loginForm.controls.passcode.valueChanges.subscribe(newValue => {
      if (newValue.length < this.passcode.length) {
        this.validPasscode = undefined;
        return;
      }
    });
  }
  ngOnInit(): void {
    this.initProcess();
  }

  initProcess() {
    const ls = localStorage.getItem('authData');
    this.userToken = localStorage.getItem('userToken');
    const data = JSON.parse(ls);
    if (data && data[0].token) {
      const userRoleId = data[0].user && data[0].user.user_role_id ? data[0].user.user_role_id : null;
      if ([1, 2, 3, 4].includes(userRoleId)) {
        this.router.navigate(['/admin']);
      } else {
        this.router.navigate(['/user']);
      }
    } else {
      this.getRandomString();
    }
  }

  // tslint:disable-next-line:typedef
  async logIn(credentials) {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.validateCode();
    if (!this.validPasscode) {
      return;
    }
    this.ngxService.start();
    const param = {
      username: btoa(credentials.email),
    };

    this.service.postRequestCreator('send-password-reset-link', null, param).subscribe((response: any) => {
      this.ngxService.stop();
      this.data = response;
      // tslint:disable-next-line:radix
      if (this.data.result && this.data.code && parseInt(this.data.code) === 200) {
        this.ngxService.stop();
        const message = this.data.result && this.data.message ? this.data.message : 'Mail has been sent to associated email with password resent link.';
        Swal.fire({
          html: '<b>' + message + '</b>',
          showConfirmButton: false,
          timer: 5000
        });
        // tslint:disable-next-line:radix
      } else if (this.data.result && this.data.code && parseInt(this.data.code) === 429) {
        this.ngxService.stop();
        const message = this.data.result && this.data.message ? this.data.message : 'Too Many Requests.';
        Swal.fire({
          title: 'Error!',
          html: '<b>' + message + '</b>',
          icon: 'error',
          showConfirmButton: false,
          timer: 3000,
        });
      } else {
        const message = this.data.result && this.data.message ? this.data.message : 'Something went wrong. Please try again!';
        Swal.fire({
          html: '<b>' + message + '</b>',
          showConfirmButton: true
        });
      }
    }, (error) => {
      this.ngxService.stop();
      this.loginForm.controls.passcode.setValue('');
      this.loginForm.controls.passcode.setErrors(null);
    });
    this.getRandomString();
    this.loginForm.controls.passcode.setValue('');
    this.loginForm.controls.passcode.setErrors(null);
  }
  getRandomString() {
    const randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < 6; i++) {
      result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    this.passcode = result;
  }

  validateCode() {
    // tslint:disable-next-line:triple-equals
    this.validPasscode = (this.loginForm.controls.passcode.value == this.passcode);
  }

  protected readonly navigator = navigator;

  navigateBack() {
    this.location.back();
  }
}
