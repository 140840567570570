import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subscriber } from 'rxjs';
import { RestService } from 'src/app/services/rest.service';
import { BreadCrumbModel } from '../Model/BreadCrumb.Model';

@Component({
  selector: 'app-welcome-screen',
  templateUrl: './welcome-screen.component.html',
  styleUrls: ['./welcome-screen.component.css']
})
export class WelcomeScreenComponent implements OnInit {

  route;
  categoriesData;
  userToken: string;
  userEmail: string;
  BreadCrumbData: BreadCrumbModel[] = [];

  cardsInfo = [
    {
      title: "Total Number of Forms",
      getCount: 0,
      icon: 'fa-clipboard',
      cssClass: 'totalForms'
    },
    {
      title: "Total Number of eBMC Forms",
      getCount: 0,
      icon: 'fa-fax',
      cssClass: 'eBMCForms'
    },
    {
      title: "Total Number of ePBR Forms",
      getCount: 0,
      icon: 'fa-list-alt',
      cssClass: 'ePBRForms'
    },
    {
      title: "Total Number of ePBR - PDF",
      getCount: 0,
      icon: 'fa-file-pdf',
      cssClass: 'ePBRPdfs'
    }
  ];

  constructor(
    private RestApi: RestService,
    private router: Router,
    private ngxService: NgxUiLoaderService,
  ) {
    const breadCrumb = new BreadCrumbModel();
    breadCrumb.Label = "Welcome";
    this.BreadCrumbData.push(breadCrumb);
  }

  ngOnInit(): void {
    this.initProcess();
  }
  initProcess() {
    const ls = localStorage.getItem('authData');
    this.userToken = localStorage.getItem('userToken');
    this.userEmail = localStorage.getItem('userEmail');
    this.getdata();
    this.getDashboardSummary();
  }

  getdata() {
    this.route = 'get-categories-list'
    this.ngxService.start();
    this.RestApi.postRequestCreator('get-categories-list', this.userToken).subscribe((data: any) => {
      this.ngxService.stop();
      this.categoriesData = data.result;
    });
  }
  getDashboardSummary() {
    this.route = 'get-dashboard-summary'
    this.ngxService.start();
    this.RestApi.postRequestCreator('get-dashboard-summary', this.userToken).subscribe((data: any) => {
      this.ngxService.stop();
      let cardFormCountInfo = data.result;
      
      this.cardsInfo[0].getCount = cardFormCountInfo.totalform;
      this.cardsInfo[1].getCount = cardFormCountInfo.bmcform;
      this.cardsInfo[2].getCount = cardFormCountInfo.pbrform;
      this.cardsInfo[3].getCount = cardFormCountInfo.epbrpdf;
    });
  }

}
