import { Component, ElementRef, OnInit, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, MatSort } from '@angular/material';
import Swal from 'sweetalert2';
import { RestService } from 'src/app/services/rest.service';
import { tap } from 'rxjs/operators';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';


@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {

  public static sessionKeyPrefix = "rpt";

  ngFilter: FormGroup;
  tableHeader: any;
  total_bmc_pbr_data;
  page_size: any;
  district_list = [];
  sub_area_category_list = [];
  area_category_list = [];
  taluka_list = [];
  bmc_list = [];
  yearOptions = [];


  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChildren('subFormHyperlink') private subFormHyperlinks: QueryList<ElementRef<any>>


  constructor(private fb: FormBuilder,
    private RestApi: RestService,
    private ngxService: NgxUiLoaderService,
    private renderer: Renderer2,
    private route: Router
  ) {

  }

  displayedColumns: string[] = ['#', 'acat_name', 'ascat_name', 'district_name', 'taluka_name', 'bmc_name', 'co_ordinator_name',
    'co_ordinator_mobile', 'co_ordinator_email', 'action'];

  columnInformations: any = [
    { id: '#', label: '#', type: 'text', style: '' },
    { id: 'acat_name', label: 'Category', type: 'text', style: '' },
    { id: 'ascat_name', label: 'Sub-Category', type: 'text', style: '' },
    { id: 'district_name', label: 'District', type: 'text', style: '' },
    { id: 'taluka_name', label: 'Taluka', type: 'text', style: '' },
    { id: 'bmc_name', label: 'BMC Name', type: 'text', style: '' },
    { id: 'co_ordinator_name', label: 'Co-Ordinator Name', type: 'text', style: '' },
    { id: 'co_ordinator_mobile', label: 'Mobile', type: 'text', style: '' },
    { id: 'co_ordinator_email', label: 'Email', type: 'text', style: '' },
    { id: 'action', label: '', type: 'text', style: '' }
  ];
  dataSource: any = [];
  ngOnInit(): void {
    this.ngFilter = this.fb.group(
      {
        acat_id: new FormControl(''),
        ascat_id: new FormControl(''),
        district_id: new FormControl(''),
        taluka_id: new FormControl(''),
        category_detail_id: new FormControl(''),
        year: new FormControl('')
      }
    );

    this.getAreaCategoryList();
    this.ngxService.start();
    this.ngFilterFormControl.acat_id.valueChanges.subscribe(newValue => {
      this.getSubCategoryList(newValue);
    });
    this.ngFilterFormControl.ascat_id.valueChanges.subscribe(newValue => {
      this.getDistrictList(newValue);
    });
    this.ngFilterFormControl.district_id.valueChanges.subscribe(newValue => {
      this.getTalukaList(newValue);
    });
    this.ngFilterFormControl.taluka_id.valueChanges.subscribe(newValue => {
      this.getBmcNameList(newValue);
    });

    this.getYearsList();
    this.getReportSummary();
  }

  get ngFilterFormControl() {
    return this.ngFilter.controls;
  }

  runApiCommunication(fromPath: string, params: any): Promise<any> {
    const userToken = localStorage.getItem('userToken');
    const dataPromise = new Promise((resolve, reject) => {
      this.RestApi.postRequestCreator(fromPath, userToken, params)
        .subscribe((resp: any) => {
          this.ngxService.stop();
          if (resp && resp.status === 'success' && resp.code === 200) {
            resolve(resp.result);
          } else {
            Swal.fire({
              title: 'Error',
              icon: 'error',
              text: resp.message,
            });
            // reject([]);
          }
        }, error => {
          this.ngxService.stop();
          Swal.fire({
            title: 'Error',
            icon: 'error',
            text: error.message,
            showConfirmButton: false,
            timer: 1500,
          });
          // reject([]);
        });
    });
    dataPromise.catch(function onReject(e) {
      Swal.fire({
        title: 'Error',
        icon: 'error',
        text: e.message,
      });
    });
    return dataPromise;
  }
  ngAfterViewInit() {
    this.paginator.page.pipe(tap(() => this.getReportSummary())).subscribe();
  }

  async getReportSummary() {
    const param = {
      page: (this.paginator.pageIndex ? this.paginator.pageIndex : 0) + 1,
      search: this.ngFilter.value,
      recordsPerPage: this.paginator.pageSize ? this.paginator.pageSize : 5
    };
    this.ngxService.start();
    const bmcPbrDataWithPagination = await this.runApiCommunication('get-report-summary', param);
    this.total_bmc_pbr_data = bmcPbrDataWithPagination.total;
    this.page_size = bmcPbrDataWithPagination.per_page;
    this.dataSource = new MatTableDataSource(bmcPbrDataWithPagination.data);
    this.ngxService.stop();
  }
  async getAreaCategoryList() {
    this.ngxService.start();
    this.ngxService.start();
    this.area_category_list = await this.runApiCommunication('get-areas-list', null);
    this.ngxService.stop();
  }

  async getSubCategoryList(categoryId: any) {
    this.ngxService.start();
    if (categoryId !== undefined && categoryId.toString() == "0") {
      this.sub_area_category_list = [];
      return;
    }
    this.sub_area_category_list = await this.runApiCommunication('get-area-sub-categories-list', { area_id: categoryId });
    this.ngxService.stop();
  }

  async getDistrictList(subCategoryId) {
    this.ngxService.start();
    if (subCategoryId !== undefined && subCategoryId.toString() == "0") {
      this.district_list = [];
      return;
    }
    this.district_list = await this.runApiCommunication('get-districts-list', { sub_area_id: subCategoryId });
    this.ngxService.stop();
  }

  async getTalukaList(districtId) {
    this.ngxService.start();
    if (districtId !== undefined && districtId.toString() == "0") {
      this.taluka_list = [];
      return;
    }
    this.taluka_list = await this.runApiCommunication('get-talukas-list', { district_id: districtId });
    this.ngxService.stop();
  }

  async getBmcNameList(talukaId) {
    this.ngxService.start();
    if (talukaId !== undefined && talukaId.toString() == "0") {
      this.bmc_list = [];
      return;
    }
    this.bmc_list = await this.runApiCommunication('get-category-details-list', {
      taluka_id: talukaId,
      area_id: this.ngFilterFormControl.acat_id.value,
      district_id: this.ngFilterFormControl.district_id.value,
      area_sub_category_id: this.ngFilterFormControl.ascat_id.value,
    });
    this.ngxService.stop();
  }

  getYearsList() {
    const currentYear = (new Date()).getFullYear();
    const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step));
    this.yearOptions = range(currentYear, currentYear - 50, -1);
  }

  get generateNewSessionKey() {
    return ReportComponent.sessionKeyPrefix + Date.now().toString() + Math.random();
  }
}
