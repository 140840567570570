import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MainWebAppRoutingModule } from './main-web-app-routing.module';
import { MainWebAppComponent } from './main-web-app.component';
import { WelcomeScreenComponent } from './welcome-screen/welcome-screen.component';
import { HeaderComponent } from '../common-component/header/header.component';
import { SidebarComponent } from '../common-component/sidebar/sidebar.component';
import {ReportComponent} from './report/report.component';
import {CategoryWiseReportComponent} from './category-wise-report/category-wise-report.component';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { CategoriesFormsComponent } from './categories-forms/categories-forms.component';
import { FormComponent } from './form/form.component';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { ListComponent } from './list/list.component';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatFormFieldModule, MatInputModule, MatTableModule } from '@angular/material';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MatSliderModule, DateAdapter } from '@angular/material';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTabsModule } from '@angular/material/tabs';
import {NgDynamicBreadcrumbModule} from 'ng-dynamic-breadcrumb';
import { BreadCrumbComponent } from './common-internal-component/bread-crumb/bread-crumb.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { DocumentUploadComponent } from './document-upload/document-upload.component';
import { FileUploadModule } from 'ng2-file-upload';
import { UploadedDocumentListComponent } from './uploaded-document-list/uploaded-document-list.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';


@NgModule({
  declarations: [
    MainWebAppComponent,
    WelcomeScreenComponent,
    HeaderComponent,
    SidebarComponent,
    CategoriesFormsComponent,
    FormComponent,
    ListComponent,
    BreadCrumbComponent,
    DocumentUploadComponent,
    UploadedDocumentListComponent,
    ReportComponent,
    CategoryWiseReportComponent
  ],
  exports: [
    HeaderComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    MainWebAppRoutingModule,
    NgxUiLoaderModule,
    ModalModule.forRoot(),
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatIconModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatFormFieldModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCardModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatMenuModule,
    MatTabsModule,
    NgDynamicBreadcrumbModule,
    FileUploadModule,
    PdfViewerModule
  ]
})
export class MainWebAppModuleModule { }
