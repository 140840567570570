import { Component, OnInit, ViewChild } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { RestService } from 'src/app/services/rest.service';
import * as Highcharts from 'highcharts';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { MatPaginator, MatSort } from '@angular/material';
import { MatTableDataSource } from '@angular/material/table';
import { tap } from 'rxjs/operators';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  constructor(private route: ActivatedRoute,
              private ngxService: NgxUiLoaderService,
              private fb: FormBuilder,
              private RestApi: RestService,
              private router: Router,) { }
  numberofBmcData = [];
  formSubmitted = [];
  resisteredBmcRevenueWise;
  submittedRevenueWise;
  donutOption;
  bmcGraphAreaCategory: any;
  formGraphAreaCategory: any;
  bmcGraphFilter = {
    search: {
      acat_id: 1
    }
  };
  formGraphFilter = {
    search: {
      acat_id: 1
    }
  };
  private userToken: string;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  isLoading = {
    bmcGraphAreaCategory: false,
    formGraphAreaCategory: false,
    resisteredBmcRevenueWise: false,
    submittedRevenueWise: false,
    bmcDetails: false
  };
  // tslint:disable-next-line:variable-name
  page_size: any;
  ngFilter: FormGroup;
  name: string;
  position: number;
  weight: number;
  symbol: string;
  createRegisterChart;
  createSubmitChart;
  // tslint:disable-next-line:variable-name
  total_bmc_data;
  tableHeader: any;
  matSortActive: any = 'bmc_name';
  matSortDirection: any = 'asc';

  division = [];
  // tslint:disable-next-line:variable-name
  district_list = [];
  displayedColumns: string[] = ['bmc_name', 'bmc_registered', 'bmc_form_count', 'pbr_form_count',
    'co_ordinator_name', 'co_ordinator_mobile'];

  columnInformations: any = [
    { id: 'bmc_name', label: 'BMC Name', type: 'text', style: '' },
    { id: 'bmc_registered', label: 'Registered', type: 'text', style: '' },
    { id: 'bmc_form_count', label: 'No. of e-BMC form filed', type: 'text', style: '' },
    { id: 'pbr_form_count', label: 'No. of e-PBR form filed', type: 'text', style: '' },
    { id: 'co_ordinator_name', label: 'Contact person name', type: 'text', style: '' },
    { id: 'co_ordinator_mobile', label: 'Contact Details', type: 'text', style: '' }
  ];
  revDivId: any;
  districtId: any;
  dataSource: any = [];
  userRoleId: any;
  ngOnInit(): void {

    this.initProcess();

    this.ngFilter = this.fb.group(
      {
        rev_div_id: new FormControl(''),
        district_id: new FormControl(''),
        bmc_registered: new FormControl(''),
        bmc_form_count: new FormControl(''),
        pbr_form_count: new FormControl('')
      }
    );
    this.getSummry();
    this.getRegistrationSummary();
    this.getBMCRegistrationGraph();
    this.getFormRegistrationGraph();
    this.getRegisterRevenueSummary();
    this.getSubmittedRevenueSummary();


    if (this.userRoleId && [1,7].includes(this.userRoleId)) {
      this.getDivisionList().then(
        (val) => {
          if (val && val.length) {
            this.revDivId = this.ngFilter.value.rev_div_id = val[0].revenu_div_id;

            this.getDistrictList().then(
              (val2: any) => {
                // @ts-ignore
                if (val2 && val2.length) {
                  this.districtId = this.ngFilter.value.district_id = val2[0].district_id;
                  this.getBmcDetails();
                }
              });
          }
        });
    } else {
      this.getBmcDetails();
    }
    // this.getDistrictList();
  }
  initProcess() {
    const ls = localStorage.getItem('authData');
    this.userToken = localStorage.getItem('userToken');
    const data = JSON.parse(ls);
    if (data && data[0].token) {
      this.userRoleId = data[0].user && data[0].user.user_role_id ? data[0].user.user_role_id : null;
      if ([1, 2, 3, 4, 6, 7].includes(this.userRoleId)) {
        this.router.navigate(['/admin']);
      } else {
        this.router.navigate(['/user']);
      }
    } else {
      this.router.navigate(['/admin/login']);
    }
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit() {
    this.paginator.page.pipe(tap(() => this.getBmcDetails())).subscribe();
    this.sort.sortChange.pipe(tap(() => this.getBmcDetails())).subscribe();
  }

  async getBmcDetails() {
    const param = {
      page: (this.paginator.pageIndex ? this.paginator.pageIndex : 0) + 1,
      search: this.ngFilter.value,
      sort: this.sort?.active,
      order: this.sort?.direction,
      recordsPerPage: this.paginator.pageSize ? this.paginator.pageSize : 5
    };

    // this.ngxService.start();
    this.isLoading.bmcDetails = true;
    const bmdDataWithPagination = await this.runApiCommunication('get-bmc-details', param);
    this.isLoading.bmcDetails = false;
    this.total_bmc_data = bmdDataWithPagination.data.total;
    this.page_size = bmdDataWithPagination.data.per_page;
    this.dataSource = new MatTableDataSource(bmdDataWithPagination.data.data);
    this.dataSource.sort = this.sort;
  }
  async getDivisionList() {
    const param = {
      rev_div_id: this.ngFilter.value.rev_div_id,
    };
    return this.division = await this.runApiCommunication('get-division-list', param);
  }

  async getSummry() {
    this.ngxService.start();
    this.formSubmitted = await this.runApiCommunication('get-form-summary', null);
  }

  async getRegistrationSummary() {
    this.ngxService.start();
    this.numberofBmcData = await this.runApiCommunication('get-registration-summary', null);
  }

  async getDistrictList() {
    const param = {
      rev_div_id: this.ngFilter.value.rev_div_id,
      state_id: 1
    };
    this.ngxService.start();
    return this.district_list = await this.runApiCommunication('get-districts-list', param);
  }

  async getRegisterRevenueSummary() {
    // this.ngxService.start();
    this.isLoading.resisteredBmcRevenueWise = true;
    const result = await this.runApiCommunication('get-revenue-summary', null);
    this.isLoading.resisteredBmcRevenueWise = false;
    this.resisteredBmcRevenueWise = result.data;
  }

  async getSubmittedRevenueSummary() {
    // this.ngxService.start();
    this.isLoading.submittedRevenueWise = true;
    const result = await this.runApiCommunication('get-form-submitted-details', null);
    this.isLoading.submittedRevenueWise = false;
    this.submittedRevenueWise = result.data;
  }

  async getBMCRegistrationGraph() {
    // this.ngxService.start();
    this.isLoading.bmcGraphAreaCategory = true;
    const data = await this.runApiCommunication('get-subareawise-registered-bmc-graph-details', this.bmcGraphFilter);
    this.isLoading.bmcGraphAreaCategory = false;
    const title = data.title ? data.title : '';
    const subtitle = this.bmcGraphAreaCategory = data.subtitle ? data.subtitle : '';
    const series = data.series ? data.series : [];
    this.drawDonutChart(title, subtitle, series, 'register-chart-container');
  }

  async getFormRegistrationGraph() {
    // this.ngxService.start();
    this.isLoading.formGraphAreaCategory = true;
    const data = await this.runApiCommunication('get-subareawise-submitted-form-graph-details', this.formGraphFilter);
    this.isLoading.formGraphAreaCategory = false;
    const title = data.title ? data.title : '';
    const subtitle = this.formGraphAreaCategory = data.subtitle ? data.subtitle : '';
    const series = data.series ? data.series : [];
    this.drawDonutChart(title, subtitle, series, 'submit-chart-container');
  }

  runApiCommunication(fromPath: string, params: any): Promise<any> {
    const userToken = localStorage.getItem('userToken');
    const dataPromise = new Promise((resolve, reject) => {
    this.RestApi.postRequestCreator(fromPath, userToken, params)
      .subscribe((resp: any) => {
        this.ngxService.stop();
        if (resp && resp.status === 'success' && resp.code === 200) {
          resolve(resp.result);
        } else {
          Swal.fire({
            title: 'Error',
            icon: 'error',
            text: resp.message,
          });
          // reject([]);
        }
      }, error => {
        this.ngxService.stop();
        Swal.fire({
          title: 'Error',
          icon: 'error',
          text: error.message,
          showConfirmButton: false,
          timer: 1500,
        });
        // reject([]);
      });
    });
    dataPromise.catch(function onReject(e) {
      Swal.fire({
        title: 'Error',
        icon: 'error',
        text: e.message,
      });
    });
    return dataPromise;
  }

  toggle(graph, e) {
    if (graph === 'bmcGraph') {
      this.bmcGraphFilter.search.acat_id = e;
      this.getBMCRegistrationGraph();
    } else if (graph === 'formGraph') {
      this.formGraphFilter.search.acat_id = e;
      this.getFormRegistrationGraph();
    } else { return; }
  }

  drawDonutChart(title = '', subtitle = '', series = [], chartDiv = '') {

    this.donutOption = {
      chart: {
        type: 'pie',
      },
      title: {
        text: title
      },
      subtitle: {
        text: subtitle,
        y: 155,
        fontWeight: 'bold',
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [
              'downloadPNG', 'downloadSVG', 'downloadPDF', 'separator', 'downloadXLS'
            ]
          },
        },
        enabled: false,
      },
      navigation: {
        buttonOptions: {
          align: 'right',
          verticalAlign: 'top',
          y: 0,
          x: -35,
          theme: {
            fill: 'rgba(23, 160, 219, 0)'
          }
        }
      },
      credits: {
        enabled: false
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      accessibility: {
        point: {
          valueSuffix: '%'
        }
      },
      legend: {
        layout: 'vertical',
        align: 'center',
        verticalAlign: 'bottom',
        itemMarginBottom: 15,
        itemStyle: {
          fontSize: 15
        },
        labelFormatter() {
          return this.name + '<b> ' + this.y + '</b>';
        }
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '{point.name}: {point.y}'
          },
          showInLegend: false
        }
      },
      series: [{
        size: '70%',
        innerSize: '60%',
        showInLegend: true,
        marker: { symbol: 'rect', radius: 0 },
        dataLabels: {
          enabled: false
        },
        data: series
      }]
    };
    Highcharts.chart(chartDiv, this.donutOption).reflow();
  }

}

