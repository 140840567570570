<div class="h-100">
  <mat-toolbar #header class="header-style p-0">
    <div class="row w-100">
      <div class="col-2 col-md-1 pl-4 pl-md-4 verticle-content-middle d-lg-none">
        <img src="assets/images/logo.png" style="width: 70px;">
      </div>
      <div
        class="col-10 col-md-11 title pl-md-5 pl-2 pl-lg-2 text-start font-lg-blue font-size-30 font-weight-bold pl-2 verticle-content-middle text-start text-wrap">
        <img src="assets/images/logo.png" style="width: 70px;" class="mx-3 d-none d-lg-inline">
        Maharashtra State Biodiversity Board
      </div>
    </div>
  </mat-toolbar>
  <ngx-ui-loader></ngx-ui-loader>
  <div class="row m-0 p-0 content-style mx-3" ngxUiLoaderBlurred blur="1">
    <div class="col-12 verticle-content-middle justify-content-center" (window:resize)="onResize($event)">
      <form #loginContent [formGroup]="loginForm" (ngSubmit)="logIn(loginForm.value)" class="signin-form verticle-content-middle">
        <div class="row login-bg-img h-100" style="border-radius: 15px;">
          <div class="col-md-4 p-lg-4 col-12 verticle-content-middle left-div">
            <div class="row">
              <div class="col-2 d-lg-block d-xl-block d-sm-none"></div>
              <div class="col-md-10">
                <div class="login-content ml-md-4 pl-md-4">
                  <div class="font-size-40 font-weight-bold py-2">Forget Password</div>
                  <div class="row">
                    <div class="col-lg-11 col-12">
                                    <span>
                                        <i class="fa fa-user position-absolute login-input-icons"></i>
                                        <input type="text" class="form-input-text form-control login-input-with-icons"
                                               placeholder="Username" formControlName="email" />
                                    </span>
                      <div
                        *ngIf="loginForm.controls['email'].invalid && (loginForm.controls['email'].dirty || loginForm.controls['email'].touched)">
                                        <span [hidden]="!loginForm.controls['email'].errors.required"
                                              class="text-red font-size-12 error-text">User Name is required. </span>
                        <span [hidden]="!loginForm.controls['email'].errors.pattern"
                              class="text-red font-size-12 error-text">Please enter a valid user name.
                                        </span>
                      </div>
                    </div>
                  </div>

                  <div class="row ">
                    <div class="col-lg-7 col-12 py-2 ">
                      <p class="p-modifie">Type the Security Code shown:</p>
                    </div>
                  </div>
                  <div class="row ">
                    <div class="col-11">
                      <div class="captcha-modify w-100">
                                        <span onmousedown='return false;' onselectstart='return false;'>{{passcode}}
                                        </span>
                      </div>
                    </div>
                    <div class="col-1 pl-0 verticle-content-middle ">
                                    <span>
                                        <a type="button" class="d-block" (click)="getRandomString()"><img
                                          src="assets/images/load.svg"></a>
                                    </span>
                    </div>

                    <div class="col-12 col-lg-11 pt-1">
                      <input onpaste="return false" autocomplete="off" type="text" name="passcode"
                             required formControlName="passcode" class="form-input-text form-control ">
                      <div *ngIf="submitted">
                                        <span *ngIf="loginForm.controls['passcode'].errors"
                                              [hidden]="!loginForm.controls['passcode'].errors.required"
                                              class="text-red font-size-12 error-text"> Required. </span>

                        <span
                          *ngIf="validPasscode == false && loginForm.controls['passcode'].errors == null"
                          class="text-red font-size-12 error-text">Invalid Code
                                        </span>

                      </div>
                    </div>

                  </div>
                  <div class="row pb-3">
                    <div class="col-12 col-lg-11 pb-3">
                      <button type="submit"
                              class="font-size-15 btn text-white bio-blue-bg-color mt-3 py-2 button-radius w-100">Submit</button>
                    </div>

                    <div class="col-12 col-lg-11 pb-3 justify-content-center d-flex bio-blue-text-color" role="button"
                         routerLink="/admin/login">Login</div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="clip-path-img col-md-8 d-none d-md-flex verticle-content-middle">
            <div class="row">
              <div class="col-12 pb-4">
                <img src="assets/images/Group2022.png" alt="logo"
                     style="width:20%; margin-left: 56%;margin-top: 5%;" />
              </div>
              <div class="col-12">
                <div class="row">
                  <div class="col-md-4 col-sm-2"></div>
                  <div class="col-md-8">

                    <div class="text-white pb-4 mb-4">
                      <div class="float-md-left">
                        <div class="font-size-30 font-weight-bold ">Maharashtra State Biodiversity Board
                        </div>
                        <div class="font-size-15 ">Jaiv Vividhata Bhavan, Kadimbag, Civil Lines,
                          Nagpur-440001
                        </div>
                        <div class="font-size-15 ">Phone No. 0712-2522982 / 84
                        </div>
                        <div class="font-size-15 ">Email id : Msbb-ngp@gov.in / Msbb.ngp@gmail.com
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </form>
    </div>
  </div>
</div>
