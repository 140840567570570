<div class="row mt-5 py-2 pb-4 d-flex justify-content-center w-100 mx-0">
    <div class="col-11 col-md-10 col-lg-9">
        <form [formGroup]="userManagmentForm">
            <div class="row bg-white border-style-20">
                <div class="col-12 font-size-14 font-weight-bold text-white bg-blue border-style-top-left-20 py-3">Add
                    User
                </div>
                <div class="col-12">
                    <div class="row">
                        <div class="col-4">
                            <input type="text" class="form-input-text form-control w-100 bg-light-gray"
                                placeholder="First Name" formControlName="firstname" />
                            <div *ngIf="submitted && userManagmentFormControls.firstname.errors">
                                <span [hidden]="!userManagmentFormControls.firstname.errors.required"
                                    class="font-size-12">
                                    <i class="fas fa-exclamation-circle error-text mr-1"></i>First Name is Required
                                </span>
                            </div>
                        </div>
                        <div class="col-4">
                            <input type="text" class="form-input-text form-control w-100 bg-light-gray"
                                placeholder="Last Name" formControlName="lastname" />
                            <div *ngIf="submitted && userManagmentFormControls.lastname.errors">
                                <span [hidden]="!userManagmentFormControls.lastname.errors.required"
                                    class="font-size-12">
                                    <i class="fas fa-exclamation-circle error-text mr-1"></i>LastName is Required
                                </span>
                            </div>
                        </div>
                        <div class="col-4">
                            <input type="text" class="form-input-text form-control w-100 bg-light-gray"
                                placeholder="Mobile Number" formControlName="mobile" maxlength="10"/>
                            <div *ngIf="submitted && userManagmentFormControls.mobile.errors">
                                <span [hidden]="!userManagmentFormControls.mobile.errors.required" class="font-size-12">
                                    <i class="fas fa-exclamation-circle error-text mr-1"></i>Mobile is Required
                                </span>
                              <span [hidden]="!userManagmentFormControls.mobile.errors.pattern" class="font-size-12">
                                    <i class="fas fa-exclamation-circle error-text mr-1"></i>Invalid Mobile
                                </span>
                            </div>
                        </div>
                        <div class="col-4">
                            <input type="text" class="form-input-text form-control w-100 bg-light-gray"
                                placeholder="Email ID" formControlName="email" />
                            <div *ngIf="submitted && userManagmentFormControls.email.errors">
                                <span [hidden]="!userManagmentFormControls.email.errors.required" class="font-size-12">
                                    <i class="fas fa-exclamation-circle error-text mr-1"></i>Email is Required
                                </span>
                                <span [hidden]="!userManagmentFormControls.email.errors.pattern" class="font-size-12">
                                    <i class="fas fa-exclamation-circle error-text mr-1"></i>Please enter a valid email
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="font-size-15 font-weight-bold py-3">
                        Choose Admin Type
                    </div>
                </div>
                <div class="col-12 w-100 pt-2 pb-3 font-gray">
                    <label class="radio-inline ml-5 verticle-content-middle d-inline-flex">
                        <input type="radio" name="optradio" id="mantralayaLevel" class="mr-2" checked
                            (change)="onAdminTypeChanged($event)" [value]="preDefinedUserRoles.mantralya"
                            formControlName="selectedRole" >
                        <span>Mantralaya Level</span>
                    </label>
                    <label class="radio-inline ml-5 verticle-content-middle d-inline-flex">
                        <input type="radio" name="optradio" id="revenueDivisionLevel" class="mr-2"
                            (change)="onAdminTypeChanged($event)" [value]="preDefinedUserRoles.revenuDivision"
                            formControlName="selectedRole">
                        <span>Revenue Division Level</span>
                    </label>
                    <label class="radio-inline ml-5 verticle-content-middle d-inline-flex">
                        <input type="radio" name="optradio" id="districtLevel" class="mr-2"
                            (change)="onAdminTypeChanged($event)" formControlName="selectedRole"
                            [value]="preDefinedUserRoles.district">
                        <span>District Level</span>
                    </label>
                    <label class="radio-inline ml-5 verticle-content-middle d-inline-flex">
                        <input type="radio" name="optradio" id="zilaParishadLevel" class="mr-2"
                            (change)="onAdminTypeChanged($event)" formControlName="selectedRole"
                            [value]="preDefinedUserRoles.zilaParishad">
                        <span>Taluka Level</span>
                    </label>
                </div>
                <div class="col-lg-4 col-sm-6 col-12 pt-3 px-3">
                    <div class="dropdown"
                        [ngClass]="userManagmentFormControls.revenuedivision.disabled ? 'disabled': ''">
                        <button class="btn btn-secondary dropdown-toggle w-100" type="button" id="dropdownMenuButton"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {{ getSelectTextFormControl(userManagmentFormControls.revenuedivision, revenueDivisionList,
                            'revenu_div_id'
                            , 'rdivision_name', 'Choose Revenue Division') }}
                        </button>
                        <div class="dropdown-menu w-100 py-0 scrollable-menu scrollbar-thin" aria-labelledby="dropdownMenuButton">
                            <span class="dropdown-item" *ngFor="let each_revenueDivision of revenueDivisionList"
                                (click)="setSelectValue(userManagmentFormControls.revenuedivision, each_revenueDivision.revenu_div_id)">
                                {{ each_revenueDivision.rdivision_name }}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12 pt-3">
                    <div class="dropdown" [ngClass]="userManagmentFormControls.district.disabled ? 'disabled': ''">
                        <button class="btn btn-secondary dropdown-toggle w-100" type="button" id="dropdownMenuButton"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {{ getSelectTextFormControl(userManagmentFormControls.district, districtList, 'district_id'
                            , 'district_name', 'Choose District') }}
                        </button>
                        <div class="dropdown-menu w-100 py-0 scrollable-menu scrollbar-thin" aria-labelledby="dropdownMenuButton">
                            <span class="dropdown-item" *ngFor="let each_districtList of districtList"
                                (click)="setSelectValue(userManagmentFormControls.district, each_districtList.district_id)">
                                {{ each_districtList.district_name }}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12 pt-3">
                    <div class="dropdown" [ngClass]="userManagmentFormControls.zilaParishad.disabled ? 'disabled': ''">
                        <button class="btn btn-secondary dropdown-toggle w-100" type="button" id="dropdownMenuButton"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {{ getSelectTextFormControl(userManagmentFormControls.zilaParishad, zilaParishadList,
                            'taluka_id'
                            , 'taluka_name', 'Choose Taluka') }}
                        </button>
                        <div class="dropdown-menu w-100 py-0 scrollable-menu scrollbar-thin" aria-labelledby="dropdownMenuButton">
                            <span class="dropdown-item" *ngFor="let each_zilaParishadList of zilaParishadList"
                                (click)="setSelectValue(userManagmentFormControls.zilaParishad, each_zilaParishadList.taluka_id)">
                                {{ each_zilaParishadList.taluka_name }}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="font-size-15 font-weight-bold py-3">
                        Choose Department
                    </div>
                </div>
                <div class="col-12 w-100 pt-2 pb-3 font-gray px-5">
                    <div class="row">
                        <div class="col-6 col-md-4 col-lg-3"
                            *ngFor="let eachDepartment of departmentList; let i = index">
                            <label class="radio-inline verticle-content-middle d-inline-flex">
                                <input type="radio" name="optradio1" class="mr-2" [checked]="i == 0"
                                    formControlName="selectedDepartment" [value]="eachDepartment.id">
                                <span>{{eachDepartment.department_name}}</span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-12 justify-content-center d-flex pt-5 pb-4 w-100">
                    <a [routerLink]="['/admin/user-management']"
                        class=" font-size-15 btn text-blue blue-border btn-width-150px mt-5 mr-3 button-radius">
                        Cancel
                    </a>
                    <button class=" font-size-15 btn text-white bio-blue-bg-color mt-5 btn-width-150px button-radius"
                        (click)="addUserForm()">
                        Submit</button>
                </div>
            </div>
        </form>
    </div>
</div>
