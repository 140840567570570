import {Inject, Injectable} from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import {Router} from '@angular/router';
import {RestService} from '../../services/rest.service';


@Injectable({
  providedIn: 'root'
})


export class AdminAuthGuard implements CanActivate {

  constructor(
    private service: RestService,
    private router: Router,
  ) {
  }


  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
    const url = state.url;
    const auth = this.isUserAuth(url);
    if (auth) {
      this.router.navigate([url]);
    }
    return true;
  }
  isUserAuth(url: string ) {
    const dataPacket = localStorage.getItem('authData');
    const data = JSON.parse(dataPacket);

    if (data && data[0].token) {
      const userRoleId = data[0].user && data[0].user.user_role_id ? data[0].user.user_role_id : null;
      if ([1, 2, 3, 4, 6, 7].includes(userRoleId)) {
        return;
      }
    }
    this.router.navigate(['admin/login']);
    return false;
  }
}
