<app-header [hideDefault]="true"></app-header>
<div>
    <div class="row w-100 d-flex mx-0 justify-content-center">
        <div class="col-12 px-3 py-3">
            <form class="w-100" [formGroup]="ngFilter">
               <div class="row bg-white border-style pb-4 mx-1">
                  <div class="col-md-8 col-sm-12 mt-2 font-size-18 font-weight-bold verticle-content-middle">
                        Report of e-BMC & e-PBR
                  </div>
                  <div class="col-md-2 col-sm-12 mt-2 font-size-18 font-weight-bold verticle-content-middle">
                      <select class="form-control form-input-text w-100 select-style" formControlName="year">
                        <option value="">Select Year</option>
                        <option *ngFor="let year of yearOptions" [value]="year">
                         {{year}}
                        </option>
                      </select>
                  </div>
                 <div class="col-lg-2 col-md-2 col-12 text-center verticle-content-end pb-0 pl-1 pr-3">
                   <button type="button"
                           class="btn bg-blue text-white btn-style border-style-10 w-100 filter-btn"
                           (click)="getReportSummary()">Search</button>
                 </div>

                  <div class="col-12">
                        <div class="row px-2">


                            <div class="col-12 pt-3 pr-1 pl-0">
                                <div
                                    class="mat-elevation-z8 table-responsive table-active table-hover table-div pr-2 pl-1">
                                    <table class="e-BMC_e-PBR-table" mat-table [dataSource]="dataSource">
                                        <ng-container [matColumnDef]="col"
                                            *ngFor="let col of displayedColumns; index as j">
                                            <ng-container *ngIf="col=='#'">
                                                <th mat-header-cell *matHeaderCellDef
                                                    [style]="columnInformations[j].style ? columnInformations[j].style : ''">
                                                    {{columnInformations[j].label ? columnInformations[j].label : ''}}
                                                </th>
                                                <td mat-cell *matCellDef="let element, index as i" class="serial">
                                                    {{ paginator.pageIndex * this.paginator.pageSize + i + 1 }}
                                                </td>
                                            </ng-container>
                                            <ng-container *ngIf="col=='action'">
                                                <th mat-header-cell *matHeaderCellDef
                                                    class="text-center text-capitalize"
                                                    [style]="columnInformations[j].style ? columnInformations[j].style : ''">
                                                    {{columnInformations[j].label ? columnInformations[j].label : ''}}
                                                </th>
                                                <td mat-cell *matCellDef="let element" class="action">
                                                    <button *ngIf="element.pbr_forms!=0"
                                                        [routerLink]="['pbr_forms/' + element.pbr_category_id + '/' + element.user_id + '/' + generateNewSessionKey ]"
                                                        [state]="ngFilter.value"
                                                        class="btn text-white btn-style border-style-10 py-1 px-2 mr-1 my-1"
                                                        style="background:#0060F1 !important;">
                                                        e-PBR Form
                                                    </button>
                                                  <span *ngIf="element.bmc_forms!=0">&nbsp;</span>
                                                    <button *ngIf="element.bmc_forms!=0"
                                                        [routerLink]="['bmc_forms/' + element.bmc_category_id + '/' + element.user_id + '/' + generateNewSessionKey]"
                                                        [state]="ngFilter.value"
                                                        class="btn text-white btn-style border-style-10 py-1 px-2 mr-1 my-1"
                                                        style="background:#0060F1 !important;">
                                                        e-BMC Form
                                                    </button>
                                                    <button
                                                        class="btn text-white btn-style border-style-10 py-1 px-2 mr-1 my-1 ml-1"
                                                        style="background:#0060F1 !important;"
                                                        [routerLink]="['download/pbr_forms/' + element.pbr_category_id + '/' + element.user_id + '/' + generateNewSessionKey ]"
                                                        [state]="ngFilter.value">
                                                        e-PBR - PDF
                                                    </button>
                                                </td>
                                            </ng-container>

                                            <ng-container *ngIf="!['#','action'].includes(col)">
                                                <th mat-header-cell *matHeaderCellDef
                                                    [style]="columnInformations[j].style" class="">
                                                    {{columnInformations[j].label ? columnInformations[j].label : ''}}
                                                </th>
                                                <td mat-cell *matCellDef="let element" class="{{col}} text-capitalize">
                                                    <span>{{!element[col] || element[col] == "" ? "NA" :
                                                        element[col]}}</span>
                                                </td>
                                            </ng-container>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">
                                        </tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                    </table>
                                </div>
                                <div class="no-record-div text-center pt-5 pb-5" *ngIf="(total_bmc_pbr_data==0)">
                                    No Record Found
                                </div>
                                <div class="stats">
                                    <mat-paginator [length]="total_bmc_pbr_data" [pageSize]="page_size"
                                        [pageSizeOptions]="[5,10,20,50,100]" showFirstLastButtons>
                                    </mat-paginator>
                                </div>
                            </div>
                        </div>
                    </div>
               </div>
            </form>
        </div>
    </div>
</div>
