import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainWebAppComponent } from './main-web-app/main-web-app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BmcRegistrationComponent } from './access-to-application/bmc-registration/bmc-registration.component';
import { MainWebAppRoutingModule } from './main-web-app/main-web-app-routing.module';
import { MainWebAppModuleModule } from './main-web-app/main-web-app-module.module';
import { LoginComponent } from './access-to-application/login/login.component';
import { SidebarComponent } from './common-component/sidebar/sidebar.component';
import { HttpClientModule, HttpClientJsonpModule} from '@angular/common/http';
import {RestService} from './services/rest.service';
import { MatIconModule } from '@angular/material/icon';

import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxUiLoaderModule, NgxUiLoaderConfig, NgxUiLoaderHttpModule, SPINNER, POSITION, PB_DIRECTION} from 'ngx-ui-loader';
import { AdminMainAppModule } from './admin-section/admin-main-app/admin-main-app.module';
import { AdminMainAppRoutingModule } from './admin-section/admin-main-app/admin-main-app-routing.module';

import { HighchartsChartModule } from 'highcharts-angular';
import { SigninComponent } from './admin-section/signin/signin.component';
import { MatToolbarModule } from '@angular/material';
import { ForgetPasswordComponent } from './access-to-application/forget-password/forget-password.component';
import { ResetPasswordComponent } from './access-to-application/reset-password/reset-password.component';
import { ForgotPasswordComponent } from './admin-section/forgot-password/forgot-password.component';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  pbColor: 'rgba(35, 96, 241, 0.56)',
  bgsColor: 'red',
  bgsPosition: POSITION.bottomCenter,
  bgsSize: 40,
  bgsType: SPINNER.threeStrings,
  bgsOpacity: 0.2,
  overlayColor: 'rgba(255,255,255,0.8)',
  fgsType: 'three-bounce',
  fgsColor: 'rgba(35, 96, 241, 0.56)',
  pbDirection: PB_DIRECTION.leftToRight,
  pbThickness: 5,
};

@NgModule({
  declarations: [
    AppComponent,
    BmcRegistrationComponent,
    LoginComponent,
    SigninComponent,
    ForgetPasswordComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent
  ],
  exports: [
    BrowserModule,
    HttpClientModule,
    HttpClientJsonpModule
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MainWebAppRoutingModule,
    AdminMainAppRoutingModule,
    MainWebAppModuleModule,
    AdminMainAppModule,
    HttpClientModule,
    MatIconModule,
    HighchartsChartModule,
    MatToolbarModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    ModalModule
  ],
  providers: [ HttpClientModule, RestService, NgxUiLoaderModule, MatIconModule],
  bootstrap: [AppComponent]
})
export class AppModule { }
