import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminAuthGuard } from 'src/app/auth-service/admin-auth/admin-auth.guard';
import { RolebasedGuard } from 'src/app/auth-service/role-auth/rolebased.guard';
import { CategoriesFormsComponent } from 'src/app/main-web-app/categories-forms/categories-forms.component';
import { AddUserComponent } from './add-user/add-user.component';
import { AdminComponentComponent } from './admin-component.component';
import { CategoryWiseReportComponent } from './category-wise-report/category-wise-report.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ReportComponent } from './report/report.component';
import { UserManagementComponent } from './user-management/user-management.component';

const routes: Routes = [
  {
    path: '',
    component: AdminComponentComponent,

    children: [
      {
        path: '',
        component: DashboardComponent
      },
      {
        path: 'dashboard',
        component: DashboardComponent
      },
      {
        path: 'report',
        component: ReportComponent
      },
      {
        path: 'user-management',
        component: UserManagementComponent,
        canActivate: [RolebasedGuard]
      },
      {
        path: 'report/:form_category_name/:category_id/:user_id/:sessionkey',
        component: CategoryWiseReportComponent
      },
      {
        path: 'report/download/:form_category_name/:category_id/:user_id/:sessionkey',
        component: CategoryWiseReportComponent
      },
      {
        path: 'add-user',
        component: AddUserComponent,
        canActivate: [RolebasedGuard]
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminMainAppRoutingModule { }
