import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { FileItem, FileUploader, FileUploaderOptions } from "ng2-file-upload";
import Swal from 'sweetalert2';
import { RestService } from 'src/app/services/rest.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-document-upload',
  templateUrl: './document-upload.component.html',
  styleUrls: ['./document-upload.component.css']
})
export class DocumentUploadComponent implements OnInit {
  @ViewChild("fileUploadInput") fileUploadInput: ElementRef;
  @ViewChild("fileUploadInput2") fileUploadInput2: ElementRef;
  @ViewChild("fileUploadInput3") fileUploadInput3: ElementRef;
  @ViewChild("fileUploadInput4") fileUploadInput4: ElementRef;
  @ViewChild("fileUploadInput5") fileUploadInput5: ElementRef;

  closeResult: string;
  modalRef: any;
  ngFilter: FormGroup;
  yearOptions: any[];
  filePreview: {
    url: any,
    fileType: string
  };
  filePreview2: {
    url: any,
    fileType: string
  };
  filePreview3: {
    url: any,
    fileType: string
  };
  filePreview4: {
    url: any,
    fileType: string
  };
  filePreview5: {
    url: any,
    fileType: string
  };
  uploader: FileUploader;
  uploader2: FileUploader;
  uploader3: FileUploader;
  uploader4: FileUploader;
  uploader5: FileUploader;
  categoriesName: any;
  categoryFormId: any;

  constructor(
    private fb: FormBuilder,
    private RestApi: RestService,
    private ngxService: NgxUiLoaderService,
    public sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private router: Router,

  ) {
    this.categoryFormId = route.snapshot.params.catergoryformid;
    this.categoriesName = route.snapshot.params.categoryformName;
  }

  ngOnInit(): void {
    const currentYear = (new Date()).getFullYear();
    this.getYearsList();


    this.ngFilter = this.fb.group(
      {
        year: new FormControl(currentYear)
      }
    );

    const userToken = localStorage.getItem('userToken');
    this.uploader = new FileUploader({
      url: this.RestApi.endpoint + "upload-application",
      method: "post",
      itemAlias: "attachment",
      allowedFileType: ["pdf"],
      maxFileSize: 25 * 1024 * 1024,
      autoUpload: false,
      authToken: 'Bearer ' + userToken,
      removeAfterUpload: true
    });
    this.uploader2 = new FileUploader({
      url: this.RestApi.endpoint + "upload-application",
      method: "post",
      itemAlias: "attachment",
      allowedFileType: ["pdf"],
      maxFileSize: 25 * 1024 * 1024,
      autoUpload: false,
      authToken: 'Bearer ' + userToken,
      removeAfterUpload: true
    });
    this.uploader3 = new FileUploader({
      url: this.RestApi.endpoint + "upload-application",
      method: "post",
      itemAlias: "attachment",
      allowedFileType: ["pdf"],
      maxFileSize: 25 * 1024 * 1024,
      autoUpload: false,
      authToken: 'Bearer ' + userToken,
      removeAfterUpload: true
    });
    this.uploader4 = new FileUploader({
      url: this.RestApi.endpoint + "upload-application",
      method: "post",
      itemAlias: "attachment",
      allowedFileType: ["pdf"],
      maxFileSize: 25 * 1024 * 1024,
      autoUpload: false,
      authToken: 'Bearer ' + userToken,
      removeAfterUpload: true
    });
    this.uploader5 = new FileUploader({
      url: this.RestApi.endpoint + "upload-application",
      method: "post",
      itemAlias: "attachment",
      allowedFileType: ["pdf"],
      maxFileSize: 25 * 1024 * 1024,
      autoUpload: false,
      authToken: 'Bearer ' + userToken,
      removeAfterUpload: true
    });
    const authData = localStorage.getItem('authData');
    const data = JSON.parse(authData);

    this.uploader.onAfterAddingFile = (item) => {
      let latestFile = this.uploader.queue[this.uploader.queue.length - 1]
      this.uploader.queue = [];
      this.uploader.queue.push(latestFile);

      const fileExt = item._file.name.split('.').pop();
      let fileType = "";
      let blobType = '';
      if (item._file.name.indexOf("pdf") > -1)
        blobType = 'application';
      else
        blobType = 'image';

      let blobFile = new Blob([item._file], { type: blobType + '/' + fileExt + this.generatePostfixSVG(fileExt) });

      let filePreview: any = window.URL.createObjectURL(blobFile);
      if (item._file.name.indexOf("pdf") > -1) {
        fileType = "pdf";
      }
      else {
        fileType = "image";
        filePreview = this.sanitizer.bypassSecurityTrustUrl(filePreview);
      }
      this.filePreview = {
        url: filePreview,
        fileType: fileType
      };
    }
    this.uploader2.onAfterAddingFile = (item2) => {
      let latestFile2 = this.uploader2.queue[this.uploader2.queue.length - 1]
      this.uploader2.queue = [];
      this.uploader2.queue.push(latestFile2);

      const fileExt2 = item2._file.name.split('.').pop();
      let fileType2 = "";
      let blobType2 = '';
      if (item2._file.name.indexOf("pdf") > -1)
        blobType2 = 'application';
      else
        blobType2 = 'image';

      let blobFile2 = new Blob([item2._file], { type: blobType2 + '/' + fileExt2 + this.generatePostfixSVG(fileExt2) });

      let filePreview2: any = window.URL.createObjectURL(blobFile2);
      if (item2._file.name.indexOf("pdf") > -1) {
        fileType2 = "pdf";
      }
      else {
        fileType2 = "image";
        filePreview2 = this.sanitizer.bypassSecurityTrustUrl(filePreview2);
      }
      this.filePreview2 = {
        url: filePreview2,
        fileType: fileType2
      };
    }

    this.uploader3.onAfterAddingFile = (item3) => {
      let latestFile3 = this.uploader3.queue[this.uploader3.queue.length - 1]
      this.uploader3.queue = [];
      this.uploader3.queue.push(latestFile3);

      const fileExt3 = item3._file.name.split('.').pop();
      let fileType3 = "";
      let blobType3 = '';
      if (item3._file.name.indexOf("pdf") > -1)
        blobType3 = 'application';
      else
        blobType3 = 'image';

      let blobFile3 = new Blob([item3._file], { type: blobType3 + '/' + fileExt3 + this.generatePostfixSVG(fileExt3) });

      let filePreview3: any = window.URL.createObjectURL(blobFile3);
      if (item3._file.name.indexOf("pdf") > -1) {
        fileType3 = "pdf";
      }
      else {
        fileType3 = "image";
        filePreview3 = this.sanitizer.bypassSecurityTrustUrl(filePreview3);
      }
      this.filePreview3 = {
        url: filePreview3,
        fileType: fileType3
      };
    }

    this.uploader4.onAfterAddingFile = (item4) => {
      let latestFile4 = this.uploader4.queue[this.uploader4.queue.length - 1]
      this.uploader4.queue = [];
      this.uploader4.queue.push(latestFile4);

      const fileExt4 = item4._file.name.split('.').pop();
      let fileType4 = "";
      let blobType4 = '';
      if (item4._file.name.indexOf("pdf") > -1)
        blobType4 = 'application';
      else
        blobType4 = 'image';

      let blobFile4 = new Blob([item4._file], { type: blobType4 + '/' + fileExt4 + this.generatePostfixSVG(fileExt4) });

      let filePreview4: any = window.URL.createObjectURL(blobFile4);
      if (item4._file.name.indexOf("pdf") > -1) {
        fileType4 = "pdf";
      }
      else {
        fileType4 = "image";
        filePreview4 = this.sanitizer.bypassSecurityTrustUrl(filePreview4);
      }
      this.filePreview4 = {
        url: filePreview4,
        fileType: fileType4
      };
    }

    this.uploader5.onAfterAddingFile = (item5) => {
      let latestFile5 = this.uploader5.queue[this.uploader5.queue.length - 1]
      this.uploader5.queue = [];
      this.uploader5.queue.push(latestFile5);

      const fileExt5 = item5._file.name.split('.').pop();
      let fileType5 = "";
      let blobType5 = '';
      if (item5._file.name.indexOf("pdf") > -1)
        blobType5 = 'application';
      else
        blobType5 = 'image';

      let blobFile5 = new Blob([item5._file], { type: blobType5 + '/' + fileExt5 + this.generatePostfixSVG(fileExt5) });

      let filePreview5: any = window.URL.createObjectURL(blobFile5);
      if (item5._file.name.indexOf("pdf") > -1) {
        fileType5 = "pdf";
      }
      else {
        fileType5 = "image";
        filePreview5 = this.sanitizer.bypassSecurityTrustUrl(filePreview5);
      }
      this.filePreview5 = {
        url: filePreview5,
        fileType: fileType5
      };
    }


    this.uploader.onBeforeUploadItem = (item) => {
      item.withCredentials = false;
      this.uploader.setOptions({
        additionalParameter: {
          year: this.ngFilter.controls.year.value
        }
      });
    }

    this.uploader2.onBeforeUploadItem = (item2) => {
      item2.withCredentials = false;
      this.uploader2.setOptions({
        additionalParameter: {
          year: this.ngFilter.controls.year.value
        }
      });
    }
    this.uploader3.onBeforeUploadItem = (item3) => {
      item3.withCredentials = false;
      this.uploader3.setOptions({
        additionalParameter: {
          year: this.ngFilter.controls.year.value
        }
      });
    }
    this.uploader4.onBeforeUploadItem = (item4) => {
      item4.withCredentials = false;
      this.uploader4.setOptions({
        additionalParameter: {
          year: this.ngFilter.controls.year.value
        }
      });
    }
    this.uploader5.onBeforeUploadItem = (item5) => {
      item5.withCredentials = false;
      this.uploader5.setOptions({
        additionalParameter: {
          year: this.ngFilter.controls.year.value
        }
      });
    }






    this.uploader.onWhenAddingFileFailed = (item, filter) => {
      const fileExt = item.name.split('.').pop();
      let errorMessage = "";
      let timer = 2000;
      switch (filter.name) {
        case 'fileSize':
          errorMessage = 'Warning ! \nThe uploaded file \"' + item.name + '\" is ' + this.formatBytes(item.size) + ', this exceeds the maximum allowed size of ' + this.formatBytes(this.uploader.options.maxFileSize);
          timer = 5000;
          break;
        default:
          errorMessage = 'File type ' + fileExt + ' not valid';
          break;
      }
      Swal.fire({
        icon: 'error',
        title: errorMessage,
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: timer,
      })

      this.uploader.clearQueue();
      this.fileUploadInput.nativeElement.value  = "";
    }

    this.uploader2.onWhenAddingFileFailed = (item2, filter) => {
      const fileExt2 = item2.name.split('.').pop();
      let errorMessage = "";
      let timer = 2000;
      switch (filter.name) {
        case 'fileSize':
          errorMessage = 'Warning ! \nThe uploaded file \"' + item2.name + '\" is ' + this.formatBytes(item2.size) + ', this exceeds the maximum allowed size of ' + this.formatBytes(this.uploader2.options.maxFileSize);
          timer = 5000;
          break;
        default:
          errorMessage = 'File type ' + fileExt2 + ' not valid';
          break;
      }
      Swal.fire({
        icon: 'error',
        title: errorMessage,
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: timer,
      })

      this.uploader2.clearQueue();
      this.fileUploadInput2.nativeElement.value  = "";
    }

    this.uploader3.onWhenAddingFileFailed = (item3, filter) => {
      const fileExt3 = item3.name.split('.').pop();
      let errorMessage = "";
      let timer = 2000;
      switch (filter.name) {
        case 'fileSize':
          errorMessage = 'Warning ! \nThe uploaded file \"' + item3.name + '\" is ' + this.formatBytes(item3.size) + ', this exceeds the maximum allowed size of ' + this.formatBytes(this.uploader3.options.maxFileSize);
          timer = 5000;
          break;
        default:
          errorMessage = 'File type ' + fileExt3 + ' not valid';
          break;
      }
      Swal.fire({
        icon: 'error',
        title: errorMessage,
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: timer,
      })

      this.uploader3.clearQueue();
      this.fileUploadInput3.nativeElement.value  = "";
    }
    this.uploader4.onWhenAddingFileFailed = (item4, filter) => {
      const fileExt4 = item4.name.split('.').pop();
      let errorMessage = "";
      let timer = 2000;
      switch (filter.name) {
        case 'fileSize':
          errorMessage = 'Warning ! \nThe uploaded file \"' + item4.name + '\" is ' + this.formatBytes(item4.size) + ', this exceeds the maximum allowed size of ' + this.formatBytes(this.uploader4.options.maxFileSize);
          timer = 5000;
          break;
        default:
          errorMessage = 'File type ' + fileExt4 + ' not valid';
          break;
      }
      Swal.fire({
        icon: 'error',
        title: errorMessage,
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: timer,
      })

      this.uploader4.clearQueue();
      this.fileUploadInput4.nativeElement.value  = "";
    }
    this.uploader5.onWhenAddingFileFailed = (item5, filter) => {
      const fileExt5 = item5.name.split('.').pop();
      let errorMessage = "";
      let timer = 2000;
      switch (filter.name) {
        case 'fileSize':
          errorMessage = 'Warning ! \nThe uploaded file \"' + item5.name + '\" is ' + this.formatBytes(item5.size) + ', this exceeds the maximum allowed size of ' + this.formatBytes(this.uploader5.options.maxFileSize);
          timer = 5000;
          break;
        default:
          errorMessage = 'File type ' + fileExt5 + ' not valid';
          break;
      }
      Swal.fire({
        icon: 'error',
        title: errorMessage,
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: timer,
      })

      this.uploader5.clearQueue();
      this.fileUploadInput5.nativeElement.value  = "";
    }


    
    this.uploader.onCompleteItem = (item, response: any, status, headers) => {
      this.filePreview = undefined;
      Swal.fire({
        icon: 'success',
        title: 'File Uploaded Successfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2000,
      }).then(() =>{
        this.router.navigate(['/user/document-list/' + this.categoriesName + "/" + this.categoryFormId]);
      })
      
    }

    this.uploader2.onCompleteItem = (item, response: any, status, headers) => {
      this.filePreview = undefined;
      Swal.fire({
        icon: 'success',
        title: 'File Uploaded Successfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2000,
      }).then(() =>{
        this.router.navigate(['/user/document-list/' + this.categoriesName + "/" + this.categoryFormId]);
      })
      
    }
    this.uploader3.onCompleteItem = (item, response: any, status, headers) => {
      this.filePreview = undefined;
      Swal.fire({
        icon: 'success',
        title: 'File Uploaded Successfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2000,
      }).then(() =>{
        this.router.navigate(['/user/document-list/' + this.categoriesName + "/" + this.categoryFormId]);
      })
      
    }
    this.uploader4.onCompleteItem = (item, response: any, status, headers) => {
      this.filePreview = undefined;
      Swal.fire({
        icon: 'success',
        title: 'File Uploaded Successfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2000,
      }).then(() =>{
        this.router.navigate(['/user/document-list/' + this.categoriesName + "/" + this.categoryFormId]);
      })
      
    }
    this.uploader5.onCompleteItem = (item, response: any, status, headers) => {
      this.filePreview = undefined;
      Swal.fire({
        icon: 'success',
        title: 'File Uploaded Successfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2000,
      }).then(() =>{
        this.router.navigate(['/user/document-list/' + this.categoriesName + "/" + this.categoryFormId]);
      })
      
    }
  }
  
  formatBytes(bytes, decimals?) {
    if (bytes == 0) return '0 Bytes';
    const k = 1024,
      dm = decimals || 2,
      sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
  getYearsList() {
    const currentYear = (new Date()).getFullYear();
    const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step));
    this.yearOptions = range(currentYear, currentYear - 50, -1);
  }

  async finalUpload() {
    if (!this.uploader.isUploading) {
      this.ngxService.start();
      this.uploader.uploadAll();
      await this.uploader.onCompleteAll();
      this.ngxService.stop();
    }
    if (!this.uploader2.isUploading) {
      this.ngxService.start();
      this.uploader2.uploadAll();
      await this.uploader2.onCompleteAll();
      this.ngxService.stop();
    }
    if (!this.uploader3.isUploading) {
      this.ngxService.start();
      this.uploader3.uploadAll();
      await this.uploader3.onCompleteAll();
      this.ngxService.stop();
    }
    if (!this.uploader4.isUploading) {
      this.ngxService.start();
      this.uploader4.uploadAll();
      await this.uploader4.onCompleteAll();
      this.ngxService.stop();
    }
    if (!this.uploader5.isUploading) {
      this.ngxService.start();
      this.uploader5.uploadAll();
      await this.uploader5.onCompleteAll();
      this.ngxService.stop();
    }
  }

  runApiCommunication(fromPath: string, params: any): Promise<any> {
    this.ngxService.start();
    const userToken = localStorage.getItem('userToken');
    const dataPromise = new Promise((resolve, reject) => {
      this.RestApi.postRequestCreator(fromPath, userToken, params)
        .subscribe((resp: any) => {
          // this.ngxService.stop();
          if (resp && resp.status === 'success' && resp.code === 200) {
            resolve(resp.result);
          } else {
            Swal.fire({
              title: 'Error',
              icon: 'error',
              text: resp.message,
            });
            // reject([]);
          }
        }, error => {
          // this.ngxService.stop();
          Swal.fire({
            title: 'Error',
            icon: 'error',
            text: error.message,
            showConfirmButton: false,
            timer: 1500,
          });
          // reject([]);
        });
    });
    dataPromise.catch(function onReject(e) {
      Swal.fire({
        title: 'Error',
        icon: 'error',
        text: e.message,
      });
    });
    return dataPromise;
  }
  generatePostfixSVG(fileExt: string): string {
    if (fileExt.toLowerCase().indexOf('svg') > -1)
      return '+xml';
    else
      return '';
  }

  

}

