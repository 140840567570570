import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RolebasedGuard implements CanActivate {
  constructor(private router: Router) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const dataPacket = localStorage.getItem('authData');
    const data = JSON.parse(dataPacket);
    if (data && data[0].token) {
      const userRoleId = data[0].user && data[0].user.user_role_id ? data[0].user.user_role_id : null;
      
      if ([1].includes(userRoleId) ) {
        return true;
      }
    }
    this.router.navigate(['/admin/dashboard']);
    return false;
  }

}
