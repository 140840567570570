<div>
    <mat-toolbar class="header-style p-0">
        <div class="row w-100">
            <div class="col-3 col-md-2 pl-4 pl-md-4 verticle-content-middle d-lg-none">
                <img src="assets/images/logo.png" style="width: 100px;">
            </div>
            <div
                class="col-7 col-md-8 col-lg-9 title pl-4 pl-lg-2 text-start font-lg-blue font-size-30 font-weight-bold pl-2 verticle-content-middle text-start text-wrap">
                <img src="assets/images/logo.png" style="width: 100px;" class="mx-3 d-none d-lg-inline">
                Maharashtra State Biodiversity Board
            </div>
            <div class="col-2 col-lg-3 login-info verticle-content-middle px-0 px-md-2 justify-content-end">
                <span class="d-none d-lg-inline logged-user-span ml-lg-4">Welcome {{loggedInUserName}}</span>
                <button mat-icon-button class="mr-3 no-mat-ripple">
                    <mat-icon class="mat-display font-blue">account_circle</mat-icon>
                </button>
                <div class="d-inline-block mr-md-2 dropdown">
                    <button class="btn btn-default btn-lg dropdown-toggle text-black border-none px-0 px-lg-0"
                        type="button" data-toggle="dropdown">
                        <span class="caret ml-2"></span>
                    </button>
                    <ul class="dropdown-menu mr-4 text-center mt-3">
                        <li><span class="d-lg-none logged-user-span">Welcome {{loggedInUserName}}</span></li>
                        <li>
                            <a role="button" (click)="logout()">
                                <i class="fa fa-sign-out-alt bio-blue-text-color"></i>
                                &nbsp;Log Out</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </mat-toolbar>
    <div class="row w-100 d-flex justify-content-center admin-menu mx-0">
        <div class="col-12 px-3 d-flex  verticle-content-middle">
            <ul class="nav nav-pills w-100" role="tablist">
                <li class="nav-item verticle-content-middle">
                    <a #liDashboardDetails role="tab" [routerLink]="['/admin/dashboard']"
                        aria-controls="DashboardDetails" aria-selected="true" class="nav-link admin-dashboard"
                        [ngClass]="route.url && (route.url.indexOf('dashboard') > 0 || route.url == '/admin') ? 'active' : ''">
                        Dashboard
                    </a>
                </li>
                <li class="nav-item verticle-content-middle">
                    <a #liReportDetails class="nav-link" data-toggle="pill" [routerLink]="['/admin/report']" role="tab"
                        aria-controls="ReportDetails" aria-selected="false" class="nav-link admin-Report"
                        [ngClass]="route.url && route.url.indexOf('report') > 0 ? 'active' : ''">Report</a>
                </li>
                <li [hidden] = "userRole!=1" class="nav-item verticle-content-middle">
                    <a #liUserManagementDetails class="nav-link" data-toggle="pill"
                        [routerLink]="['/admin/user-management']" role="tab" aria-controls="UsermanagementDetails"
                        aria-selected="false" class="nav-link admin-usermanagement"
                        [ngClass]="route.url && (route.url.indexOf('user-management') > 0
                                    || route.url.indexOf('add-user') > 0) ? 'active' : ''">User
                        Management</a>
                </li>
            </ul>
        </div>
    </div>
</div>
<ngx-ui-loader></ngx-ui-loader>
