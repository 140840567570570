<app-header [HeaderTitle]="headerTitle"></app-header>
<ng-container *ngIf="isFormAvailable; then formTemplate; else noFormTemplate">
</ng-container>

<ng-template #noFormTemplate>
  <div class="container justify-content-center">
    <div class="row">
      <div class="col-12">

        <div class="header pt-5 pb-5 pl-5 pt-5 mt-5 text-center">
          <h3>No Form Available</h3>
        </div>

      </div>
    </div>
  </div>
</ng-template>

<ng-template #formTemplate>

  <form [formGroup]="ngform" (ngSubmit)="submitForm( ngform.value)" style="width: 100%;">
    <div class="row justify-content-center d-flex mx-1"
      [ngClass]="applicationId && applicationId > 0 ? 'edit-mode' : ''">
      <div class="col-11">
        <div class="row form-row-1">
          <div class="col-md-10 col-sm-12 mt-5 d-flex justify-content-lg-start">
            <div class="font-size-24 font-weight-bold">
              {{formName}} <span *ngIf="formSubText"> : {{formSubText}}</span>
            </div>
          </div>
          <div class="col-md-2 col-sm-12 mt-5 d-flex justify-content-lg-end">
            <div class="pt-3">
              <select class="form-control form-input-text w-100" formControlName="year">
                <option value="">Select Year</option>
                <option *ngFor="let year of yearOptions" [value]="year">
                  {{year}}
                </option>
              </select>
              <label class="form-label font-size-15 font-weight-bold"><span class="mendatory-text">*</span>
                Year</label>
              <div
                *ngIf="ngform.controls['year'].invalid && ((ngform.controls['year'].dirty || ngform.controls['year'].touched) || submitted)">
                <span [hidden]="!ngform.controls['year'].errors.required" class="text-red font-size-12 error-text">Year
                  is required. </span>
              </div>

            </div>
          </div>
        </div>
        <hr>
      </div>

      <div class="col-11" *ngIf="formDescription">
        <div class="row">
          <div class="col-sm-12 mt-2 d-flex justify-content-lg-start">
            <div class="font-size-16">
              {{formDescription}}
            </div>
          </div>
        </div>
      </div>
      <br>
      <br>

      <div class="col-md-11">

        <div>
          <div oncopy="return false" oncut="return false" onpaste="return false"
            *ngFor="let ticket of t.controls; let i = index" class="list-group col-md-12 list-group-flush"
            style="padding-bottom: 15px !important;">
            <div class="list-group-item panel-body pr-0 pl-0">
              <div [formGroup]="ticket" class="form-row col-sm-12 pr-0 pl-0">
                <div class="col-md-12 col-sm-12">
                  <div class="row form-row-2">
                    <div class="col-md-3 col-sm-2 pt-5 pb-3" *ngFor="let label of form[i]; let j = index">
                      <ng-container *ngIf="form[i][j].type == 'text'" class="pt-2 bb-2">
                        <input id="{{i+1}}" type="text" placeholder="{{form[i][j].placeholder}}"
                          formControlName="{{form[i][j].name}}" class="form-control" />


                        <label class="form-label font-size-15 font-weight-bold"><span class="mendatory-text">*</span>
                          {{form[i][j].label}}</label>
                      </ng-container>


                      <ng-container *ngIf="form[i][j].type == 'number'" class="pt-2 bb-2">
                        <input id="{{i+1}}" type="number" placeholder="{{form[i][j].placeholder}}"
                          formControlName="{{form[i][j].name}}" class="form-control" (keydown)="checkNumber($event)" />


                        <label class="form-label font-size-15 font-weight-bold"><span class="mendatory-text">*</span>
                          {{form[i][j].label}}</label>
                      </ng-container>

                      <ng-container *ngIf="form[i][j].type == 'single-select-dropdown'">
                        <select [hidden]="form[i][j].is_dependent" id="{{i+1}}" formControlName="{{form[i][j].name}}" class="form-control">
                          <option *ngIf="form[i][j].placeholder" value="">{{form[i][j].placeholder}}</option>
                          <option *ngFor="let title of form[i][j].option" [value]="title.value">
                            {{title.text}}
                          </option>
                        </select>

                        <select [hidden]="!form[i][j].is_dependent" id="{{i+1}}" formControlName="{{form[i][j].name}}" (change)= "getOptions(form[i][j].dependentDetails, $event, i, j)"  class="form-control">
                          <option *ngIf="form[i][j].placeholder" value="">{{form[i][j].placeholder}}</option>
                          <option *ngFor="let title of form[i][j].option" [value]="title.value">
                            {{title.text}}
                          </option>
                        </select>


                        <label class="form-label font-size-15 font-weight-bold"><span class="mendatory-text">*</span>
                          {{form[i][j].label}}</label>

                      </ng-container>

                      <div
                        *ngIf="ticket.controls[form[i][j].name].invalid && ((ticket.controls[form[i][j].name].dirty || ticket.controls[form[i][j].name].touched) || submitted)">
                        <span [hidden]="!ticket.controls[form[i][j].name].errors.required"
                          class="text-red font-size-12 error-text">{{form[i][j].label}} is required. </span>
                      </div>

                    </div>&nbsp;
                  </div>
                </div>
                <!--                  <div class="col-md-4 col-sm-4" style="text-align: right">-->
                <!--                    <a role="button"  style="zoom: 0.7;" (click)="onClear(i)">Clear</a>-->
                <!--                  </div>-->
                <span id="error_{{i+1}}" class="col-md-11 col-md-offset-1"
                  style="display: none;color:red">Required</span>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="col-11">
        <hr>
        <div class="row" style="margin-top: -40px;">
          <div class="col-sm-12 col-md-12 mt-2 d-flex justify-content-center">
            <a *ngIf="!(applicationId && applicationId>0)" (click)="addForm()" title="Add Form" class="font-size-16"
              role="button"> <i class="fa fa-2x fa-plus-square"></i></a>
            <ng-container *ngIf="formNumber>1">
              &nbsp;&nbsp;&nbsp;
              <a (click)="removeForm()" title="Remove Form" class="font-size-16" role="button"> <i
                  class="fa fa-2x fa-minus-square text-danger"></i></a>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="col-11 mt-5 pb-5  justify-content-center d-flex">
        <div class="w-100">
          <div class="col-12 justify-content-center d-flex">
            <button type="submit" class=" font-size-15 btn text-white bio-blue-bg-color w-25 py-2 mt-5  button-radius">
              {{applicationId && applicationId > 0 ? 'Edit' : 'Submit'}}
            </button>
            &nbsp;&nbsp;&nbsp;
            <a *ngIf="!(applicationId && applicationId > 0)"
              class=" font-size-15 btn text-white bio-blue-bg-color w-25 py-2 mt-5  button-radius"
              [routerLink]="['list']">List View</a>
            <a *ngIf="(applicationId && applicationId > 0)"
              class=" font-size-15 btn text-white bio-blue-bg-color w-25 py-2 mt-5  button-radius"
              [routerLink]="[generateListViewRouterLink]">
              Cancel
            </a>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-template>
