import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdminMainAppRoutingModule } from './admin-main-app-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AdminHeaderComponent } from '../common/admin-header/admin-header.component';
import { AdminComponentComponent } from './admin-component.component';
// tslint:disable-next-line:max-line-length
import { MatButtonModule, MatCardModule, MatIconModule, MatInputModule, MatPaginatorModule, MatSelectModule, MatSortModule, MatTableModule, MatToolbarModule } from '@angular/material';
import { UserManagementComponent } from './user-management/user-management.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { NgxUiLoaderModule, NgxUiLoaderConfig, NgxUiLoaderHttpModule, SPINNER, POSITION, PB_DIRECTION} from 'ngx-ui-loader';
import { BrowserModule } from '@angular/platform-browser';
import { ReportComponent } from './report/report.component';
import { CategoryWiseReportComponent } from './category-wise-report/category-wise-report.component';
import { AddUserComponent } from './add-user/add-user.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  pbColor: 'rgba(35, 96, 241, 0.56)',
  bgsColor: 'red',
  bgsPosition: POSITION.bottomCenter,
  bgsSize: 40,
  bgsType: SPINNER.threeStrings,
  bgsOpacity: 0.2,
  overlayColor: 'rgba(255,255,255,0.8)',
  fgsType: 'three-bounce',
  fgsColor: 'rgba(35, 96, 241, 0.56)',
  pbDirection: PB_DIRECTION.leftToRight,
  pbThickness: 5,
};

@NgModule({
  declarations: [
    AdminComponentComponent,
    AdminHeaderComponent,
    DashboardComponent,
    UserManagementComponent,
    ReportComponent,
    CategoryWiseReportComponent,
    AddUserComponent,
  ],
  exports: [
    AdminHeaderComponent
  ],
    imports: [
        FormsModule,
        CommonModule,
        ReactiveFormsModule,
        AdminMainAppRoutingModule,
        MatToolbarModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        MatCardModule,
        MatButtonToggleModule,
        MatSelectModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
        PdfViewerModule
    ],
  providers: [NgxUiLoaderModule, MatIconModule],

})
export class AdminMainAppModule {}
