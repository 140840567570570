import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatToolbar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { RestService } from 'src/app/services/rest.service';
import Swal from 'sweetalert2';
import {calculateSHA256} from '../../_helpers/utility';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})

export class SigninComponent implements OnInit, AfterViewInit {

  constructor(
    private fb: FormBuilder,
    @Inject(DOCUMENT) document,
    private service: RestService,
    private http: HttpClient,
    private router: Router,
    private activeroute: ActivatedRoute,
    private ngxService: NgxUiLoaderService,
  ) {
    this.createForm();
  }
  ngAfterViewInit(): void {
    this.onResize(null);
  }
  loginForm: FormGroup;
  data: any;
  authData: any;
  userToken: any;
  errorMessage: any;

  submitted = false;
  error = '';
  validPasscode: boolean = undefined;
  passcode;

  createForm() {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required]],
      password: ['', Validators.required],
      passcode: ['', Validators.required]
    });
    this.loginForm.controls.passcode.valueChanges.subscribe(newValue => {
      if (newValue.length < this.passcode.length) {
        this.validPasscode = undefined;
        return;
      }
    });
  }
  ngOnInit(): void {
    this.initProcess();
  }
  initProcess() {
    const ls = localStorage.getItem('authData');
    this.userToken = localStorage.getItem('userToken');
    const data = JSON.parse(ls);
    if (data && data[0].token) {
      const userRoleId = data[0].user && data[0].user.user_role_id ? data[0].user.user_role_id : null;
      if ([1, 2, 3, 4].includes(userRoleId)) {
        this.router.navigate(['/admin']);
      } else {
        this.router.navigate(['/user']);
      }
    } else {
      this.getRandomString();
    }
  }

  // tslint:disable-next-line:typedef
  logIn(credentials) {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.validateCode();
    if (!this.validPasscode) {
      return;
    }
    this.ngxService.start();
    const param = {
      email: btoa(credentials.email),
      password: (calculateSHA256(credentials.password)),
      device_id: localStorage.getItem('device_uuid') ? btoa(localStorage.getItem('device_uuid').toString()) : null
    };

    this.service.postRequestCreator('login', null, param).subscribe((response: any) => {
      this.ngxService.stop();
      this.data = response;
      if (this.data.result && this.data.result.access_token && this.data.result.user && this.data.result.user.user_role_id) {
        this.authData = new Array({
          token: this.data.result.access_token,
          token_type: this.data.result.token_type,
          expires_in: this.data.result.expires_in,
          user: this.data.result.user
        });
        this.validPasscode = undefined;
        localStorage.setItem('authData', JSON.stringify(this.authData));
        localStorage.setItem('userToken', (this.data.result.access_token));
        localStorage.setItem('userName', (this.data.result.user.name));
        localStorage.setItem('userEmail', (this.data.result.user.email));
        this.ngxService.stop();
        Swal.fire({
          html: '<b>Welcome to dashboard!</b>',
          showConfirmButton: false,
          timer: 1500
        });

        if ([5].includes(parseInt(this.data.result.user.user_role_id, 10))) {
          this.router.navigate(['/user']);
        } else {
          this.router.navigate(['/admin']);
        }
      } else {
        Swal.fire({
          html: '<b>Email/Password is incorrect! Try Again</b>',
          showConfirmButton: true
        });
        this.loginForm.controls.passcode.setValue('');
        this.loginForm.controls.passcode.setErrors(null);
      }
    }, (error) => {
      this.ngxService.stop();
      this.loginForm.controls.passcode.setValue('');
      this.loginForm.controls.passcode.setErrors(null);
    });
    this.loginForm.reset();
    this.loginForm.setErrors(null);
    this.getRandomString();
  }

  getRandomString() {
    var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var result = '';
    for (var i = 0; i < 6; i++) {
      result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    this.passcode = result
  }
  validateCode() {
    this.validPasscode = (this.loginForm.controls['passcode'].value == this.passcode);
  }

  @ViewChild("loginContent") loginContent: ElementRef;
  @ViewChild("header") header: MatToolbar;
  onResize(event: any) {
    let zoomValue = (window.innerWidth / window.screen.availWidth);
    if (window.screen.availWidth == window.innerWidth)
      zoomValue = 1;

    this.loginContent.nativeElement["style"].zoom = zoomValue;
    this.header._elementRef.nativeElement["style"].zoom = zoomValue;
  }

  navigateTo(s: string) {
   window.location.href = s;
  }
}
