<!-- <app-header [hideDefault]="true"></app-header> -->
<app-header [hideIcon]="false"></app-header>
<!-- <app-bread-crumb [BreadCrumbData]="BreadCrumbData"></app-bread-crumb> -->
<div class="row mt-3 px-0 py-3 mx-0">
    <div class="col-lg-5 col-sm-11 pl-3">
        <div class="header py-4 pl-md-5 d-none">
            <h1>Maharashtra State Biodiversity Board</h1>
            <h2 class="pr-5 mr-3">Department of Maharashtra Biodiversity , Government of Maharashtra</h2>
        </div>
        <div class="main-content pl-md-5">
            <div class="row">
                <!-- <div class="d-none d-md-flex col-lg-11">
                    <div class="logo w-100 d-flex justify-content-center">
                        <img src="assets/images/logo.png" alt="logo"/>
                    </div>
                </div> -->
                <div class="col-11">
                    <div class="welcome-content row d-flex justify-content-center px-0">
                        <div class="col-lg-12 pt-2">
                            <h1 class="font-weight-bold w-100 d-flex justify-content-center">Welcome</h1>
                            <span class="bio-blue-text-color p-0 w-100 d-flex justify-content-center">{{userEmail}}</span>
                        </div>
                        <div class="col-lg-12 pt-5">
                            <!-- <div *ngFor="let d of categoriesData; let i=index" class="pb-3 d-inline-flex pr-2  w-45" 
                                [ngClass]="i == 0 ? 'fa-pull-left' : 'fa-pull-right' "-->
                            <div *ngFor="let d of categoriesData; let i=index" class="pb-3 pr-2  d-flex justify-content-center w-100">
                                <button routerLink="categories-form/{{d.title}}/{{d.id}}"
                                    class=" font-size-15 btn text-white bio-blue-bg-color w-45 py-2 mt-3  button-radius">
                                    {{d.name}}
                                </button>
                            </div>
                            <!-- <div class="pb-3 d-inline-flex pr-2 w-45"> -->
                            <div class="pb-3 pr-2 d-flex justify-content-center w-100">
                                <button routerLink="report"
                                    class=" font-size-15 btn text-white bio-blue-bg-color w-45 py-2 mt-3  button-radius">
                                    Report
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-7 col-sm-11 pl-5">
        <div *ngFor="let eachCard of cardsInfo" class="card form-cards d-inline-flex my-3 mx-3 shadow"
            style="width: 21rem; height: 200px;" [ngClass]="eachCard.cssClass">
            <div class="card-body px-4 py-5">
                <div class="row h-100">
                    <div class="col-8">
                        <h5 class="card-title d-flex justify-content-center">{{ eachCard.title }}</h5>
                        <p class="card-text">{{ eachCard.getCount }}</p>
                    </div>
                    <div class="col-4 d-flex verticle-content-middle">
                        <i class="fa" [ngClass]="eachCard.icon"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>