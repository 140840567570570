import { Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { title } from 'process';
import { Router, NavigationEnd } from '@angular/router';
import Swal from 'sweetalert2';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { RestService } from '../../services/rest.service';
import { errorValidate } from '../../_helpers/utility';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  CurrentUrl;
  userToken: string;
  userEmail: string;
  userName: string;
  data: any;
  baseUrl = this.service.baseUrl;

  headerTitle: string;
  get HeaderTitle(){
    return this.headerTitle;
  }
  @Input() set HeaderTitle(value: string){
    this.headerTitle = value;
    // this.headerTitle = this.headerTitleSelectionService.transformTitle(value);
  }

  @Input() hideDefault = false;
  @Input() hideIcon = true;

  constructor(private titlePage: Title,
              public router: Router,
              public service: RestService,
              private ngxService: NgxUiLoaderService,
    // private headerTitleSelectionService: HeaderTitleSelectionService
  ) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        const element = document.getElementById('body');
        if (['/login', '/forget-password', '/admin/forget-password', '/user'].includes(val.urlAfterRedirects)) {
          element.classList.remove('watermark');
        } else {
          element.classList.add('watermark');
        }
      }

    });
  }

  ngOnInit(): void {
    this.initProcess();
  }

  initProcess() {
    const ls = localStorage.getItem('authData');
    this.userToken = localStorage.getItem('userToken');
    this.userEmail = localStorage.getItem('userEmail');
    const data = JSON.parse(ls);
    if (data && data.length && data[0] && data[0].user) {
      this.userName = data[0].user.category_detail_name + ' (' + data[0].user.area_name + ')';
    }
  }

  logout() {

    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to logout?',
      showCancelButton: true,
      confirmButtonColor: '#163862',
      confirmButtonText: 'Confirm',
      cancelButtonColor: '#d33'
    }).then((result) => {
      if (result.value) {

        this.ngxService.start();
        this.service.postRequestCreator('logout', this.userToken).subscribe(response => {
          this.ngxService.stop();
          this.data = response;
          console.log(this.data);
          if (this.data && this.data.message) {
            Swal.fire({
              html: '<b>You are logged out successfully!</b>',
              showConfirmButton: false,
            });
            localStorage.removeItem('authData');
            localStorage.removeItem('userName');
            localStorage.removeItem('userEmail');
            localStorage.removeItem('userToken');
            window.location.href = this.baseUrl;
          } else {
            Swal.fire({
              html: '<b>Something went wrong! Try Again</b>',
              showConfirmButton: true,
            });
          }
        }, error => {
          this.ngxService.stop();
        });

      }
    });
  }


}
