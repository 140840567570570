<app-header></app-header>
<form [formGroup]="ngFilter" style="width: 100%;">
    <div class="row justify-content-center d-flex mx-1">
        <div class="col-11">
            <div class="row">
                <div class="col-md-10 col-sm-12 mt-5 d-flex justify-content-lg-start">
                    <div class="font-size-24 font-weight-bold">
                        Document Upload Form
                    </div>
                </div>
                <div class="col-md-2 col-sm-12 mt-5 d-flex justify-content-lg-end">
                    <div>
                        <select class="form-control form-input-text w-100" formControlName="year">
                            <option value="">Select Year</option>
                            <option *ngFor="let year of yearOptions" [value]="year">
                                {{year}}
                            </option>
                        </select>
                        <label class="form-label font-size-15 font-weight-bold"><span class="mendatory-text">*</span>
                            Year</label>
                    </div>
                </div>
            </div>
            <hr>
        </div>


        <div class="col-6">
            <div class="row w-100 pt-3">
                <div class="col-md-6 col-12 pt-2">
                    <div class="font-size-20 font-weight-bold  verticle-content-middle px-3"
                        [ngClass]="!filePreview ? 'file-not-selected w-fixed-300 justify-content-center' : ''">
                        <span *ngIf="!filePreview">
                            Document<br>Preview
                        </span>
                        <img *ngIf="filePreview && filePreview.fileType == 'image' && filePreview.url"
                            [src]="filePreview.url" class="w-100 w-fixed-max-width-350">
                        <div *ngIf="filePreview && filePreview.fileType == 'pdf' && filePreview.url"
                            class="w-100 disabled-transparent">
                            <pdf-viewer [src]="filePreview.url" [render-text]="false" [original-size]="false"
                                [fit-to-page]="false" style="width: inherit; height: 250px;" class="inherit-height ">
                            </pdf-viewer>
                        </div>
                    </div>
                    <div class="pt-3 w-100 d-flex justify-content-center">
                        <span *ngFor="let item of uploader.queue ">
                            {{item._file.name}}
                        </span>
                    </div>
                </div>
                <div class="col-md-6 col-12 pt-4">
                    <span class="d-none">
                        <input #fileUploadInput type="file" name="file" class="form-control" [uploader]="uploader"
                            ng2FileSelect>
                    </span>
                    <span class="h-100 verticle-content-middle" style="max-width: 150px;">
                        <button class="btn btn-primary" (click)="fileUploadInput.click()">Choose File</button>
                    </span>
                </div>
            </div>
        </div>

        
        <div class="col-6">
            <div class="row w-100 pt-3">
                <div class="col-md-6 col-12 pt-2">
                    <div class="font-size-20 font-weight-bold  verticle-content-middle px-3"
                        [ngClass]="!filePreview2 ? 'file-not-selected w-fixed-300 justify-content-center' : ''">
                        <span *ngIf="!filePreview2">
                            Document<br>Preview
                        </span>
                        <img *ngIf="filePreview2 && filePreview2.fileType == 'image' && filePreview2.url"
                            [src]="filePreview2.url" class="w-100 w-fixed-max-width-350">
                        <div *ngIf="filePreview2 && filePreview2.fileType == 'pdf' && filePreview2.url"
                            class="w-100 disabled-transparent">
                            <pdf-viewer [src]="filePreview2.url" [render-text]="false" [original-size]="false"
                                [fit-to-page]="false" style="width: inherit; height: 250px;" class="inherit-height ">
                            </pdf-viewer>
                        </div>
                    </div>
                    <div class="pt-3 w-100 d-flex justify-content-center">
                        <span *ngFor="let item of uploader2.queue">
                            {{item._file.name}}
                        </span>
                    </div>
                </div>
                <div class="col-md-6 col-12 pt-4">
                    <span class="d-none">
                        <input #fileUploadInput2 type="file" name="file2" class="form-control" [uploader]="uploader2"
                            ng2FileSelect>
                    </span>
                    <span class="h-100 verticle-content-middle" style="max-width: 150px;">
                        <button class="btn btn-primary" (click)="fileUploadInput2.click()">Choose File</button>
                    </span>
                </div>
            </div>
        </div>

        <div class="col-6">
            <div class="row w-100 pt-3">
                <div class="col-md-6 col-12 pt-2">
                    <div class="font-size-20 font-weight-bold  verticle-content-middle px-3"
                        [ngClass]="!filePreview3 ? 'file-not-selected w-fixed-300 justify-content-center' : ''">
                        <span *ngIf="!filePreview3">
                            Document<br>Preview
                        </span>
                        <img *ngIf="filePreview3 && filePreview3.fileType == 'image' && filePreview3.url"
                            [src]="filePreview3.url" class="w-100 w-fixed-max-width-350">
                        <div *ngIf="filePreview3 && filePreview3.fileType == 'pdf' && filePreview3.url"
                            class="w-100 disabled-transparent">
                            <pdf-viewer [src]="filePreview3.url" [render-text]="false" [original-size]="false"
                                [fit-to-page]="false" style="width: inherit; height: 250px;" class="inherit-height ">
                            </pdf-viewer>
                        </div>
                    </div>
                    <div class="pt-3 w-100 d-flex justify-content-center">
                        <span *ngFor="let item of uploader3.queue">
                            {{item._file.name}}
                        </span>
                    </div>
                </div>
                <div class="col-md-6 col-12 pt-4">
                    <span class="d-none">
                        <input #fileUploadInput3 type="file" name="file3" class="form-control" [uploader]="uploader3"
                            ng2FileSelect>
                    </span>
                    <span class="h-100 verticle-content-middle" style="max-width: 150px;">
                        <button class="btn btn-primary" (click)="fileUploadInput3.click()">Choose File</button>
                    </span>
                </div>
            </div>
        </div>

        <div class="col-6">
            <div class="row w-100 pt-3">
                <div class="col-md-6 col-12 pt-2">
                    <div class="font-size-20 font-weight-bold  verticle-content-middle px-3"
                        [ngClass]="!filePreview4 ? 'file-not-selected w-fixed-300 justify-content-center' : ''">
                        <span *ngIf="!filePreview4">
                            Document<br>Preview
                        </span>
                        <img *ngIf="filePreview4 && filePreview4.fileType == 'image' && filePreview4.url"
                            [src]="filePreview4.url" class="w-100 w-fixed-max-width-350">
                        <div *ngIf="filePreview4 && filePreview4.fileType == 'pdf' && filePreview4.url"
                            class="w-100 disabled-transparent">
                            <pdf-viewer [src]="filePreview4.url" [render-text]="false" [original-size]="false"
                                [fit-to-page]="false" style="width: inherit; height: 250px;" class="inherit-height ">
                            </pdf-viewer>
                        </div>
                    </div>
                    <div class="pt-3 w-100 d-flex justify-content-center">
                        <span *ngFor="let item of uploader4.queue">
                            {{item._file.name}}
                        </span>
                    </div>
                </div>
                <div class="col-md-6 col-12 pt-4">
                    <span class="d-none">
                        <input #fileUploadInput4 type="file" name="file4" class="form-control" [uploader]="uploader4"
                            ng2FileSelect>
                    </span>
                    <span class="h-100 verticle-content-middle" style="max-width: 150px;">
                        <button class="btn btn-primary" (click)="fileUploadInput4.click()">Choose File</button>
                    </span>
                </div>
            </div>
        </div>

        <div class="col-6">
            <div class="row w-100 pt-3">
                <div class="col-md-6 col-12 pt-2">
                    <div class="font-size-20 font-weight-bold  verticle-content-middle px-3"
                        [ngClass]="!filePreview5 ? 'file-not-selected w-fixed-300 justify-content-center' : ''">
                        <span *ngIf="!filePreview5">
                            Document<br>Preview
                        </span>
                        <img *ngIf="filePreview5 && filePreview5.fileType == 'image' && filePreview5.url"
                            [src]="filePreview5.url" class="w-100 w-fixed-max-width-350">
                        <div *ngIf="filePreview5 && filePreview5.fileType == 'pdf' && filePreview5.url"
                            class="w-100 disabled-transparent">
                            <pdf-viewer [src]="filePreview5.url" [render-text]="false" [original-size]="false"
                                [fit-to-page]="false" style="width: inherit; height: 250px;" class="inherit-height ">
                            </pdf-viewer>
                        </div>
                    </div>
                    <div class="pt-3 w-100 d-flex justify-content-center">
                        <span *ngFor="let item of uploader5.queue">
                            {{item._file.name}}
                        </span>
                    </div>
                </div>
                <div class="col-md-6 col-12 pt-4">
                    <span class="d-none">
                        <input #fileUploadInput5 type="file" name="file5" class="form-control" [uploader]="uploader5"
                            ng2FileSelect>
                    </span>
                    <span class="h-100 verticle-content-middle" style="max-width: 150px;">
                        <button class="btn btn-primary" (click)="fileUploadInput5.click()">Choose File</button>
                    </span>
                </div>
            </div>
        </div>











        <div class="col-11 mt-5 pb-5 justify-content-center d-flex">
            <div class="w-100">
                <div class="col-12 justify-content-center d-flex">
                    <button type="submit"
                        class="font-size-15 btn text-white bio-blue-bg-color w-25 py-2 mt-5  button-radius"
                        value="upload" id="btn_upload" (click)="finalUpload()">Upload</button>
                    <a class="font-size-15 btn text-white bio-blue-bg-color w-25 py-2 mt-5  button-radius ml-2"
                        [routerLink]="['/user/document-list/'+ categoriesName + '/' + categoryFormId]">List View</a>
                </div>
            </div>
        </div>
    </div>
</form>