// noinspection BadExpressionStatementJS

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { RestService } from '../../services/rest.service';
import Swal from 'sweetalert2';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray, ValidatorFn, AbstractControl } from '@angular/forms';
import { Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { checkNumber } from '../../_helpers/utility';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class FormComponent implements OnInit {

  constructor(
    private router: Router,
    private service: RestService,
    private fb: FormBuilder,
    @Inject(DOCUMENT) document,
    private activeroute: ActivatedRoute,
    private ngxService: NgxUiLoaderService,
    private http: HttpClient,
  ) {
    this.id = activeroute.snapshot.params.id;
    this.applicationId = activeroute.snapshot.params.appid;
    this.categoryFormId = activeroute.snapshot.params.catergoryformid;
    this.catergoryformName = activeroute.snapshot.params.catergoryformName;
    this.formname = activeroute.snapshot.params.formname;
    this.createForm();
  }
  get checkNumber() {
    return checkNumber;
  }
  get f() { return this.ngform.controls; }
  get t() { return this.f.forms as FormArray; }
  get headerTitle() {
    if (this.applicationId && this.applicationId > 0) {
      return this.form_title + ' / ' + 'Edit View';
    }
    return this.form_title + ' / ' + this.formName;

  }

  get generateListViewRouterLink(){
    return '/user/categories-form/' + this.catergoryformName + '/' + this.categoryId + '/form/' + this.formname + '/' + this.id + '/list';
  }
  catergoryformName: any;
  formname: any;
  id: any = '';
  applicationId = 0;
  categoryId: any = '';
  categoryFormId: any = '';
  isFormAvailable: any = 1;
  private userToken: string;
  private data: any;
  formName: any;
  // tslint:disable-next-line:variable-name
  form_title: any;
  formSubText: any;
  formDescription: any;

  ngform: FormGroup;
  playCost: number;
  totalCost: number;
  ticket = 1;
  value;
  submitted = false;
  error: number;
  formNumber = 1;
  formJson: any = {};
  labelArray = [];
  controlNameArray = [];
  formStructure = [];
  form = [];
  isListView = 0;
  yearOptions = [];
  format: any;

  onChangeForms(e) {
    const numberOfForms = e.value || 0;
    this.formNumber = numberOfForms;
    if (this.t.length < numberOfForms) {
      for (let i = this.t.length; i < numberOfForms; i++) {
        this.t.push(this.fb.group(
          this.formJson
        ));
      }
    } else {
      for (let i = this.t.length; i >= numberOfForms; i--) {
        this.t.removeAt(i);
      }
    }
    this.forms(numberOfForms, 'play');
  }

  onClear(row) {
    this.submitted = false;
    const control = this.t.controls;
    if (row !== undefined) {
      control[row].reset();
    }
  }

  createForm() {
    this.ngform = this.fb.group({
      year: ['', [Validators.required]],
      forms: new FormArray([]),
    },
    );
  }

  addForm() {
    const formStructure = JSON.parse(localStorage.getItem('formStructure'));
    this.form[this.formNumber] = formStructure;
    this.formNumber += 1;
    // tslint:disable-next-line:variable-name
    const number = this.formNumber;
    this.onChangeForms({ value: number });

  }

  removeForm() {
    if (this.formNumber <= 1) {
      return;
    }
    this.formNumber -= 1;
    this.form.pop();
    // tslint:disable-next-line:variable-name
    const number = this.formNumber;
    this.onChangeForms({ value: number });
  }

  forms(event, type) {
    this.ticket = event;
  }

  ngOnInit(): void {
    this.initProcess();
  }

  initProcess() {
    const ls = localStorage.getItem('authData');
    this.userToken = localStorage.getItem('userToken');
    this.getYearsList();
    this.getCategoryForm();
  }

  getYearsList(){
    const currentYear = (new Date()).getFullYear();
    const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step));
    this.yearOptions = range(currentYear, currentYear - 50, -1);
  }
  getCategoryForm() {
    const param = {
      category_form_id: this.id
    };
    this.ngxService.start();
    this.service.postRequestCreator('get-category-forms-list', this.userToken, param).subscribe((response: any) => {
      this.ngxService.stop();
      if (response.code === 200) {
        this.data = response.result && response.result.length && response.result[0] ? response.result[0] : {};
        this.categoryFormId = this.data.id ? this.data.id : '';
        this.categoryId = this.data.category_id ? this.data.category_id : '';
        this.formName = this.data.form_text ? this.data.form_text : '';
        this.form_title = this.data.form_title ? this.data.form_title : '';
        this.formSubText = this.data.form_subtext ? this.data.form_subtext : '';

        const format = this.format = this.data.format ? JSON.parse(this.data.format) : {};

        if (format) {
          this.formDescription = format.description ? format.description : '';

          // this.form = format.form ? format.form : [];
          this.form = format.form ? [format.form] : [];
          this.formStructure = format.form ? format.form : [];
          localStorage.setItem('formStructure', JSON.stringify(this.formStructure));

          if (this.form.length) {
            // tslint:disable-next-line:forin
            for (const i in this.form[0]) {
              this.formJson[this.form[0][i].id] = ['', [Validators.required]];
              this.labelArray[i] = this.form[0][i].label;
              this.controlNameArray[i] = this.form[0][i].name;
            }
            if (this.formJson) {
              this.onChangeForms({ value: 1 });
            } else {
              this.isFormAvailable = 0;
            }
          } else {
            this.isFormAvailable = 0;
          }

        } else {
          this.isFormAvailable = 0;
        }

        if (this.applicationId && this.applicationId > 0) {
          const formSeachParams = {
            search: {
              category_form_id: this.id,
              id: this.applicationId
            }
          };
          // tslint:disable-next-line:no-shadowed-variable
          this.service.postRequestCreator('get-form-details', this.userToken, formSeachParams).subscribe((response: any) => {
            // tslint:disable-next-line:max-line-length
            if (response.code === 200 && (response.result && response.result && response.result.data && response.result.data.data && response.result.data.data.length && response.result.data.data[0])) {
              this.patchFormData(response.result.data.data[0]);
            }
            else {
              Swal.fire({
                html: '<b>Form data not found! Try Again</b>',
                showConfirmButton: true,
              });
            }
          });
        }

      } else {
        Swal.fire({
          html: '<b>Something went wrong! Try Again</b>',
          showConfirmButton: true,
        });
      }
    }, (error) => {
      this.ngxService.stop();
    });
  }

  submitForm(data) {
    this.submitted = true;
    if (this.ngform.invalid) { return; }
    const formData = JSON.stringify(this.ngform.value, null, 4);
    const param = JSON.parse(formData);
    param.category_form_id = this.id;
    this.ngxService.start();
    let path = 'register-form';
    if (this.applicationId && this.applicationId > 0) {
      path = 'update-form';
      param.application_id = this.applicationId;
      param.forms[0].year = param.year;
      delete param.year;
    }
    this.service.postRequestCreator(path, this.userToken, param).subscribe((response: any) => {
      this.ngxService.stop();
      if (response.code === 200) {
        let message = 'Form Registered Successfully';
        if (this.applicationId && this.applicationId > 0) {
          message = 'Form Updated Successfully';
        }

        Swal.fire({
          html: '<b>' + message + '</b>',
          showConfirmButton: true,
        });
        this.router.navigate([this.generateListViewRouterLink]);
      } else {
        Swal.fire({
          html: '<b>Something went wrong! Try Again</b>',
          showConfirmButton: true,
        });
      }
    }, (error) => {
      this.ngxService.stop();
    });
  }

  patchFormData(formData) {

    const index = this.formNumber - 1;
    for (const key in formData) {
      if (key === 'year') {
        this.ngform.controls.year.patchValue(formData.year);
      } else if (this.controlNameArray.includes(key)) {
        // @ts-ignore
        this.ngform.controls.forms.controls[index].controls[key].patchValue(formData[key]);
      }
    }

  }

  getOptions(details, e, i, j) {
    // tslint:disable-next-line:prefer-const
    let param = {
      api_model : details.api_model,
      ...details.api_condition,
      category_form_id: this.id,
    };
    if (details.optionKey) {
      param[details.optionKey] = e.target.value;
    }
    this.ngxService.start();
    const path = 'get-options';
    this.service.postRequestCreator(path, this.userToken, param).subscribe((response: any) => {
      this.ngxService.stop();
      if (response.length) {
        this.form[i][j + 1].option = response;
        // @ts-ignore
        this.ngform.controls.forms.controls[i].controls[details.id].markAsPristine();
        // @ts-ignore
        this.ngform.controls.forms.controls[i].controls[details.id].patchValue('');
      } else {
        Swal.fire({
          html: '<b>Something went wrong! Try Again</b>',
          showConfirmButton: true,
        });
      }
    }, (error) => {
      this.ngxService.stop();
    });
  }
}
