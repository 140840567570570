<app-header [HeaderTitle]="headerTitle"></app-header><div class="container-fluid pr-5 pl-5 justify-content-center">
  <div class="row">
    <div *ngIf="formType" class="col-12 mt-4 d-flex justify-content-lg-start">
      <div class="col-12 row">
        <div class=" col-md-12 col-sm-12 font-size-32 font-weight-bold pl-4">{{formType}}</div>
      </div>
    </div>

    <div class="col-12 mt-4 d-flex justify-content-lg-start">
      <div class="col-12 row">
        <div class=" col-md-8 col-sm-12 font-size-32 font-weight-bold pl-4">
          {{formName}} <span *ngIf="formSubText"> : {{formSubText}}</span>
        </div>

        <div class="col-md-4 col-sm-12">
          <form [formGroup]="ngFilter" id="ngFilter" (ngSubmit)="getFormDetails()" autocomplete="off" style="zoom: 0.8">
            <mat-form-field appearance="outline" style="display: initial">
              <input matInput placeholder="Search" formControlName="search">
              <mat-icon role="button" (click)="getFormDetails()" matSuffix><i
                  class="fa fa-search bio-blue-text-color"></i></mat-icon>
            </mat-form-field>
          </form>
        </div>

      </div>
    </div>
    <div class="col-md-12 pt-1">
      <div class="panel panel-default">
        <div class="panel-content">
          <div class="mat-elevation-z8 table-responsive table-active table-hover table-div">
            <table class="ticket-table" mat-table [dataSource]="dataSource" matSort matSortActive="{{matSortActive}}"
              matSortDisableClear matSortDirection="{{matSortDirection}}">


              <ng-container [matColumnDef]="col" *ngFor="let col of displayedColumns; index as j">

                <ng-container *ngIf="col=='serial'">
                  <th mat-header-cell *matHeaderCellDef
                    [style]="columnInformations[j].style ? columnInformations[j].style : ''">
                    {{columnInformations[j].label ? columnInformations[j].label : ''}} </th>
                  <td mat-cell *matCellDef="let element, index as i" class="serial"> {{ total - ( total - i) + from }}
                  </td>
                </ng-container>
                <ng-container *ngIf="col=='action'">
                  <th mat-header-cell *matHeaderCellDef class="text-center text-capitalize"
                    [style]="columnInformations[j].style ? columnInformations[j].style : ''">
                    {{columnInformations[j].label ? columnInformations[j].label : ''}} </th>
                  <td mat-cell *matCellDef="let element" class="action">
                    <a [routerLink]="['edit', element.id]">
                      <button type="button" class="btn btnColor btn-xs" title="Edit Form">
                        <i class="fa fa-edit bio-blue-text-color"></i></button>
                    </a>
                  </td>
                </ng-container>

                <ng-container *ngIf="!['serial','action'].includes(col)">
                  <!--                  <th mat-header-cell *matHeaderCellDef mat-sort-header style="{{columnInformations[j].style ? columnInformations[j].style : ''}}"> {{columnInformations[j].label ? columnInformations[j].label : ''}} </th>-->
                  <th mat-header-cell *matHeaderCellDef mat-sort-header [style]="columnInformations[j].style">
                    {{columnInformations[j].label ? columnInformations[j].label : ''}} </th>
                  <td mat-cell *matCellDef="let element" class="{{col}} text-capitalize">
                    <span
                      *ngIf="columnInformations[j].label && columnInformations[j].type == 'text'">{{element[col] ? element[col] : 'NA'}}</span>

                    <span *ngIf="columnInformations[j].label && columnInformations[j].type == 'date'">{{element[col] |
                      date}}</span>
                    <span *ngIf="columnInformations[j].label && columnInformations[j].type == 'time'">{{element[col] |
                      date: 'shortTime'}}</span>
                    <span *ngIf="columnInformations[j].label && columnInformations[j].type == 'datetime'">{{element[col]
                      | date}} {{element[col] | date: 'shortTime'}}</span>

                  </td>
                </ng-container>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>
          <div class="no-record-div text-center pt-5 pb-5" *ngIf="(total==0)">
            No Record Found
          </div>
          <div class="stats">
            <mat-paginator [length]="total" [pageSize]="10" [pageSizeOptions]="[5,10,20,50,100]" showFirstLastButtons>
            </mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
