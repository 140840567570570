import { Component, Input, OnInit } from '@angular/core';
import { BreadCrumbModel } from '../../Model/BreadCrumb.Model';

@Component({
  selector: 'app-bread-crumb',
  templateUrl: './bread-crumb.component.html',
  styleUrls: ['./bread-crumb.component.css']
})
export class BreadCrumbComponent implements OnInit {

  constructor() { }
  @Input() BreadCrumbData: BreadCrumbModel[];

  ngOnInit(): void {
  }

}
